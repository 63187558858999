<template>
  <div class="wrapper">
    <navigator></navigator>

    <sidebar></sidebar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <!-- HEADER -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Small boxes (Stat box) -->
          <!-- Main row -->
          <div class="row">
            <section class="col-lg-5 connectedSortable">
              <!-- card editar -->
              <div class="card card-info" v-if="editar">
                <div class="card-header">
                  <h3 class="card-title">Editar Cliente</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form role="form" @submit.prevent="updatecliente(cliente)">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="name">Nome Fantasia:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="nome_fantasia"
                        placeholder="Nome Fantasia"
                        v-model="cliente.nome_fantasia"
                      />
                    </div>

                    <div class="form-group">
                      <label for="telefone">Telefone:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="telefone"
                        placeholder="Telefone"
                        v-model="cliente.telefone"
                      />
                    </div>

                    <div class="form-group">
                      <label for="endereco">Endereço:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="endereco"
                        placeholder="Endereço"
                        v-model="cliente.endereco"
                      />
                    </div>

                    <div class="form-group">
                      <label for="endereco">Ação ao estourar prazo:</label>
                      <select v-model="cliente.acao_ao_estourar_prazo" class="form-control">
                        <option disabled value>Selecione</option>
                        <option value="Aprovar e publicar">Aprovar e Publicar</option>
                        <option value="Aprovar e não publicar">Aprovar e Não Publicar</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="endereco">Limite solicitações extras:</label>
                      <input
                        type="number"
                        class="form-control"
                        id="limite_solicitacao_extra"
                        placeholder="Limite solicitações extras"
                        v-model="cliente.limite_solicitacao_extra"
                      />
                    </div>

                    <div class="form-group">
                      <label for="moderador_id">Moderador:</label>
                      <select id="moderador_id" v-model="cliente.moderador_id" class="form-control">
                        <option
                          v-for="usuario in usuarios"
                          v-bind:value="usuario.id"
                          v-bind:key="usuario.id"
                        >{{usuario.name}}</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="atendente_id">Sucesso do Cliente:</label>
                      <select id="atendente_id" v-model="cliente.atendente_id" class="form-control">
                        <option
                          v-for="usuario in usuarios"
                          v-bind:value="usuario.id"
                          v-bind:key="usuario.id"
                        >{{usuario.name}}</option>
                      </select>
                    </div>
                    
                    <!-- TAG MANAGER -->
                    <tag-manager
                    :key="updateTags"
                    :tags="cliente.tags" 
                    :color="color"
                    :show-tag-select="showTagSelect"
                    @open-tag-select="openTagSelect"
                    @selected="onTagSelected"
                    @create-tag="criarTag"
                    @open-picker="openPicker"
                    @deleted-tag="deleteAddedTag"
                    @delete-added-tag="deleteAddedTag"/>

                    <!-- INTEGRACAO -->
                    <div class="form-group mt-5">
                      <div class="custom-control custom-checkbox">
                        <input
                          class="custom-control-input"
                          type="checkbox"
                          id="integracao"
                          value="1"
                          v-model="cliente.possui_integracao"
                        />
                        <label
                          for="integracao"
                          class="custom-control-label"
                        >Utilizar integração para publicação</label>
                      </div>
                    </div>

                    <div class="form-group mt-5">
                      <label for="links_uteis">Links Úteis</label>
                      <textarea
                        id="links_uteis_edit"
                        class="form-control"
                        placeholder="Links Úteis"
                        v-model="cliente.links_uteis"
                      ></textarea>
                      <!-- <input
                        type="text"
                        class="form-control"
                        id="links_uteis_edit"
                        placeholder="Links Úteis"
                        v-model="cliente.links_uteis"
                      />-->
                    </div>

                    <div class="form-group">
                      <label for="informes">Informes</label>
                      <textarea
                        id="informes_edit"
                        class="form-control"
                        v-model="cliente.informes"
                        placeholder="Informes"
                      ></textarea>
                      <!-- <input
                        type="text"
                        class="form-control"
                        id="informes_edit"
                        placeholder="Informes"
                        v-model="cliente.informes"
                      />-->
                    </div>

                    <div class="form-group">
                      <label for="atendente_id">URL Feeds:</label>
                      <h5>{{$apiUrl}}/feed/client/{{ cliente.id }}</h5>
                    </div>
                  </div>
                  <!-- /.card-body -->
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button class="btn btn-info">Atualizar</button>
                    <button class="btn btn-secondary ml-2" v-on:click="updateCancel()">Cancelar</button>
                  </div>
                </form>
              </div>
              <!-- /.card -->

              <!-- card adicionar -->
              <div class="card card-info" v-else>
                <div class="card-header">
                  <h3 class="card-title">Adicionar Cliente</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form role="form" @submit.prevent="postcliente(cliente)">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="name">Nome Fantasia:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="nome_fantasia"
                        placeholder="Nome Fantasia"
                        v-model="cliente.nome_fantasia"
                      />
                    </div>

                    <div class="form-group">
                      <label for="telefone">Telefone:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="telefone"
                        placeholder="Telefone"
                        v-model="cliente.telefone"
                      />
                    </div>

                    <div class="form-group">
                      <label for="endereco">Endereço</label>
                      <input
                        type="text"
                        class="form-control"
                        id="endereco"
                        placeholder="Endereço"
                        v-model="cliente.endereco"
                      />
                    </div>

                    <div class="form-group">
                      <label for="endereco">Ação ao estourar prazo:</label>
                      <select v-model="cliente.acao_ao_estourar_prazo" class="form-control">
                        <option disabled value>Selecione</option>
                        <option value="Aprovar e publicar">Aprovar e Publicar</option>
                        <option value="Aprovar e não publicar">Aprovar e Não Publicar</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="endereco">Limite solicitações extras:</label>
                      <input
                        type="number"
                        class="form-control"
                        id="limite_solicitacao_extra"
                        placeholder="Limite solicitações extras"
                        v-model="cliente.limite_solicitacao_extra"
                      />
                    </div>

                    <div class="form-group">
                      <label for="endereco">Moderador:</label>

                      <select id="moderador_id" v-model="cliente.moderador_id" class="form-control">
                        <option
                          v-for="usuario in usuarios"
                          v-bind:value="usuario.id"
                          v-bind:key="usuario.id"
                        >{{usuario.name}}</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="atendente_id">Sucesso do Cliente:</label>
                      <select id="atendente_id" v-model="cliente.atendente_id" class="form-control">
                        <option
                          v-for="usuario in usuarios"
                          v-bind:value="usuario.id"
                          v-bind:key="usuario.id"
                        >{{usuario.name}}</option>
                      </select>
                    </div>

                    <!-- TAG MANAGER -->
                    <tag-manager
                    :key="updateTags"
                    :tags="tags" 
                    :color="color"
                    :show-tag-select="showTagSelect"
                    @open-tag-select="openTagSelect"
                    @selected="onTagSelected"
                    @create-tag="criarTag"
                    @open-picker="openPicker"
                    @deleted-tag="deleteAddedTag"
                    @delete-added-tag="deleteAddedTag"/>

                    <!-- INTEGRACAO -->
                    <div class="form-group mt-5">
                      <div class="custom-control custom-checkbox">
                        <input
                          class="custom-control-input"
                          type="checkbox"
                          id="integracao"
                          value="1"
                          v-model="cliente.possui_integracao"
                        />
                        <label
                          for="integracao"
                          class="custom-control-label"
                        >Utilizar integração para publicação</label>
                      </div>
                    </div>

                    <div class="form-group mt-5">
                      <label for="links_uteis">Links Úteis</label>
                      <textarea id="links_uteis" class="form-control" v-model="cliente.links_uteis"></textarea>
                    </div>

                    <div class="form-group">
                      <label for="informes">Informes</label>
                      <textarea id="informes" class="form-control" v-model="cliente.informes"></textarea>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button class="btn btn-info">Salvar</button>
                  </div>
                </form>
              </div>
              <!-- /.card -->

              <!-- card debug -->
              <div class="card" v-if="$debugWindow">
                <div class="card-header">
                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                      <i class="fas fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <pre>POST:{{ cliente }}</pre>
                  <pre>RESPONSE:{{ retorno }}</pre>
                </div>
              </div>
              <!-- /.card debug -->
            </section>

            <section class="col-lg-7 connectedSortable">
              <div class="card card-info">
                <div class="overlay" v-if="loading">
                  <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>

                <div class="card-header">
                  <!--       <h3 class="card-title">Clientes</h3>
                  &nbsp;-->
                  <h3 class="card-title">{{ titulo }}</h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table
                    id="tblclientes"
                    class="table table-bordered table-striped dataTable"
                    role="grid"
                    aria-describedby="example1_info"
                  >
                    <thead>
                      <tr role="row">
                        <th rowspan="1" colspan="1">ID</th>
                        <th rowspan="1" colspan="1">Nome</th>
                        <th rowspan="1" colspan="1">Moderador</th>
                        <th rowspan="1" colspan="1">S.Cliente</th>
                        <th rowspan="1" colspan="1">Ação Prazo</th>
                        <th rowspan="1" colspan="1">L. Extra</th>
                        <th rowspan="1" colspan="1">Update</th>
                        <th rowspan="1" colspan="1">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr role="row" class="odd" v-for="cliente in clientes" :key="cliente.id">
                        <td class="sorting_1 text-center">{{ cliente.id }}</td>
                        <td class>
                          {{ cliente.nome_fantasia }} <span v-if="cliente.deleted_at" class="text-muted">[INATIVO]</span>
                          <br />
                          <small>{{cliente.telefone}}</small>
                        </td>
                        <td>
                          <div
                            v-for="usuario in usuarios"
                            v-bind:value="usuario.id"
                            v-bind:key="usuario.id"
                          >
                            <p v-if="usuario.id === cliente.moderador_id">{{usuario.name}}</p>
                          </div>
                        </td>
                        <td>
                          <div
                            v-for="usuario in usuarios"
                            v-bind:value="usuario.id"
                            v-bind:key="usuario.id"
                          >
                            <p v-if="usuario.id === cliente.atendente_id">{{usuario.name}}</p>
                          </div>
                        </td>
                        <td>{{ cliente.acao_ao_estourar_prazo }}</td>
                        <td>{{ cliente.limite_solicitacao_extra }}</td>
                        <td class>
                          <span class="d-none">{{ cliente.updated_at}}</span>
                          {{ cliente.updated_at | moment("D/MM HH:mm") }}
                        </td>
                        <td class>
                          <button
                            v-if="cliente.deleted_at"
                            type="button"
                            class="btn btn-block btn-outline-danger btn-xs"
                            disabled
                          >excluir</button>
                          <button
                            v-else
                            type="button"
                            class="btn btn-block btn-outline-danger btn-xs"
                            v-on:click.stop="showConfirm(cliente)"
                          >excluir</button>
                          <button
                            type="button"
                            class="btn btn-block btn-outline-primary btn-xs"
                            v-on:click="updateSelcliente(cliente)"
                          >editar</button>
                          <button
                            type="button"
                            class="btn btn-block btn-outline-secondary btn-xs"
                            v-on:click="clienteCategorias(cliente)"
                          >categorias</button>
                          <!-- <button
                            type="button"
                            class="btn btn-block btn-outline-info btn-xs"
                            v-on:click="contratos(cliente)"
                          >contratos</button>-->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- /.card -->
            </section>
          </div>
          <!-- /.row (main row) -->
        </div>
        <vue-color-picker class="color-picker" :class="{ invisible : !shouldShowColorPicker}" v-model="color" />
        <div class="picker-modal tag-color-input-bg-off" @click="closePicker"></div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>

    <!-- /.content-wrapper -->
    <footer-comp></footer-comp>
  </div>
  <!-- ./wrapper -->
</template>

<style>
.dataTables_filter input {
  border: 1px solid #ccc;
  margin-bottom: 10px !important;
}
table.dataTable {
  border-collapse: collapse;
  margin-bottom: 10px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #efefef !important;
}
.color-picker {
  position:fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.invisible {
  display: none;
}
.tag-color-input {
  border-radius: 30%;
  height: calc(2.25rem + 2px);
  width: calc(2.25rem + 2px);
  border: 1px solid lightgrey;
  line-height: 2;
}
.tag-color-input-bg {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000DD;
  transition-duration: .4s;
  pointer-events: all;
}
.tag-color-input-bg-off {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000000;
  transition-duration: .2s;
  transition-property: background-color;
  pointer-events: none;
}
</style>

<script>

import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import "jquery-ui";
import "jquery-ui-bundle";
import "summernote/dist/summernote-lite.css";
import "summernote/dist/summernote-lite.min.js";
import "summernote/dist/lang/summernote-pt-BR.js";
import Chrome from 'vue-color/src/components/Chrome.vue';
import TagManager from '../components/TagManager.vue';

export default {
  name: "clientes",
  components: {
    'vue-color-picker': Chrome,
    TagManager,
  },
  data() {
    return {
      apiurl: this.$apiUrl + "/clients",
      apiurlusers: this.$apiUrl + "/users",
      titulo: "Carregando lista...",
      retorno: {},
      clientes: [],
      cliente: {},
      usuarios: [],
      table: false,
      editar: false,
      login: {},
      loading: false,
      color: {
        hex: '#ff0000'
      },
      shouldShowColorPicker: false,
      tags: null,
      new_tag_text: null,
      showTagSelect: false,
      updateTags: false,
    };
  },
  methods: {
    getUsuarios() {
      axios
        .get(this.apiurlusers, this.login)
        .then(response => {
          this.usuarios = response.data;
        })
        .catch(error => {
          this.titulo = error;
        });
    },
    getClientes() {
      this.loading = true;
      axios
        .get(this.apiurl + "?include_deleted=1", this.login)
        .then(response => {
          //console.log(response.data);

          this.clientes = response.data;
          this.titulo = "Clientes";
          this.dataTable();
          this.cliente = {};
          this.loading = false;
        })
        .catch(error => {
          this.titulo = error;
        });

      this.editar = false;
    },
    postcliente(cliente) {
      this.loading = true;
      //cliente.id = Date.now();
      cliente.links_uteis = $("#links_uteis").summernote("code");
      cliente.informes = $("#informes").summernote("code");

      var integracao;

      if (cliente.possui_integracao) {
        integracao = 1;
      } else {
        integracao = 0;
      }

      axios
        .post(
          this.apiurl,
          {
            nome_fantasia: cliente.nome_fantasia,
            telefone: cliente.telefone,
            endereco: cliente.endereco,
            acao_ao_estourar_prazo: cliente.acao_ao_estourar_prazo,
            limite_solicitacao_extra: cliente.limite_solicitacao_extra,
            moderador_id: cliente.moderador_id,
            atendente_id: cliente.atendente_id,
            links_uteis: cliente.links_uteis,
            informes: cliente.informes,
            possui_integracao: integracao
          },
          this.login
        )
        .then(response => {
          var _t = this;

          _t.retorno = response.data;

          this.loading = false;

          //ALERT
          if (_t.retorno.status == 201) {
            _t.getClientes();

            _t.showalert("Sucesso", _t.retorno.message, "success");
          } else {
            const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

            for (var i = 0; i < msg.length; i++) {
              var msgerro = msg[i][1];

              _t.showalert("Atenção", msgerro, "warning");
            }
          }
          //FINAL ALERT
        });
    },
    updatecliente(cliente) {
      cliente.links_uteis = $("#links_uteis_edit").summernote("code");
      cliente.informes = $("#informes_edit").summernote("code");

      var integracao;

      if (cliente.possui_integracao) {
        integracao = 1;
      } else {
        integracao = 0;
      }

      var updateurl = this.apiurl + "/" + cliente.id;

      axios
        .patch(
          updateurl,
          {
            nome_fantasia: cliente.nome_fantasia,
            telefone: cliente.telefone,
            endereco: cliente.endereco,
            acao_ao_estourar_prazo: cliente.acao_ao_estourar_prazo,
            limite_solicitacao_extra: cliente.limite_solicitacao_extra,
            moderador_id: cliente.moderador_id,
            atendente_id: cliente.atendente_id,
            links_uteis: cliente.links_uteis,
            informes: cliente.informes,
            possui_integracao: integracao,
            tags: cliente.tags,
          },
          this.login
        )
        .then(response => {
          var _t = this;

          _t.retorno = response.data;

          $("#links_uteis_edit").summernote("code", "");

          $("#informes_edit").summernote("code", "");

          if (_t.retorno.status == 200) {
            _t.getClientes();

            _t.showalert("Cadastro Atualizado", _t.retorno.message, "success");
          } else {
            const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

            for (var i = 0; i < msg.length; i++) {
              var msgerro = msg[i][1];

              _t.showalert("Atenção", msgerro, "error");
            }
          }
        });
    },
    contratos(cliente) {
      this.$router.push("/contratos/" + cliente.id);
    },
    clienteCategorias(cliente) {
      this.$router.push("/cliente-categorias/" + cliente.id);
    },
    updateSelcliente(cliente) {
      this.editar = true;

      if(typeof cliente.tags != 'undefined' && cliente.tags && cliente.tags.length == 0) cliente.tags = null;
      else if (cliente.tags) {
        cliente.tags.forEach((tag, i) => {
          if(tag.dirty) cliente.tags.splice(i, 1);
        });
      }

      this.cliente = cliente; ///WHY????

      setTimeout(function() {
        this.cliente = cliente; ///WHY????

        if (this.cliente.informes == null) {
          $("#informes_edit").summernote("code", "");
        } else {
          $("#informes_edit").summernote("code", this.cliente.informes);
        }

        if (this.cliente.links_uteis == null) {
          $("#links_uteis_edit").summernote("code", "");
        } else {
          $("#links_uteis_edit").summernote("code", this.cliente.links_uteis);
        }
      }, 100);
    },
    updateCancel() {
      this.editar = false;
      this.cliente = {};

      $("#links_uteis_edit").summernote("code", "");
      $("#informes_edit").summernote("code", "");
    },
    deletecliente(cliente) {
      var deleteurl = this.apiurl + "/" + cliente.id;

      axios.delete(deleteurl, this.login).then(response => {
        var _t = this;

        _t.retorno = response.data;

        if (_t.retorno.status == 200) {
          _t.getClientes();

          _t.showalert("Cadastro Excluído", _t.retorno.message, "success");
        } else {
          const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

          for (var i = 0; i < msg.length; i++) {
            var msgerro = msg[i][1];

            _t.showalert("Atenção", msgerro, "error");
          }
        }
      });
    },
    showConfirm(cliente) {
      this.$fire({
        title: "Excluir",
        text:
          "Você tem certeza que deseja excluir " + cliente.nome_fantasia + "?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não"
      }).then(result => {
        if (result.value) {
          this.deletecliente(cliente);
        }
      });
    },
    dataTable() {
      var _e = this;

      if (_e.table != false) {
        _e.table.destroy();

        setTimeout(function() {
          _e.renderTable();
        }, 300);
      } else {
        setTimeout(function() {
          _e.renderTable();
        }, 300);
      }
    },
    renderTable() {
      this.table = $("#tblclientes").DataTable({
        paging: true,
        bInfo: true,
        bLengthChange: false,
        searching: true,
        language: {
          sEmptyTable: "Nenhum registro encontrado",
          sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
          sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
          sInfoFiltered: "(Filtrados de _MAX_ registros)",
          sInfoPostFix: "",
          sInfoThousands: ".",
          sLengthMenu: "_MENU_ resultados por página",
          sLoadingRecords: "Carregando...",
          sProcessing: "Processando...",
          sZeroRecords: "Nenhum registro encontrado",
          sSearch: "Pesquisar",
          oPaginate: {
            sNext: "Próximo",
            sPrevious: "Anterior",
            sFirst: "Primeiro",
            sLast: "Último"
          },
          oAria: {
            sSortAscending: ": Ordenar colunas de forma ascendente",
            sSortDescending: ": Ordenar colunas de forma descendente"
          },
          select: {
            rows: {
              _: "Selecionado %d linhas",
              "0": "Nenhuma linha selecionada",
              "1": "Selecionado 1 linha"
            }
          }
        }
      });
    },
    openPicker() {
      this.shouldShowColorPicker = true;

      this.$el.querySelector('.picker-modal').classList.remove('tag-color-input-bg-off');
      this.$el.querySelector('.picker-modal').classList.add('tag-color-input-bg');
    },
    closePicker() {
      this.shouldShowColorPicker = false;

      this.$el.querySelector('.picker-modal').classList.add('tag-color-input-bg-off');
      this.$el.querySelector('.picker-modal').classList.remove('tag-color-input-bg');
    },
    handleClick(e) {
      if(!this.$el.querySelector('.tag-select').contains(e.target)) {
        this.closeTagSelect();
      }
    },
    openTagSelect() {
      this.showTagSelect = true;

      this.$el.addEventListener('click', this.handleClick);
    },
    closeTagSelect() {
      this.showTagSelect = false;

      this.$el.removeEventListener('click', this.handleClick);
    },
    onTagSelected(tag) {
      this.closeTagSelect();

      if(typeof this.cliente.id != 'undefined') {
        if(!this.cliente.tags) this.cliente.tags = [];

        if(this.cliente.tags.find(existing_tag => {
          return existing_tag.id == tag.id;
        })) return;

        tag.dirty = true;
        
        this.cliente.tags.push(tag);
      } else {
        if(!this.tags) this.tags = [];

        if(this.tags.find(existing_tag => {
          return existing_tag.id == tag.id;
        })) return;
        
        this.tags.push(tag);
      }
    },
    criarTag(tag) {
      let payload = {
        tag: tag.tag,
        cor: tag.color.hex,
      }

      axios
      .post(this.$apiUrl + '/tags', payload)
      .then( () => {
        this.updateTags = !this.updateTags;
      })
      .catch(err => {
        console.error(err);
      });
    },
    refreshClientTags() {
      axios
      .get(this.apiurl + '/' + this.cliente.id + '/tags')
      .then( response => {
        if( response.data.length > 0 ) this.cliente.tags = response.data;
      })
      .catch(err => console.error(err));
    },
    deleteAddedTag(tag) {
      if(this.editar) {
        this.cliente.tags.forEach( (current_tag, i) => {
          if(current_tag.id == tag.id) this.cliente.tags.splice(i, 1);
          if(this.cliente.tags.length == 0) this.cliente.tags = null;
        });
      } else {
        this.tags.forEach( (current_tag, i) => {
          if(current_tag.id == tag.id) this.tags.splice(i, 1);
          if(this.tags.length == 0) this.tags = null;
        });
      }
    }
  },
  mounted() {
    $(document).ready(function() {
      $("#links_uteis").summernote({
        lang: "pt-BR",
        placeholder: "Links Úteis ...",
        tabsize: 2,
        height: 200,
        toolbar: [
          ["style", ["style"]],
          ["font", ["bold", "underline", "clear"]],
          ["fontname", ["fontname"]],
          ["color", ["color"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["table", ["table"]],
          ["insert", ["link", "picture", "video"]],
          ["view", ["fullscreen", "codeview", "help"]]
        ]
      });

      $("#informes").summernote({
        lang: "pt-BR",
        placeholder: "Informes ...",
        tabsize: 2,
        height: 200,
        toolbar: [
          ["style", ["style"]],
          ["font", ["bold", "underline", "clear"]],
          ["fontname", ["fontname"]],
          ["color", ["color"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["table", ["table"]],
          ["insert", ["link", "picture", "video"]],
          ["view", ["fullscreen", "codeview", "help"]]
        ]
      });

      $("#links_uteis_edit").summernote({
        lang: "pt-BR",
        placeholder: "Links Úteis ...",
        tabsize: 2,
        height: 200,
        toolbar: [
          ["style", ["style"]],
          ["font", ["bold", "underline", "clear"]],
          ["fontname", ["fontname"]],
          ["color", ["color"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["table", ["table"]],
          ["insert", ["link", "picture", "video"]],
          ["view", ["fullscreen", "codeview", "help"]]
        ]
      });

      $("#informes_edit").summernote({
        lang: "pt-BR",
        placeholder: "Informes ...",
        tabsize: 2,
        height: 200,
        toolbar: [
          ["style", ["style"]],
          ["font", ["bold", "underline", "clear"]],
          ["fontname", ["fontname"]],
          ["color", ["color"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["table", ["table"]],
          ["insert", ["link", "picture", "video"]],
          ["view", ["fullscreen", "codeview", "help"]]
        ]
      });
    });

    if (localStorage.getItem("login")) {
      try {
        const loginJSON = JSON.parse(localStorage.getItem("login"));

        this.login = {
          headers: {
            Authorization: "Bearer ".concat(loginJSON.api_token),
            Accept: "application/json"
          }
        };

        this.getClientes();
        this.getUsuarios();
      } catch (e) {
        this.$router.push("/login");
        localStorage.removeItem("login");
      }
    } else {
      this.$router.push("/login");
    }
  },
  destroyed() {}
};
</script>
