<template>
  <div class="wrapper">
    <navigator></navigator>

    <sidebar></sidebar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <!-- HEADER -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Small boxes (Stat box) -->

          <!-- Main row -->
          <div class="row">
            <section class="col-lg-5 connectedSortable">
              <!-- card editar -->
              <div class="card card-info" v-if="editar">
                <div class="card-header">
                  <h3 class="card-title">Editar Categoria</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form role="form" @submit.prevent="updateCategoria(categoria)">
                  <div class="card-body">


                    <div class="form-group">
                      <label for="name">Categoria:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="categoria"
                        placeholder="Categoria"
                        v-model="categoria.descricao"
                      />
                    </div>

           

                    
                  </div>
                  <!-- /.card-body -->
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button class="btn btn-info">Atualizar</button>
                    <button class="btn btn-secondary ml-2" v-on:click="updateCancel()">Cancelar</button>
                  </div>
                </form>
              </div>
              <!-- /.card -->

              <!-- card adicionar -->
              <div class="card card-info" v-else>
                <div class="card-header">
                  <h3 class="card-title">Adicionar Categoria</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form role="form" @submit.prevent="postCategoria(categoria)">
                  <div class="card-body">

                    <div class="form-group">

                      <label for="name">Descrição:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="descricao"
                        placeholder="Descrição"
                        v-model="categoria.descricao"
                      />
                    </div>

                    <!-- /.card-body -->
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-info">Salvar</button>
                  </div>
                </form>
              </div>
              <!-- /.card -->

              <!-- card debug -->
              <div class="card" v-if="$debugWindow">
                <div class="card-header">
                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                      <i class="fas fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <pre>POST:{{ categoria }}</pre>
                  <pre>RESPONSE:{{ retorno }}</pre>
                </div>
              </div>
              <!-- /.card debug -->
            </section>

            <section class="col-lg-7 connectedSortable">
              <div class="card card-info">
                <div class="card-header">
                  <!--       <h3 class="card-title">categorias</h3>
                  &nbsp;-->
                  <h3 class="card-title">{{ titulo }}</h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table
                    id="tblcategorias"
                    class="table table-bordered table-striped dataTable"
                    role="grid"
                    aria-describedby="example1_info"
                  >
                    <thead>
                      <tr role="row">
                        <th rowspan="1" colspan="1">ID</th>
                        <th rowspan="1" colspan="1">Categoria</th>
                        <th rowspan="1" colspan="1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        role="row"
                        class="odd"
                        v-for="(categoria, index) in categorias"
                        :key="index"
                      >
                        <td class="sorting_1 text-center">{{ categoria.id }}</td>

                        <td class="sorting_1 text-center">{{ categoria.descricao }}</td>

                        <td class>
                          <button
                            type="button"
                            class="btn btn-block btn-outline-danger btn-xs"
                            v-on:click.stop="showConfirm(categoria)"
                          >excluir</button>

                          <button
                            type="button"
                            class="btn btn-block btn-outline-primary btn-xs"
                            v-on:click="updateSelCategoria(categoria)"
                          >editar</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- /.card -->
            </section>
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>

    <!-- /.content-wrapper -->
    <footer-comp></footer-comp>
  </div>
  <!-- ./wrapper -->
</template>

<style>
.dataTables_filter input {
  border: 1px solid #ccc;
  margin-bottom: 10px !important;
}
table.dataTable {
  border-collapse: collapse;
  margin-bottom: 10px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #efefef !important;
}
</style>

<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import "jquery-ui";
import "jquery-ui-bundle";
import "summernote/dist/summernote-lite.css";
import "summernote/dist/summernote-lite.min.js";
import "summernote/dist/lang/summernote-pt-BR.js";

export default {
  name: "categorias",
  data() {
    return {
      apiurl: this.$apiUrl + "/clients",
      apiurlusers: this.$apiUrl + "/users",
      categorias_url:
        this.$apiUrl + "/clients/" + this.$route.params.id + "/categorias",
      titulo: "Carregando lista...",
      retorno: {},
      categorias: [],
      categoria: {},
      usuarios: [],
      table: false,
      editar: false,
      login: {}
    };
  },
  methods: {
    getUsuarios() {
      axios
        .get(this.apiurlusers, this.login)
        .then(response => {
          this.usuarios = response.data;
        })
        .catch(error => {
          this.titulo = error;
        });
    },
    getClienteCategorias() {
      axios
        .get(this.categorias_url, this.login)
        .then(response => {
          //console.log(response.data);

          this.categorias = response.data.message;          
          this.titulo = "Categorias"
          this.dataTable();
          
        })
        .catch(error => {
          this.titulo = error;
        });

      this.editar = false;
    },
    postCategoria(categoria) {
      axios.post(this.categorias_url, categoria, this.login).then(response => {
        var _t = this;

        _t.retorno = response.data;

        //console.log(_t.retorno);

        //ALERT
        if (_t.retorno.status == 200) {

          _t.getClienteCategorias();

          _t.showalert("Sucesso", _t.retorno.message, "success");

          this.categoria = {};

        } else {
          const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

          for (var i = 0; i < msg.length; i++) {
            var msgerro = msg[i][1];

            _t.showalert("Atenção", msgerro, "warning");
          }
        }
        //FINAL ALERT
      });
    },
    updateCategoria(categoria) {
      

      var updateurl = this.categorias_url + "/" + categoria.id;

      axios
        .patch(
          updateurl,
          categoria,
          this.login
        )
        .then(response => {
          var _t = this;

          _t.retorno = response.data;

          //console.log(_t.retorno);

          if (_t.retorno.status == 201) {
            _t.getClienteCategorias();

            _t.showalert("Cadastro Atualizado", _t.retorno.message, "success");
          } else {
            const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

            for (var i = 0; i < msg.length; i++) {
              var msgerro = msg[i][1];

              _t.showalert("Atenção", msgerro, "error");
            }
          }
        });
    },
     updateSelCategoria(categoria) {
      this.editar = true;

      this.categoria = categoria; ///WHY????

    },
    updateCancel() {
      this.editar = false;
      this.categoria = {};
      
      this.getClienteCategorias();
    },
    deleteCategoria(categoria) {

      var deleteurl = this.categorias_url + "/" + categoria.id;

      axios.delete(deleteurl, this.login).then(response => {

        var _t = this;

        _t.retorno = response.data;

        //console.log(_t.retorno);

        if (_t.retorno.status == 201) {
          _t.getClienteCategorias();

          _t.showalert("Cadastro Excluído", _t.retorno.message, "success");

        } else {

          const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

          for (var i = 0; i < msg.length; i++) {
            var msgerro = msg[i][1];

            _t.showalert("Atenção", msgerro, "error");
          }
        }
      });
    },
    showConfirm(categoria) {
      this.$fire({
        title: "Excluir",
        text:
          "Você tem certeza que deseja excluir " + categoria.nome_fantasia + "?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não"
      }).then(result => {
        if (result.value) {
          this.deleteCategoria(categoria);
        }
      });
    },
    dataTable() {
      var _e = this;

      if (_e.table != false) {
        _e.table.destroy();

        setTimeout(function() {
          _e.renderTable();
        }, 300);
      } else {
        setTimeout(function() {
          _e.renderTable();
        }, 300);
      }
    },
    renderTable() {
      this.table = $("#tblcategorias").DataTable({
        paging: true,
        bInfo: true,
        bLengthChange: false,
        searching: true,
        language: {
          sEmptyTable: "Nenhum registro encontrado",
          sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
          sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
          sInfoFiltered: "(Filtrados de _MAX_ registros)",
          sInfoPostFix: "",
          sInfoThousands: ".",
          sLengthMenu: "_MENU_ resultados por página",
          sLoadingRecords: "Carregando...",
          sProcessing: "Processando...",
          sZeroRecords: "Nenhum registro encontrado",
          sSearch: "Pesquisar",
          oPaginate: {
            sNext: "Próximo",
            sPrevious: "Anterior",
            sFirst: "Primeiro",
            sLast: "Último"
          },
          oAria: {
            sSortAscending: ": Ordenar colunas de forma ascendente",
            sSortDescending: ": Ordenar colunas de forma descendente"
          },
          select: {
            rows: {
              _: "Selecionado %d linhas",
              "0": "Nenhuma linha selecionada",
              "1": "Selecionado 1 linha"
            }
          }
        }
      });
    }
  },
  mounted() {
    if (localStorage.getItem("login")) {
      try {
        const loginJSON = JSON.parse(localStorage.getItem("login"));

        this.login = {
          headers: {
            Authorization: "Bearer ".concat(loginJSON.api_token),
            Accept: "application/json",
            "Cache-Control": "no-cache"
          }
        };

        this.getClienteCategorias();
        //this.getUsuarios();
      } catch (e) {
        this.$router.push("/login");
        localStorage.removeItem("login");
      }
    } else {
      this.$router.push("/login");
    }
  },
  destroyed() {}
};
</script>
