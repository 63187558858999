<template>
	<Relatorio>
		<!-- right col (We are only adding the ID to make the widgets sortable)-->
		<section class="col-lg-12 connectedSortable">
			<div class="card">
				<div class="card-header">
					<h3>Métricas Aprovações</h3>
					<div class="card-tools">
						<!-- Maximize Button -->
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
					<!-- /.card-tools -->
				</div>

				<!-- /.card-header -->
				<div class="card-body">
					<div class="row justify-content-md-center">
						<div class="col-sm-12 col-md-6 col-lg-2 sm">
							<label for="">Ciclo</label>

							<input
								type="text"
								class="form-control"
								placeholder="mm/aa"
								v-model="filtro.ciclo"
								v-mask="'##/##'"
								name="ciclo"
								autocomplete="off"
							/>
						</div>
					</div>

					<div class="row mt-3">
						<div class="col">
							<div class="form-group">
								<button
									v-on:click="getMetricasAprovacoes()"
									id="btnn"
									type="button"
									class="btn btn-info form-control"
								>
									PESQUISAR
								</button>
							</div>
						</div>
					</div>
				</div>
				<!-- Card Body -->
			</div>
			<!-- Card -->
		</section>
		<!-- right col -->

		<!-- Overall result section -->
		<section class="col-lg-12 connectedSortable">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div
				v-if="relatorio"
				class="d-flex flex-row justify-content-around"
			>
				<div class="card col-3">
					<div
						class="card-header d-flex flex-row justify-content-center"
					>
						<h3>
							{{
								parseInt(
									(totalConteudosAprovados * 100) /
										(totalConteudosAprovados +
											totalConteudosAprovadosAlteracoes +
											totalConteudosReprovados +
											totalConteudosReprovadoEAlterados),
								) || 0
							}}% Aprovados
						</h3>
					</div>
					<div
						class="card-body d-flex flex-row justify-content-center"
					>
						{{ totalConteudosAprovados }} de
						{{
							totalConteudosAprovados +
								totalConteudosAprovadosAlteracoes +
								totalConteudosReprovados +
								totalConteudosReprovadoEAlterados
						}}
					</div>
				</div>
				<div class="card col-3">
					<div
						class="card-header d-flex flex-row justify-content-center"
					>
						<h3>
							{{
								parseInt(
									(totalConteudosAprovadosAlteracoes * 100) /
										(totalConteudosAprovados +
											totalConteudosAprovadosAlteracoes +
											totalConteudosReprovados +
											totalConteudosReprovadoEAlterados),
								) || 0
							}}% Aprovados com Ajustes
						</h3>
					</div>
					<div
						class="card-body d-flex flex-row justify-content-center"
					>
						{{ totalConteudosAprovadosAlteracoes }} de
						{{
							totalConteudosAprovados +
								totalConteudosAprovadosAlteracoes +
								totalConteudosReprovados +
								totalConteudosReprovadoEAlterados
						}}
					</div>
				</div>
				<div class="card col-3">
					<div
						class="card-header d-flex flex-row justify-content-center"
					>
						<h3>
							{{
								parseInt(
									((totalConteudosReprovados +
										totalConteudosReprovadoEAlterados) *
										100) /
										(totalConteudosAprovados +
											totalConteudosAprovadosAlteracoes +
											totalConteudosReprovados +
											totalConteudosReprovadoEAlterados),
								) || 0
							}}% Reprovados
						</h3>
					</div>
					<div
						class="card-body d-flex flex-row justify-content-center"
					>
						{{
							totalConteudosReprovados +
								totalConteudosReprovadoEAlterados
						}}
						de
						{{
							totalConteudosAprovados +
								totalConteudosAprovadosAlteracoes +
								totalConteudosReprovados +
								totalConteudosReprovadoEAlterados
						}}
					</div>
				</div>
			</div>
		</section>

		<!-- Clientes Section -->
		<section class="col-lg-12 connectedSortable">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<div class="row">
						<div class="col xs12 md4">
							<h3>Clientes</h3>
						</div>
					</div>
					<div class="card-tools">
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
				</div>

				<div class="card-body">
					<!-- <div class="table-responsive"> This uses responsive search -->
					<div class="table-responsive">
						<table
							id="tblRelatorioCliente"
							class="table table-bordered table-striped dataTable"
							role="grid"
							aria-describedby="conteudos"
						>
							<thead>
								<tr role="row">
									<th rowspan="1" colspan="1">Cliente</th>
									<th rowspan="1" width="180px" colspan="1">
										Aprovadas
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Aprovadas com alteração
									</th>
									<th rowspan="1" width="100px" colspan="1">
										Reprovadas
									</th>
								</tr>
							</thead>

							<tbody>
								<tr
									role="row"
									class="odd"
									v-for="(cliente,
									index) in relatorio.clientes"
									:key="index"
								>
									<td>{{ cliente.nome_fantasia }}</td>
									<td>
										{{
											parseInt(
												(cliente.conteudos_aprovados *
													100) /
													totalConteudosCliente(
														cliente,
													),
											) || 0
										}}% ({{
											cliente.conteudos_aprovados
										}}
										de {{ totalConteudosCliente(cliente) }})
									</td>
									<td>
										{{
											parseInt(
												(cliente.conteudos_aprovados_com_alteracoes *
													100) /
													totalConteudosCliente(
														cliente,
													),
											) || 0
										}}% ({{
											cliente.conteudos_aprovados_com_alteracoes
										}}
										de {{ totalConteudosCliente(cliente) }})
									</td>
									<td>
										{{
											parseInt(
												((cliente.conteudos_reprovados +
													cliente.conteudos_alterados_e_reprovados) *
													100) /
													totalConteudosCliente(
														cliente,
													),
											) || 0
										}}% ({{
											cliente.conteudos_reprovados +
												cliente.conteudos_alterados_e_reprovados
										}}
										de {{ totalConteudosCliente(cliente) }})
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>

		<!-- Executor Section -->
		<section class="col-lg-12 connectedSortable">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<div class="row">
						<div class="col xs12 md4">
							<h3>Executores</h3>
						</div>
						<div class="col xs12 md4">
							<v-select
								taggable
								id="executor"
								placeholder="Selecione o executor"
								label="name"
								:options="usuarios"
								:reduce="(usuarios) => usuarios.id"
								v-model="filtro.executor_id"
							></v-select>
						</div>
					</div>
					<div class="card-tools">
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
				</div>

				<div class="card-body">
					<!-- <div class="table-responsive"> This uses responsive search -->
					<div class="table-responsive">
						<table
							id="tblRelatorio"
							class="table table-bordered table-striped dataTable"
							role="grid"
							aria-describedby="conteudos"
						>
							<thead>
								<tr role="row">
									<th rowspan="1" colspan="1">Executor</th>
									<th rowspan="1" width="180px" colspan="1">
										Aprovadas
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Aprovadas com alteração
									</th>
									<th rowspan="1" width="100px" colspan="1">
										Reprovadas
									</th>
								</tr>
							</thead>

							<tbody>
								<tr
									role="row"
									class="odd"
									v-for="(registro,
									index) in relatorio.registros"
									:key="index"
								>
									<td>{{ registro.executor }}</td>
									<td>{{ registro.aprovadas }}</td>
									<td>{{ registro.aprovadas_alteracao }}</td>
									<td>{{ registro.reprovadas }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>

		<!-- Tipo Section -->
		<section class="col-lg-12 connectedSortable">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<div class="row">
						<div class="col xs12 md4">
							<h3>Tipo</h3>
						</div>
						<div class="col xs12 md4">
							<v-select
								taggable
								id="tipo"
								placeholder="Selecione o tipo"
								label="descricao"
								:options="tipos"
								:reduce="(tipos) => tipos.id"
								v-model="filtro.conteudo_tipo_id"
							></v-select>
						</div>
					</div>
					<div class="card-tools">
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
				</div>

				<div class="card-body">
					<!-- <div class="table-responsive"> This uses responsive search -->
					<div class="table-responsive">
						<table
							id="tblRelatorio"
							class="table table-bordered table-striped dataTable"
							role="grid"
							aria-describedby="conteudos"
						>
							<thead>
								<tr role="row">
									<th rowspan="1" colspan="1">Tipo</th>
									<th rowspan="1" width="180px" colspan="1">
										Aprovadas
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Aprovadas com alteração
									</th>
									<th rowspan="1" width="100px" colspan="1">
										Reprovadas
									</th>
								</tr>
							</thead>

							<tbody>
								<tr
									role="row"
									class="odd"
									v-for="(registro,
									index) in relatorio.registros"
									:key="index"
								>
									<td>{{ registro.tipo }}</td>
									<td>{{ registro.aprovadas }}</td>
									<td>{{ registro.aprovadas_alteracao }}</td>
									<td>{{ registro.reprovadas }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>

		<!-- Moderador Section -->
		<section class="col-lg-12 connectedSortable">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<div class="row">
						<div class="col xs12 md4">
							<h3>Moderador</h3>
						</div>
						<div class="col xs12 md4">
							<v-select
								taggable
								id="moderador"
								placeholder="Selecione o Moderador"
								label="name"
								:options="usuarios"
								:reduce="(usuarios) => usuarios.id"
								v-model="filtro.moderador_id"
							></v-select>
						</div>
					</div>
					<div class="card-tools">
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
				</div>

				<div class="card-body">
					<!-- <div class="table-responsive"> This uses responsive search -->
					<div class="table-responsive">
						<table
							id="tblRelatorio"
							class="table table-bordered table-striped dataTable"
							role="grid"
							aria-describedby="conteudos"
						>
							<thead>
								<tr role="row">
									<th rowspan="1" colspan="1">Moderador</th>
									<th rowspan="1" width="180px" colspan="1">
										Aprovadas
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Aprovadas com alteração
									</th>
									<th rowspan="1" width="100px" colspan="1">
										Reprovadas
									</th>
								</tr>
							</thead>

							<tbody>
								<tr
									role="row"
									class="odd"
									v-for="(registro,
									index) in relatorio.registros"
									:key="index"
								>
									<td>{{ registro.tipo }}</td>
									<td>{{ registro.aprovadas }}</td>
									<td>{{ registro.aprovadas_alteracao }}</td>
									<td>{{ registro.reprovadas }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>
	</Relatorio>
</template>
<style>
.cursor-pointer {
	cursor: pointer;
}
.note-float-left,
note-float-right {
	padding: 4px;
}
</style>
<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import Relatorio from "../../components/Relatorio.vue";

export default {
	name: "relatorio-metricas-aprovacoes",
	data() {
		return {
			titulo: "Carregando lista...",
			usuarios_url: this.$apiUrl + "/users",
			clientes_url: this.$apiUrl + "/clients",
			metricas_aprovacoes_url: this.$apiUrl + "/metrica_aprovacoes",
			tipos_url: this.$apiUrl + "/conteudo_tipo",
			login: {},
			loginObj: {},
			filtro: {
				ciclo: this.$moment().format("MM/YY"),
			},
			relatorio: [],
			clientes: [],
			usuarios: [],
			tipos: [],
			isAdmin: false,
			table: false,
			loading: false,
		};
	},
	computed: {
		totalConteudosAprovados: {
			cache: false,
			get() {
				let total = 0;
				for (let i in this.relatorio.clientes) {
					total += parseInt(
						this.relatorio.clientes[i].conteudos_aprovados,
					);
				}
				return total;
			},
		},
		totalConteudosAprovadosAlteracoes: {
			cache: false,
			get() {
				let total = 0;
				for (let i in this.relatorio.clientes) {
					total += parseInt(
						this.relatorio.clientes[i]
							.conteudos_aprovados_com_alteracoes,
					);
				}
				return total;
			},
		},
		totalConteudosReprovados: {
			cache: false,
			get() {
				let total = 0;
				for (let i in this.relatorio.clientes) {
					total += parseInt(
						this.relatorio.clientes[i].conteudos_reprovados,
					);
				}
				return total;
			},
		},
		totalConteudosReprovadoEAlterados: {
			cache: false,
			get() {
				let total = 0;
				for (let i in this.relatorio.clientes) {
					total += parseInt(
						this.relatorio.clientes[i]
							.conteudos_alterados_e_reprovados,
					);
				}
				return total;
			},
		},
	},
	methods: {
		totalConteudosCliente(cliente) {
			return (
				cliente.conteudos_aprovados +
				cliente.conteudos_aprovados_com_alteracoes +
				cliente.conteudos_reprovados +
				cliente.conteudos_alterados_e_reprovados
			);
		},
		getMetricasAprovacoes() {
			this.loading = true;
			axios
				.get(this.metricas_aprovacoes_url, { params: this.filtro })
				.then((response) => {
					this.relatorio.clientes = response.data;
					this.dataTable();
					this.loading = false;
				})
				.catch((error) => {
					this.showalert("Atenção", error, "error");
				});
		},
		getUsuarios() {
			axios
				.get(this.usuarios_url, this.login)
				.then((response) => {
					this.usuarios = response.data;
				})
				.catch((error) => {
					this.titulo = error;
				});
		},
		getClientes() {
			axios
				.get(this.clientes_url + "?include_deleted=1", this.login)
				.then((response) => {
					//console.log(response.data);
					this.clientes = response.data;
				})
				.catch((error) => {
					this.titulo = error;
				});
			this.editar = false;
		},
		getTipos() {
			axios
				.get(this.tipos_url, this.login)
				.then((response) => {
					this.tipos = response.data;
				})
				.catch((error) => {
					console.error("Tipos: " + error);
				});
		},
		deleteregistro(registro) {
			var deleteurl = this.avaliacao_mensal_url + "/" + registro.id;
			axios.delete(deleteurl, this.login).then((response) => {
				var _t = this;
				_t.retorno = response.data;
				if (_t.retorno.status == 201) {
					_t.getMetricasAprovacoes();
					_t.showalert(
						"Cadastro Excluído",
						_t.retorno.message,
						"success",
					);
				} else {
					const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'
					for (var i = 0; i < msg.length; i++) {
						var msgerro = msg[i][1];
						_t.showalert("Atenção", msgerro, "error");
					}
				}
			});
		},
		showConfirm(registro) {
			this.$fire({
				title: "Excluir",
				text:
					"Você tem certeza que deseja excluir #" + registro.id + "?",
				type: "question",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Sim, excluir!",
				cancelButtonColor: "#d33",
				cancelButtonText: "Não",
			}).then((result) => {
				if (result.value) {
					this.deleteregistro(registro);
				}
			});
		},
		dataTable() {
			var _e = this;
			if (_e.table != false) {
				_e.table.destroy();
				setTimeout(() => {
					_e.renderTable();
				}, 100);
			} else {
				setTimeout(() => {
					_e.renderTable();
				}, 100);
			}
		},
		renderTable() {
			$.fn.dataTable.ext.type.order["percentual-pre"] = function(d) {
				return parseInt(d.match(/(\d*)%/)[1]);
			};
			this.table = $("#tblRelatorioCliente").DataTable({
				paging: true,
				bInfo: true,
				bLengthChange: false,
				searching: true,
				columnDefs: [
					{
						type: "percentual",
						targets: [-1, -2, -3],
					},
				],
				order: [
					[3, "desc"],
					[2, "desc"],
					[1, "desc"],
				],
				pageLength: 10,
				language: {
					sEmptyTable: "Pesquisando registros...",
					sInfo:
						"Mostrando de _START_ até _END_ de _TOTAL_ registros",
					sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
					sInfoFiltered: "(Filtrados de _MAX_ registros)",
					sInfoPostFix: "",
					sInfoThousands: ".",
					sLengthMenu: "_MENU_ resultados por página",
					sLoadingRecords: "Carregando...",
					sProcessing: "Processando...",
					sZeroRecords: "Nenhum registro encontrado",
					sSearch: "Pesquisar",
					oPaginate: {
						sNext: "Próximo",
						sPrevious: "Anterior",
						sFirst: "Primeiro",
						sLast: "Último",
					},
					oAria: {
						sSortAscending: ": Ordenar colunas de forma ascendente",
						sSortDescending:
							": Ordenar colunas de forma descendente",
					},
					select: {
						rows: {
							_: "Selecionado %d linhas",
							"0": "Nenhuma linha selecionada",
							"1": "Selecionado 1 linha",
						},
					},
				},
			});
		},
	},
	mounted() {
		this.contador_login = localStorage.getItem("contador");
		if (localStorage.getItem("login")) {
			try {
				const loginJSON = JSON.parse(localStorage.getItem("login"));
				this.loginObj = loginJSON;
				this.isAdmin = loginJSON.isAdmin;
				this.login = {
					headers: {
						Authorization: "Bearer ".concat(loginJSON.api_token),
						Accept: "application/json",
						"Cache-Control": "no-cache",
					},
				};
				setTimeout(() => {
					this.getMetricasAprovacoes();
					this.getClientes();
					this.getUsuarios();
					this.getTipos();
				}, 300);
			} catch (e) {
				localStorage.removeItem("login");
				this.$router.push("/login");
			}
		} else {
			localStorage.removeItem("login");
			this.$router.push("/login");
		}
	},
	destroyed() {
		localStorage.contador = 0;
	},
	components: { Relatorio },
};
</script>
