<template>
    <div class="tag" :style="{ backgroundColor: tag.cor }" @click="$emit('tag-click', tag)">{{ tag.tag }}</div>
</template>

<script>
export default {
    props: [
        'tag'
    ]
}
</script>
<style scoped>
.tag {
  height: calc(2rem + 2px);
  border-radius: .4rem;
  padding: .3rem;
  color: white;
  font-weight: bolder;
  width: auto;
  display: inline-block;
}
</style>