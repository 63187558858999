<template>
  <!-- Footer -->
  <footer class="main-footer">
    <strong>
      Copyright &copy; 2020
      <a href="#">E-Saúde</a>. 
    </strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Versão</b> 1.0
    </div>
  </footer>
  <!-- /.footer -->
</template>

<script>

import $ from "jquery";

export default {
  name: "footer-comp",    
  mounted() {
    const el = document.body;
    el.classList.add(
      "sidebar-mini",
      "layout-fixed",
      "control-sidebar-push",
      "sidebar-collapse"
    );
    //this.table = $('#tblclientes').DataTable();

  
   // Inicio - Make the dashboard widgets sortable Using jquery UI
    $(".connectedSortable").sortable({
      placeholder: "sort-highlight",
      connectWith: ".connectedSortable",
      handle: ".card-header, .nav-tabs",
      forcePlaceholderSize: true,
      zIndex: 999999
    });

    $(".connectedSortable .card-header, .connectedSortable .nav-tabs-custom").css("cursor", "move");
    // Final - Make the dashboard widgets sortable Using jquery UI
  },
  destroyed() {
    const el = document.body;
    el.classList.remove(
      "sidebar-mini",
      "layout-fixed",
      "control-sidebar-push",
      "sidebar-collapse"
    );
  }
};
</script>
