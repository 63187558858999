<template>
    <div class="tag-selector hidden">
        <template v-if="tags">
            <template v-for="tag in tags">
                <div :key="tag.id" @click="selectTag(tag)" class="tag-field">
                    <tag :tag="tag"/>
                    <div class="btn btn-light btn-small" @click.stop.prevent="deleteTag(tag)"><i class="fas fa-trash"></i></div>
                </div>
            </template>
        </template>
        <div v-else>
            Nenhuma tag cadastrada no sistema
        </div>
        <div :id="'delete-modal-'+_uid" class="modal fade">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">Excluir essa tag do sistema?</h3>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-default" @click="closeDeleteModal" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger" @click="confirmDelete">Excluir</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Tag from "./Tag.vue";
import $ from "jquery";

axios.defaults.withCredentials = true;

export default {
    components: {
        Tag,
    },
    data() {
        return {
            tags: null,
            tagToBeDeleted: null,
        }
    },
    methods: {
        selectTag(tag) {
            this.$emit('selected', tag);
        },
        refresh() {
            axios
            .get(this.$apiUrl + '/tags')
            .then(response => {
                if (response.data.length > 0) this.tags = response.data;
                else this.tags = null;
            })
            .catch( err => {
                console.err(err);
            });
        },
        deleteTag(tag) {
            $('#delete-modal-'+this._uid).modal('show');
            this.tagToBeDeleted = tag;
        },
        confirmDelete() {
            axios
            .delete( this.$apiUrl + '/tags/' + this.tagToBeDeleted.id)
            .then( () => {
                this.refresh();

                this.$emit('deleted-tag', this.tagToBeDeleted);
                this.tagToBeDeleted = null;
            })
            .catch( err => console.error(err));
            $('#delete-modal-'+this._uid).modal('hide');
        },
        openSelect() {
            this.$el.classList.remove('hidden');

            document.addEventListener('click', this.closeSelect);
        },
        closeSelect(e) {
            if(!this.$el.contains(e.target)) {
                this.$el.classList.add('hidden');
                document.removeEventListener('click', this.closeSelect);
            }
        },
        closeDeleteModal() {
            $('delete-modal-'+this._uid).modal('hide');
        }
    },
    mounted() {
        this.refresh();

        $('#delete-modal-'+this._uid).appendTo('body');
    },
    beforeDestroy() {
        $('#delete-modal-'+this._uid).appendTo(this.$el);
    }
}
</script>
<style scoped>
    .tag-selector.hidden {
        display: none;
    }
    .tag-selector {
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: .25rem;
        border: 1px solid lightgrey;
        z-index: 100;
        box-shadow: 2px 2px 5px 1px #00000050;
    }
    .tag-field {
        padding: 3px;
        display: flex;
        justify-content: space-between;
        gap: 7.5px
    }
</style>