<template>
  <!-- Inicio chat FUNCIONARIOS -->
  <section class="connectedSortable"  v-if="$debugWindow">
    <div class="card direct-chat direct-chat-warning collapsed-card">
      <div class="card-header">
        <h3 class="card-title">Debug</h3>

        <div class="card-tools">
          <span
            data-toggle="tooltip"
            title="Novas Mensagens"
            class="badge bg-primary"
          >{{ mensagens && mensagens.length ? mensagens.length : 0 }}</span>
          <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <!-- Conversations are loaded here -->

        <div class="direct-chat-messages" id="chat">
          <!-- Message. Default to the left -->
          <div
            class="direct-chat-msg"
            v-for="(msg, index) in mensagens"
            :class="{'right': msg.sender === 'FRONT'}"
            :key="index"
          >
            <div class="direct-chat-infos clearfix">
              <span class="direct-chat-name float-left">{{msg.sender}}</span>
              <span class="direct-chat-timestamp float-right">{{msg.time | moment("HH:mm:ss")}}</span>
            </div>
            <!-- /.direct-chat-infos -->
            <img
              class="direct-chat-img"
              src="@/assets/img/user1-128x128.jpg"
              alt="Message User Image"
            />
            <!-- /.direct-chat-img -->
            <div class="direct-chat-text">
              <pre style="font-size: 10px">{{msg.mensagem}}</pre>
            </div>
            <!-- /.direct-chat-text -->
          </div>
          <!-- /.direct-chat-msg -->
        </div>
        <!--/.direct-chat-messages-->
      </div>
      <!-- /.card-body -->

      <!-- /.card-footer-->
    </div>
  </section>
  <!-- final chat -->
</template>

<script>
/*
<!-- Associando um valor dinamicamente a uma variável -->
<blog-post v-bind:title="post.title"></blog-post>
*/
//import axios from "axios";

export default {
  name: "debug",
  props: ["log"],
  data() {
    return {
      mensagens: []
    };
  },
  watch: {
    log: function(line) {
      this.mensagens.unshift(line); //FORMATA A DATA PARA EXIBIR NA VIEW
    }
  },
  methods: {},
  mounted() {}
};
</script>