<template>
	<Relatorio>
		<!-- right col (We are only adding the ID to make the widgets sortable)-->
		<section class="col-lg-12 connectedSortable">
			<div class="card">
				<div class="card-header">
					<h3>Métricas Avaliações</h3>
					<div class="card-tools">
						<!-- Maximize Button -->
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
					<!-- /.card-tools -->
				</div>

				<!-- /.card-header -->
				<div class="card-body">
					<div class="row">
						<div class="col">
							<!-- PERIODO DE -->
							<div class="form-group">
								<label for="periodo_de">De</label>
								<!-- v-mask="'##/##/####'" -->
								<input
									id="periodo_de"
									autocomplete="off"
									type="date"
									class="form-control"
									v-model="filtro.periodo_de"
								/>
							</div>
						</div>

						<div class="col">
							<!-- PERIODO ATE -->
							<div class="form-group">
								<label for="periodo_ate">Até</label>
								<!-- v-mask="'##/##/####'" -->
								<input
									id="periodo_ate"
									autocomplete="off"
									type="date"
									class="form-control"
									v-model="filtro.periodo_ate"
								/>
							</div>
						</div>

						<div class="col">
							<!-- CLIENTE -->
							<div class="form-group">
								<label for="cliente">Cliente</label>
								<v-select
									id="cliente"
									placeholder="Selecione o cliente"
									label="nome_fantasia"
									:options="clientes"
									:reduce="(clientes) => clientes.id"
									v-model="filtro.client_id"
									v-on:change="selClientes($event)"
								></v-select>
							</div>
						</div>
					</div>
					<div class="row">
						<!-- TIPO -->
						<div class="col">
							<div class="form-group">
								<label for="tipo">Tipo</label>
								<v-select
									taggable
									id="tipo"
									placeholder="Selecione o tipo"
									label="descricao"
									:options="tipos"
									:reduce="(tipos) => tipos.id"
									v-model="filtro.conteudo_tipo_id"
								></v-select>
							</div>
						</div>

						<div class="col">
							<!-- ATENDENTE -->
							<div class="form-group">
								<label for="atendente">Atendente</label>
								<v-select
									taggable
									id="atendente"
									placeholder="Selecione o Atendente"
									label="name"
									:options="usuarios"
									:reduce="(usuarios) => usuarios.id"
									v-model="filtro.atendente_id"
								></v-select>
							</div>
						</div>

						<div class="col">
							<!-- MODERADOR -->
							<div class="form-group">
								<label for="moderador">Moderador</label>
								<v-select
									taggable
									id="moderador"
									placeholder="Selecione o Moderador"
									label="name"
									:options="usuarios"
									:reduce="(usuarios) => usuarios.id"
									v-model="filtro.moderador_id"
								></v-select>
							</div>
						</div>

						<div class="col">
							<!-- EXECUTOR -->
							<div class="form-group">
								<label for="executor">Executor</label>
								<v-select
									taggable
									id="executor"
									placeholder="Selecione o executor"
									label="name"
									:options="usuarios"
									:reduce="(usuarios) => usuarios.id"
									v-model="filtro.executor_id"
								></v-select>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col">
							<div class="form-group">
								<button
									v-on:click="getMetricasAvaliacoes()"
									id="btnn"
									type="button"
									class="btn btn-info form-control"
								>
									PESQUISAR
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- <br /> -->
		<!-- <pre>FILTRO: {{filtro}}</pre> -->

		<!-- MÉDIA -->

		<section class="col-lg-12 connectedSortable" v-if="relatorio.medias">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<h3>Média</h3>
					<div class="card-tools">
						<!-- Maximize Button -->
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
					<!-- /.card-tools -->
				</div>

				<!-- /.card-header -->
				<div class="card-body">
					<div class="table-responsive">
						<table
							id
							class="table table-bordered table-striped dataTable"
							role="grid"
							aria-describedby="conteudos"
						>
							<thead>
								<tr role="row">
									<th rowspan="1" width="100px" colspan="1">
										Design
									</th>
									<th rowspan="1" colspan="1">Conteúdo</th>
									<th rowspan="1" colspan="1">Estratégia</th>
									<th rowspan="1" colspan="1">Atendimento</th>
									<th rowspan="1" colspan="1">Resultados</th>
									<th>NPS</th>
								</tr>
							</thead>

							<tbody>
								<tr role="row" class="odd">
									<td>{{ relatorio.medias.csat_design }}</td>
									<td>
										{{ relatorio.medias.csat_conteudo }}
									</td>
									<td>
										{{ relatorio.medias.csat_estrategia }}
									</td>
									<td>
										{{ relatorio.medias.csat_atendimento }}
									</td>
									<td>
										{{ relatorio.medias.csat_resultados }}
									</td>
									<td>{{ relatorio.medias.nps }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>

		<!-- AVALIACAO MENSAL -->

		<section class="col-lg-12 connectedSortable" v-if="relatorio.registros">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<h3>Avaliação Mensal</h3>
					<div class="card-tools">
						<!-- Maximize Button -->
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
					<!-- /.card-tools -->
				</div>

				<!-- /.card-header -->
				<div class="card-body">
					<div class="table-responsive mt-5">
						<table
							id="tblRelatorio"
							class="table table-bordered table-striped dataTable"
							role="grid"
							aria-describedby="conteudos"
						>
							<thead>
								<tr role="row">
									<th rowspan="1" colspan="1">Data</th>
									<th rowspan="1" width="180px" colspan="1">
										Item
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Cliente
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Usuário
									</th>
									<th rowspan="1" colspan="1">Atendimento</th>
									<th rowspan="1" colspan="1">Resultados</th>
									<th>NPS</th>
								</tr>
							</thead>

							<tbody>
								<tr
									role="row"
									class="odd"
									v-for="(registro,
									index) in relatorio.registros"
									:key="index"
								>
									<td class="sorting_1">
										<span class="d-none">{{
											registro.data
										}}</span>
										<!-- PARA ORDENAR O DTTABLE -->
										{{
											registro.data
												| moment("DD/MM HH:mm")
										}}
									</td>
									<td>{{ registro.item }}</td>
									<td>{{ registro.cliente }}</td>
									<td>{{ registro.usuario }}</td>
									<td>{{ registro.csat_atendimento }}</td>
									<td>{{ registro.csat_resultados }}</td>
									<td>{{ registro.nps }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>

		<!-- AVALIACAO ENTREGAS -->

		<section class="col-lg-12 connectedSortable" v-if="relatorio.conteudos">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<h3>Avaliação Entregas</h3>
					<div class="card-tools">
						<!-- Maximize Button -->
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
					<!-- /.card-tools -->
				</div>

				<!-- /.card-header -->
				<div class="card-body">
					<div class="table-responsive mt-5">
						<table
							id="tblRelatorio"
							class="table table-bordered table-striped dataTable"
							role="grid"
							aria-describedby="conteudos"
						>
							<thead>
								<tr role="row">
									<th rowspan="1" colspan="1">Data</th>
									<th rowspan="1" width="180px" colspan="1">
										Item
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Cliente
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Usuário
									</th>
									<th rowspan="1" width="100px" colspan="1">
										Design
									</th>
									<!-- <th rowspan="1" width="100px" colspan="1">Design Obs</th> -->
									<th rowspan="1" width="100px" colspan="1">
										Conteúdo
									</th>
									<!-- <th rowspan="1" width="100px" colspan="1">Conteúdo Obs</th> -->
									<th rowspan="1" colspan="1">Estratégia</th>
									<!-- <th rowspan="1" colspan="1">Estratégia Obs</th> -->
								</tr>
							</thead>

							<tbody>
								<tr
									role="row"
									class="odd"
									v-for="(registro,
									index) in relatorio.conteudos"
									:key="index"
								>
									<td class="sorting_1">
										<span class="d-none">{{
											registro.data
										}}</span>
										<!-- PARA ORDENAR O DTTABLE -->
										{{
											registro.data
												| moment("DD/MM HH:mm")
										}}
									</td>
									<td>{{ registro.item }}</td>
									<td>{{ registro.cliente }}</td>
									<td>{{ registro.usuario }}</td>
									<td>{{ registro.csat_design }}</td>
									<!-- <td>{{registro.design_observacao}}</td> -->
									<td>{{ registro.csat_conteudo }}</td>
									<!-- <td>{{registro.conteudo_observacao}}</td> -->
									<td>{{ registro.csat_estrategia }}</td>
									<!-- <td>{{registro.estrategia_observacao}}</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>
		<!-- right col -->
	</Relatorio>
	<!-- ./wrapper -->
</template>
<style>
.cursor-pointer {
	cursor: pointer;
}
.note-float-left,
note-float-right {
	padding: 4px;
}
</style>
<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import Relatorio from "../../components/Relatorio.vue";

export default {
	name: "relatorio-metricas-avaliacoes",
	data() {
		return {
			titulo: "Carregando lista...",
			usuarios_url: this.$apiUrl + "/users",
			clientes_url: this.$apiUrl + "/clients",
			avaliacao_mensal_url: this.$apiUrl + "/metrica_avaliacoes",
			tipos_url: this.$apiUrl + "/conteudo_tipo",
			login: {},
			loginObj: {},
			filtro: {
				periodo_ate: this.$moment().format("YYYY-MM-DD"),
				periodo_de: this.$moment().format("YYYY-MM-01"),
			},
			relatorio: [],
			clientes: [],
			usuarios: [],
			tipos: [],
			isAdmin: false,
			table: false,
			loading: false,
		};
	},
	methods: {
		getMetricasAvaliacoes() {
			this.loading = true;
			axios
				.post(this.avaliacao_mensal_url, this.filtro, this.login)
				.then((response) => {
					this.relatorio = response.data.message;
					this.dataTable();
					this.loading = false;
				})
				.catch((error) => {
					this.showalert("Atenção", error, "error");
				});
		},
		getUsuarios() {
			axios
				.get(this.usuarios_url, this.login)
				.then((response) => {
					this.usuarios = response.data;
				})
				.catch((error) => {
					this.titulo = error;
				});
		},
		getClientes() {
			axios
				.get(this.clientes_url + "?include_deleted=1", this.login)
				.then((response) => {
					//console.log(response.data);

					this.clientes = response.data;
				})
				.catch((error) => {
					this.titulo = error;
				});

			this.editar = false;
		},
		getTipos() {
			axios
				.get(this.tipos_url, this.login)
				.then((response) => {
					this.tipos = response.data;
				})
				.catch((error) => {
					console.error("Tipos: " + error);
				});
		},
		deleteregistro(registro) {
			var deleteurl = this.avaliacao_mensal_url + "/" + registro.id;

			axios.delete(deleteurl, this.login).then((response) => {
				var _t = this;

				_t.retorno = response.data;

				if (_t.retorno.status == 201) {
					_t.getMetricasAvaliacoes();

					_t.showalert(
						"Cadastro Excluído",
						_t.retorno.message,
						"success",
					);
				} else {
					const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

					for (var i = 0; i < msg.length; i++) {
						var msgerro = msg[i][1];

						_t.showalert("Atenção", msgerro, "error");
					}
				}
			});
		},
		showConfirm(registro) {
			this.$fire({
				title: "Excluir",
				text:
					"Você tem certeza que deseja excluir #" + registro.id + "?",
				type: "question",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Sim, excluir!",
				cancelButtonColor: "#d33",
				cancelButtonText: "Não",
			}).then((result) => {
				if (result.value) {
					this.deleteregistro(registro);
				}
			});
		},
		dataTable() {
			var _e = this;

			if (_e.table != false) {
				_e.table.destroy();

				setTimeout(() => {
					_e.renderTable();
				}, 100);
			} else {
				setTimeout(() => {
					_e.renderTable();
				}, 100);
			}
		},
		renderTable() {
			this.table = $("#tblRelatorio").DataTable({
				paging: true,
				bInfo: true,
				bLengthChange: false,
				searching: true,
				order: [[0, "desc"]],
				language: {
					sEmptyTable: "Pesquisando registros...",
					sInfo:
						"Mostrando de _START_ até _END_ de _TOTAL_ registros",
					sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
					sInfoFiltered: "(Filtrados de _MAX_ registros)",
					sInfoPostFix: "",
					sInfoThousands: ".",
					sLengthMenu: "_MENU_ resultados por página",
					sLoadingRecords: "Carregando...",
					sProcessing: "Processando...",
					sZeroRecords: "Nenhum registro encontrado",
					sSearch: "Pesquisar",
					oPaginate: {
						sNext: "Próximo",
						sPrevious: "Anterior",
						sFirst: "Primeiro",
						sLast: "Último",
					},
					oAria: {
						sSortAscending: ": Ordenar colunas de forma ascendente",
						sSortDescending:
							": Ordenar colunas de forma descendente",
					},
					select: {
						rows: {
							_: "Selecionado %d linhas",
							"0": "Nenhuma linha selecionada",
							"1": "Selecionado 1 linha",
						},
					},
				},
			});
		},
	},
	mounted() {
		this.contador_login = localStorage.getItem("contador");

		if (localStorage.getItem("login")) {
			try {
				const loginJSON = JSON.parse(localStorage.getItem("login"));

				this.loginObj = loginJSON;

				this.isAdmin = loginJSON.isAdmin;

				this.login = {
					headers: {
						Authorization: "Bearer ".concat(loginJSON.api_token),
						Accept: "application/json",
						"Cache-Control": "no-cache",
					},
				};

				setTimeout(() => {
					this.getMetricasAvaliacoes();
					this.getClientes();
					this.getUsuarios();
					this.getTipos();
				}, 300);
			} catch (e) {
				localStorage.removeItem("login");
				this.$router.push("/login");
			}
		} else {
			localStorage.removeItem("login");
			this.$router.push("/login");
		}
	},
	destroyed() {
		localStorage.contador = 0;
	},
	components: { Relatorio },
};
</script>
