<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#">
          <i class="fas fa-bars"></i>
        </a>
      </li>
    </ul>

    <!-- SEARCH FORM -->
    <!-- <form class="form-inline ml-3">
      <div class="input-group input-group-sm">
        <input
          class="form-control form-control-navbar"
          type="search"
          placeholder="Pesquisar"
          aria-label="Pesquisar"
        />
        <div class="input-group-append">
          <button class="btn btn-navbar" type="submit">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </form> -->

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Messages Dropdown Menu -->

      <li class="nav-item">
        <a class="nav-link" href="#">
          <i class="far fa-user mr-1"></i>
          <span class>{{loginstr.name}}</span>
        </a>
      </li>
      <!-- Notifications Dropdown Menu -->
    </ul>
  </nav>
  <!-- /.navbar -->
</template>


<script>
export default {
  name: "navbar",
  data() {
    return {
      loginstr: {}
    };
  },
  mounted() {
    if (localStorage.getItem("login")) {
      try {
        this.loginstr = JSON.parse(localStorage.getItem("login"));
        //console.log(this.login);
      } catch (e) {
        this.loginstr = "";
      }
    } else {
      this.loginstr = "";
    }
  }
};
</script>