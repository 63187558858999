<template>
	<Relatorio>
		<!-- right col (We are only adding the ID to make the widgets sortable)-->
		<section class="col-lg-12">
			<div class="card">
				<div class="card-header">
					<h3>Relatório - Produções Parceiros</h3>
					<!-- /.card-tools -->
				</div>

				<!-- /.card-header -->
				<div class="card-body">
					<div class="row">
						<div class="col">
							<!-- PERIODO DE -->
							<div class="form-group">
								<label for="periodo_de">De</label>
								<!-- v-mask="'##/##/####'" -->
								<input
									id="periodo_de"
									autocomplete="off"
									type="date"
									class="form-control"
									v-model="filtro.periodo_de"
								/>
							</div>
						</div>

						<div class="col">
							<!-- PERIODO ATE -->
							<div class="form-group">
								<label for="periodo_ate">Até</label>
								<!-- v-mask="'##/##/####'" -->
								<input
									id="periodo_ate"
									autocomplete="off"
									type="date"
									class="form-control"
									v-model="filtro.periodo_ate"
								/>
							</div>
						</div>

						<div class="col">
							<!-- CLIENTE -->
							<div cass="form-group">
								<label for="cliente">Cliente</label>
								<v-select
									id="cliente"
									placeholder="Selecione o Cliente"
									label="nome_fantasia"
									:options="clientes"
									:reduce="(clientes) => clientes.id"
									v-model="filtro.client_id"
									v-on:change="selClientes($event)"
									multiple
								></v-select>
							</div>
						</div>

						<div class="col">
							<!-- MODERADOR -->
							<div class="form-group">
								<label for="executor">Responsável</label>
								<v-select
									taggable
									id="executor"
									placeholder="Selecione o Responsável"
									label="name"
									:options="usuarios"
									:reduce="(usuarios) => usuarios.id"
									v-model="filtro.executor_id"
									multiple
								></v-select>
							</div>
						</div>

						<div
							class="col"
							style="align-self: center; transform: translate(0%, 20%);"
						>
							<button
								v-on:click="getMetricasProducoes()"
								id="btnn"
								type="button"
								class="btn btn-info form-control"
							>
								PESQUISAR
							</button>
						</div>
					</div>
					<!-- row -->
				</div>
				<!-- card body -->
			</div>
			<!-- card -->
		</section>

		<!-- Overall result section -->
		<section v-if="producoes" class="col-lg-12">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<div class="row">
						<div class="col xs12 md4">
							<h3>Indicadores Essenciais</h3>
						</div>
						<!-- card-tools -->
						<div class="card-tools">
							<button
								type="button"
								class="btn btn-tool"
								data-card-widget="collapse"
							>
								<i class="fas fa-minus"></i>
							</button>
						</div>
					</div>
					<!-- /.card-tools -->
				</div>

				<div class="card-body">
					<div class="d-flex flex-row justify-content-around">
						<div class="card col-2">
							<div
								class="card-header d-flex flex-column align-items-center"
							>
								<h3>{{ percentual_no_prazo || 0 }}%</h3>
								<p class="text-center">
									das entregas no prazo
								</p>
							</div>
						</div>
						<div class="card col-2">
							<div
								class="card-header d-flex flex-column align-items-center"
							>
								<h3>{{ csat_medio || 0 }}</h3>
								<p class="text-center">
									CSAT médio no período
								</p>
							</div>
						</div>
						<div class="card col-2">
							<div
								class="card-header d-flex flex-column align-items-center"
							>
								<h3>{{ percentual_aprovados || 0 }}%</h3>
								<p class="text-center">
									das entregas aprovadas
								</p>
							</div>
						</div>
						<div class="card col-2">
							<div
								class="card-header d-flex flex-column align-items-center"
							>
								<h3>
									{{ percentual_aprovados_ajustes || 0 }}%
								</h3>
								<p class="text-center">
									das entregas aprov. com ajustes
								</p>
							</div>
						</div>
						<div class="card col-2">
							<div
								class="card-header d-flex flex-column align-items-center"
							>
								<h3>{{ percentual_reprovados || 0 }}%</h3>
								<p class="text-center">
									das entregas reprovados
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Entregas Periodo Section -->
		<section v-if="producoes" class="col-lg-12">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<div class="row">
						<div class="col xs12 md4">
							<h3>Entregas no período</h3>
						</div>
						<div class="card-tools">
							<button
								type="button"
								class="btn btn-tool"
								data-card-widget="collapse"
							>
								<i class="fas fa-minus"></i>
							</button>
						</div>
					</div>
				</div>

				<div class="card-body">
					<!-- <div class="table-responsive"> This uses responsive search -->
					<div class="table-responsive">
						<table
							id="tblRelatorioEntregas"
							class="table table-bordered table-striped dataTable"
							role="grid"
							aria-describedby="conteudos"
						>
							<thead>
								<tr role="row">
									<th rowspan="1" colspan="1">
										Tipo
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Ciclo
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Mês de execução
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Quantidade
									</th>
								</tr>
							</thead>

							<tbody>
								<tr
									role="row"
									class="odd"
									v-for="(entrega, index) in entregas"
									:key="index"
								>
									<td>{{ entrega.tipo }}</td>
									<td>{{ entrega.ciclo }}</td>
									<td>
										{{ entrega.execucao }}
									</td>
									<td>
										{{ entrega.quantidade }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>

		<!-- Detalhamento Section -->
		<section class="col-lg-12 connectedSortable">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<div class="row">
						<div class="col xs12 md4">
							<h3>Detalhamento</h3>
						</div>
						<div class="card-tools">
							<button
								type="button"
								class="btn btn-tool"
								data-card-widget="maximize"
							>
								<i class="fas fa-expand"></i>
							</button>
						</div>
					</div>
				</div>

				<div class="card-body">
					<!-- <div class="table-responsive"> This uses responsive search -->
					<div class="table-responsive">
						<table
							id="tblRelatorioExecucoes"
							class="table table-bordered table-striped dataTable"
							role="grid"
							aria-describedby="conteudos"
						>
							<thead>
								<tr role="row">
									<th rowspan="1" colspan="1">
										Executor
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Mês de execução
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Conteúdo
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Cliente
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Tipo
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Função
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Prazo
									</th>
									<th rowspan="1" width="180px" colspan="1">
										CSAT
									</th>
									<th rowspan="1" width="180px" colspan="1">
										Aprovação
									</th>
								</tr>
							</thead>

							<tbody>
								<tr
									role="row"
									class="odd"
									v-for="(conteudo, index) in producoes"
									:key="index"
								>
									<td>
										{{
											conteudo.conteudo_executor.executor
												.name
										}}
									</td>
									<td>
										{{
											conteudo.updated_at | moment("MMMM")
										}}
									</td>
									<td>
										{{ conteudo.conteudo.titulo }}
									</td>
									<td>
										{{
											conteudo.conteudo.cliente
												.nome_fantasia
										}}
									</td>
									<td>
										{{ conteudo.conteudo.tipo.descricao }}
									</td>
									<td>
										{{ conteudo.funcao }}
									</td>
									<td>
										{{
											conteudo.atraso ||
												"Prazo não definido"
										}}
									</td>
									<td>
										{{ conteudo.csat || "Não avaliado" }}
									</td>
									<td>
										{{
											aprovacao(conteudo.nivel_reprovacao)
										}}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>
	</Relatorio>
</template>
<style>
.cursor-pointer {
	cursor: pointer;
}
.note-float-left,
note-float-right {
	padding: 4px;
}
</style>
<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import Relatorio from "../../components/Relatorio.vue";

export default {
	name: "relatorio-parceiros",

	data() {
		return {
			titulo: "Carregando lista...",
			usuarios_url: this.$apiUrl + "/users",
			clientes_url: this.$apiUrl + "/clients",
			avaliacao_mensal_url: this.$apiUrl + "/metrica_parceiros",
			login: {},
			loginObj: {},
			filtro: {
				periodo_ate: this.$moment().format("YYYY-MM-DD"),
				periodo_de: this.$moment().format("YYYY-MM-01"),
			},
			clientes: [],
			usuarios: [],
			isAdmin: false,
			table: false,
			loading: false,
			producoes: null,
		};
	},
	methods: {
		getMetricasProducoes() {
			this.loading = true;
			axios
				.get(this.avaliacao_mensal_url, { params: this.filtro })
				.then((response) => {
					this.producoes = response.data;
					this.dataTable();
					this.loading = false;
				})
				.catch((error) => {
					this.showalert("Atenção", error, "error");
				});
		},
		getUsuarios() {
			axios
				.get(this.usuarios_url, this.login)
				.then((response) => {
					this.usuarios = response.data;
				})
				.catch((error) => {
					this.titulo = error;
				});
		},
		getClientes() {
			axios
				.get(this.clientes_url + "?include_deleted=1", this.login)
				.then((response) => {
					//console.log(response.data);

					this.clientes = response.data;
				})
				.catch((error) => {
					this.titulo = error;
				});

			this.editar = false;
		},

		dataTable() {
			var _e = this;

			if (_e.table != false) {
				_e.table.destroy();

				setTimeout(() => {
					_e.renderTable();
				}, 100);
			} else {
				setTimeout(() => {
					_e.renderTable();
				}, 100);
			}
		},
		renderTable() {
			this.table = $("#tblRelatorioExecucoes").DataTable({
				paging: true,
				bInfo: true,
				bLengthChange: true,
				searching: true,
				order: [[0, "desc"]],
				pageLength: 50,
				language: {
					sEmptyTable: "Pesquisando registros...",
					sInfo:
						"Mostrando de _START_ até _END_ de _TOTAL_ registros",
					sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
					sInfoFiltered: "(Filtrados de _MAX_ registros)",
					sInfoPostFix: "",
					sInfoThousands: ".",
					sLengthMenu: "_MENU_ resultados por página",
					sLoadingRecords: "Carregando...",
					sProcessing: "Processando...",
					sZeroRecords: "Nenhum registro encontrado",
					sSearch: "Pesquisar",
					oPaginate: {
						sNext: "Próximo",
						sPrevious: "Anterior",
						sFirst: "Primeiro",
						sLast: "Último",
					},
					oAria: {
						sSortAscending: ": Ordenar colunas de forma ascendente",
						sSortDescending:
							": Ordenar colunas de forma descendente",
					},
					select: {
						rows: {
							_: "Selecionado %d linhas",
							"0": "Nenhuma linha selecionada",
							"1": "Selecionado 1 linha",
						},
					},
				},
			});
		},
		aprovacao(nivel_reprovacao) {
			if (nivel_reprovacao === null) return "Aprovação pendente";
			if (nivel_reprovacao == 0) return "Aprovação direta";
			if (nivel_reprovacao == 1) return "Aprov. c/ ajustes";
			if (nivel_reprovacao == 2) return "Reprovado";
			if (nivel_reprovacao == 3) return "Reprov. e aprov. c/ ajustes";
		},
	},
	computed: {
		percentual_no_prazo: function() {
			let qtd_no_prazo = 0;
			let qtd_total = 0;

			for (let i in this.producoes) {
				if (this.producoes[i].atraso != "Prazo indefinido") qtd_total++;

				if (this.producoes[i].atraso == "No prazo") qtd_no_prazo++;
			}

			return parseInt((qtd_no_prazo * 100) / qtd_total);
		},
		csat_medio: function() {
			let qtd_total = 0;
			let csat_total = 0;

			for (let i in this.producoes) {
				if (this.producoes[i].csat == null) continue;

				qtd_total++;
				csat_total += this.producoes[i].csat;
			}

			return parseInt(csat_total / qtd_total);
		},
		percentual_aprovados: function() {
			let qtd_total = 0;
			let qtd_aprovados = 0;

			for (let i in this.producoes) {
				if (this.producoes[i].nivel_reprovacao == null) continue;

				qtd_total++;
				if (this.producoes[i].nivel_reprovacao == 0) qtd_aprovados++;
			}

			return parseInt((qtd_aprovados * 100) / qtd_total);
		},
		percentual_aprovados_ajustes: function() {
			let qtd_total = 0;
			let qtd_aprovados_ajustes = 0;

			for (let i in this.producoes) {
				if (this.producoes[i].nivel_reprovacao == null) continue;

				qtd_total++;
				if (this.producoes[i].nivel_reprovacao == 1)
					qtd_aprovados_ajustes++;
			}

			return parseInt((qtd_aprovados_ajustes * 100) / qtd_total);
		},
		percentual_reprovados: function() {
			let qtd_total = 0;
			let qtd_reprovados = 0;

			for (let i in this.producoes) {
				if (this.producoes[i].nivel_reprovacao == null) continue;

				qtd_total++;
				if (
					this.producoes[i].nivel_reprovacao == 2 ||
					this.producoes[i].nivel_reprovacao == 3
				)
					qtd_reprovados++;
			}

			return parseInt((qtd_reprovados * 100) / qtd_total);
		},
		entregas: function() {
			let entregas = {};
			1;
			for (let i in this.producoes) {
				if (
					!entregas.hasOwnProperty(
						this.producoes[i].conteudo.tipo.descricao +
							this.$moment(
								this.producoes[i].conteudo.ciclo,
							).format("MMMM") +
							this.$moment(this.producoes[i].updated_at).format(
								"MMMM",
							),
					)
				) {
					entregas[
						this.producoes[i].conteudo.tipo.descricao +
							this.$moment(
								this.producoes[i].conteudo.ciclo,
							).format("MMMM") +
							this.$moment(this.producoes[i].updated_at).format(
								"MMMM",
							)
					] = {
						tipo: this.producoes[i].conteudo.tipo.descricao,
						ciclo: this.$moment(
							this.producoes[i].conteudo.ciclo,
						).format("MMMM"),
						execucao: this.$moment(
							this.producoes[i].updated_at,
						).format("MMMM"),
						quantidade: 0,
					};
				}

				entregas[
					this.producoes[i].conteudo.tipo.descricao +
						this.$moment(this.producoes[i].conteudo.ciclo).format(
							"MMMM",
						) +
						this.$moment(this.producoes[i].updated_at).format(
							"MMMM",
						)
				].quantidade += 1;
			}

			return entregas;
		},
	},
	mounted() {
		this.contador_login = localStorage.getItem("contador");

		if (localStorage.getItem("login")) {
			try {
				const loginJSON = JSON.parse(localStorage.getItem("login"));

				this.loginObj = loginJSON;

				this.isAdmin = loginJSON.isAdmin;

				this.login = {
					headers: {
						Authorization: "Bearer ".concat(loginJSON.api_token),
						Accept: "application/json",
						"Cache-Control": "no-cache",
					},
				};

				setTimeout(() => {
					this.getMetricasProducoes();
					this.getClientes();
					this.getUsuarios();
				}, 300);
			} catch (e) {
				localStorage.removeItem("login");
				this.$router.push("/login");
			}
		} else {
			localStorage.removeItem("login");
			this.$router.push("/login");
		}
	},
	destroyed() {
		localStorage.contador = 0;
	},
	components: { Relatorio },
};
</script>
