<template>
    <div v-if="history" style="display: contents">
        <button 
            v-if="!isOpen" 
            @click="toggle" 
            type="button" 
            class="btn btn-light btn-block">
            <i class="fas fa-angle-down"></i>
        </button>
        <template v-else>
            <ul class="list-group">
                <template
                    v-for="entry in history">
                    <li :key="entry.id" v-if="entry.action != 'created'" class="list-group-item">
                        Dia {{entry.date | moment("DD/MM/YY, HH:mm")}} - {{entry.name || getPlaceholder(entry.action) }} {{ getMessage(entry.action) }}
                    </li>
                    <li :key="entry.id" v-else class="list-group-item">
                        Dia {{entry.date | moment("DD/MM/YY, HH:mm")}} - {{getMessage(entry.action)}}
                    </li>
                </template>
            </ul>
            <button 
                @click="toggle" 
                type="button" 
                class="btn btn-light btn-block">
                <i class="fas fa-angle-up"></i>
            </button>
        </template>
    </div>
</template>

<script>
import axios from "axios";

axios.defaults.withCredentials = true;

export default {
    props: {
        conteudo_id: Number
    },
    data() {
        return {
            isOpen: false,
            history: null
        }
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
        getPlaceholder(action) {
            if(action.startsWith('client')) return "O cliente";
            if(action.startsWith('moderator')) return "O moderador";

            return "O executor";
        },
        getMessage(action) {
            switch (action) {
                case "advanced":
                    return 'avançou a tarefa';

                case "created":
                    return 'Tarefa criada';

                case "started_production":
                    return 'iniciou a produção da tarefa';

                case "client_approved":
                    return 'aprovou a tarefa';

                case "client_reproved_1":
                    return 'aprovou a tarefa com ajustes';

                case "client_reproved_2":
                    return 'reprovou a tarefa';

                case "client_reproved_3":
                    return 'aprovou a tarefa após reprovação e ajustes';

                case "moderator_approved":
                    return 'aprovou a tarefa na revisão';

                case "moderator_reproved":
                    return 'reprovou a tarefa na revisão';

                case "adjusted":
                    return 'ajustou a tarefa';

                case "published":
                    return 'publicou a tarefa';

                case "scheduled":
                    return 'agendou a tarefa';

                case "stuck":
                    return 'marcou como impedido';

                case "unstuck":
                    return 'liberou a execução';
            }
            
            return 'avançou a tarefa';
        }
    },
    mounted() {
        axios
        .get(this.$apiUrl + '/conteudos/' + this.conteudo_id + "/historico?v=" + Math.random())
        .then(response => {
            this.history = response.data;
        })
        .catch(response => {
            console.log('Error: ' + response);
        });


    }
}
</script>

<style>

</style>