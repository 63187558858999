<template>
  <div class="wrapper">
    <navigator></navigator>

    <sidebar></sidebar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <!-- HEADER -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Small boxes (Stat box) -->

          <!-- Main row -->
          <div class="row">
            <section class="col-lg-5 connectedSortable">
              <!-- card editar -->
              <div class="card card-info" v-if="editar">
                <div class="card-header">
                  <h3 class="card-title">Editar Usuário</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form role="form" @submit.prevent="updateUsuario(usuario)">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="name">Nome</label>
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Nome"
                        v-model="usuario.name"
                      />
                    </div>
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Email"
                        v-model="usuario.email"
                      />
                    </div>

                    <div class="form-group">
                      <label for="endereco">Cliente:</label>

                      <select id="moderador_id" v-model="usuario.client_id" class="form-control">
                        <option
                          v-for="cliente in clientes"
                          v-bind:value="cliente.id"
                          v-bind:key="cliente.id"
                        >{{cliente.nome_fantasia}}</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="password">Senha</label>
                      <input
                        type="password"
                        class="form-control"
                        id="password"
												autocomplete="off"
                        placeholder="Senha"
                        v-model="usuario.password"
                      />
                    </div>
                    <div class="form-group">
                      <label for="passwordc">Confirmar Senha</label>
                      <input
                        type="password"
                        class="form-control"
                        id="passwordc"
												autocomplete="off"
                        placeholder="Senha"
                        v-model="usuario.password_confirmation"
                      />
                    </div>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="isAdmin"
                        v-model="usuario.isAdmin"
                      />
                      <label class="form-check-label" for="isAdmin">Admin</label>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button class="btn btn-info">Atualizar</button>
                    <button class="btn btn-secondary ml-2" v-on:click="updateCancel()">Cancelar</button>
                  </div>
                </form>
              </div>
              <!-- /.card -->

              <!-- card adicionar -->
              <div class="card card-info" v-else>
                <div class="card-header">
                  <h3 class="card-title">Adicionar Usuário</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form role="form" @submit.prevent="postUsuario(usuario)">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="name">Nome</label>
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Nome"
                        v-model="usuario.name"
                      />
                    </div>
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="Email"
                        v-model="usuario.email"
                      />
                    </div>

                    <div class="form-group">
                      <label for="client_id">Cliente:</label>

                      <select id="client_id" v-model="usuario.client_id" class="form-control">
                        <option
                          v-for="cliente in clientes"
                          v-bind:value="cliente.id"
                          v-bind:key="cliente.id"
                        >{{cliente.nome_fantasia}}</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="password">Senha</label>
                      <input
                        type="password"
                        class="form-control"
												autocomplete="off"
                        id="password"
                        placeholder="Senha"
                        v-model="usuario.password"
                      />
                    </div>
                    <div class="form-group">
                      <label for="passwordc">Confirmar Senha</label>
                      <input
                        type="password"
												autocomplete="off"
                        class="form-control"
                        id="passwordc"
                        placeholder="Senha"
                        v-model="usuario.password_confirmation"
                      />
                    </div>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="isAdmin"
                        v-model="usuario.isAdmin"
                      />
                      <label class="form-check-label" for="isAdmin">Admin</label>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button class="btn btn-info">Salvar</button>
                  </div>
                </form>
              </div>
              <!-- /.card -->

              <!-- card debug -->
              <div class="card" v-if="$debugWindow">
                <div class="card-header">
                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                      <i class="fas fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <pre>POST:{{ usuario }}</pre>
                  <pre>RESPONSE:{{ retorno }}</pre>
                </div>
              </div>
              <!-- /.card debug -->
            </section>

            <section class="col-lg-7 connectedSortable">
              <div class="overlay" v-if="loading">
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              <div class="card card-info">
                <div class="card-header">
                  <!--       <h3 class="card-title">Usuários</h3>
                  &nbsp;-->
                  <h3 class="card-title">{{ titulo }}</h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table
                    id="tblUsuarios"
                    class="table table-bordered table-striped dataTable"
                    role="grid"
                    aria-describedby="example1_info"
                  >
                    <thead>
                      <tr role="row">
                        <th rowspan="1" colspan="1">ID</th>
                        <th rowspan="1" colspan="1">Tipo</th>
                        <th rowspan="1" colspan="1">Nome</th>
                        <th rowspan="1" colspan="1">Email</th>
                        <th rowspan="1" colspan="1">Update</th>
                        <th rowspan="1" colspan="1">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr role="row" class="odd" v-for="usuario in usuarios" :key="usuario.id">
                        <td class="sorting_1">{{ usuario.id }}</td>
                        <td>
                          <span
                            class="badge text-light badge-danger m-1"
                            v-if="usuario.isAdmin"
                          >admin</span>
                          <span
                            class="badge text-light badge-info m-1"
                            v-if="usuario.client_id"
                          >cliente</span>
                        </td>
                        <td class>
                          {{ usuario.name }} <span v-if="usuario.deleted_at" class="text-muted">[INATIVO]</span>
                          <br />
                        </td>
                        <td class>{{ usuario.email }}</td>
                        <td class>
                          <span class="d-none">{{ usuario.updated_at}}</span>
                          {{ usuario.updated_at | moment("D/MM HH:mm") }}
                        </td>
                        <td class>
                          <button
                            v-if="!usuario.deleted_at"
                            type="button"
                            class="btn btn-block btn-outline-danger btn-xs"
                            v-on:click.stop="showSubstituteChoice(usuario)"
                          >excluir</button>
                          <button
                            v-else
                            type="button"
                            class="btn btn-block btn-outline-danger btn-xs"
                            disabled
                          >excluir</button>
                          <button
                            type="button"
                            class="btn btn-block btn-outline-primary btn-xs"
                            v-on:click="updateSelUsuario(usuario)"
                          >editar</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- /.card -->
            </section>
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>

    <!-- /.content-wrapper -->
    <footer-comp></footer-comp>
  </div>
  <!-- ./wrapper -->
</template>

<style>
.dataTables_filter input {
  border: 1px solid #ccc;
  margin-bottom: 10px !important;
}
table.dataTable {
  border-collapse: collapse;
  margin-bottom: 10px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #efefef !important;
}
</style>

<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";

export default {
  name: "usuarios",
  data() {
    return {
      usuarios_url: this.$apiUrl + "/users",
      clientes_url: this.$apiUrl + "/clients",
      titulo: "Carregando lista...",
      retorno: {},
      idAdmin: 0,
      usuarios: [],
      clientes: [],
      usuario: {},
      table: false,
      editar: false,
      login: {},
      loading: false,
      substituto: null,
    };
  },
  methods: {
    getUsuarios() {
      this.loading = true;
      axios
        .get(this.usuarios_url, this.login)
        .then(response => {
          this.getClientes();

          this.usuarios = response.data;
          this.titulo = "Usuários";
          this.dataTable();
          this.usuario = {};
          this.loading = false;
        })
        .catch(error => {
          this.titulo = error;
        });

      this.editar = false;
    },
    postUsuario(usuario) {
      //usuario.id = Date.now();
      this.loading = true;

      axios
        .post(
          this.usuarios_url,
          {
            name: usuario.name,
            email: usuario.email,
            isAdmin: usuario.isAdmin,
            client_id: usuario.client_id,
            password: usuario.password,
            password_confirmation: usuario.password_confirmation
          },
          this.login
        )
        .then(response => {
          var _t = this;

          _t.retorno = response.data;

          this.loading = false;

          //ALERT
          if (_t.retorno.status == 201) {
            _t.getUsuarios();

            _t.showalert("Sucesso", _t.retorno.message, "success");
          } else {
            const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

            //console.log(msg.length);

            var msgerro = "";

            if (msg.length > 1) {
              for (var i = 0; i < msg.length; i++) {
                msgerro = msg[i][1];

                _t.showalert("Atenção", msgerro, "error");
              }
            } else {
              msgerro = msg;

              _t.showalert("Atenção", msgerro, "error");
            }
          }
          //FINAL ALERT
        });
    },
    updateUsuario(usuario) {
      if (usuario.isAdmin === 0) {
        usuario.isAdmin = false;
      }

      var updateurl = this.usuarios_url + "/" + usuario.id;

      axios
        .patch(
          updateurl,
          {
            id: usuario.id,
            name: usuario.name,
            email: usuario.email,
            isAdmin: usuario.isAdmin,
            client_id: usuario.client_id,
            password: usuario.password,
            password_confirmation: usuario.password_confirmation
          },
          this.login
        )
        .then(response => {
          var _t = this;

          _t.retorno = response.data;

          if (_t.retorno.status == 200) {
            _t.getUsuarios();

            _t.showalert("Cadastro Atualizado", _t.retorno.message, "success");
          } else {
            const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

            //console.log(msg);

            var msgerro = "";

            if (msg.length > 1) {
              for (var i = 0; i < msg.length; i++) {
                msgerro = msg[i][1];

                _t.showalert("Atenção", msgerro, "error");
              }
            } else {
              msgerro = msg;

              _t.showalert("Atenção", msgerro, "error");
            }
          }
        });
    },
    updateSelUsuario(usuario) {
      this.editar = true;
      this.usuario = usuario;
    },
    updateCancel() {
      this.editar = false;
      this.usuario = {};
    },
    deleteUsuario(usuario, substituto = null) {
      var deleteurl = this.usuarios_url + "/" + usuario.id;

      if(substituto) {  

        //Deletamos e enviamos um substituto para os conteúdos em execução
        axios.delete(deleteurl + '/substituto/' + substituto,  this.login).then(response => {
          var _t = this;
  
          _t.retorno = response.data;
  
          if (_t.retorno.status == 200) {
            _t.getUsuarios();
  
            _t.showalert("Cadastro Excluído", _t.retorno.message, "success");
          } else {
            const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'
  
            for (var i = 0; i < msg.length; i++) {
              var msgerro = msg[i][1];
  
              _t.showalert("Atenção", msgerro, "error");
            }
          }
        });
      } else {  
        axios.delete(deleteurl, this.login).then(response => {
          var _t = this;
  
          _t.retorno = response.data;
  
          if (_t.retorno.status == 200) {
            _t.getUsuarios();
  
            _t.showalert("Cadastro Excluído", _t.retorno.message, "success");
          } else {
            const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'
  
            for (var i = 0; i < msg.length; i++) {
              var msgerro = msg[i][1];
  
              _t.showalert("Atenção", msgerro, "error");
            }
          }
        });
      }
    },
    getClientes() {
      axios
        .get(this.clientes_url, this.login)
        .then(response => {
          this.clientes = response.data;
          this.clientes.push({
            id: null,
            nome_fantasia: "Usuário não é cliente",
            telefone: "",
            endereco: "",
            acao_ao_estourar_prazo: "",
            limite_solicitacao_extra: 0,
            created_at: "2019-11-11 15:00:59",
            updated_at: "2019-11-11 15:00:59",
            moderador_id: 0,
            atendente_id: 0
          });
        })
        .catch(error => {
         console.error(error);
        });

      this.editar = false;
    },
    showConfirm(usuario, substituto = null) {
      this.$fire({
        title: "Excluir",
        text: "Você tem certeza que deseja excluir " + usuario.name + "?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não"
      }).then(result => {
        if (result.value) {
          if(substituto) this.deleteUsuario(usuario, substituto);
          else this.deleteUsuario(usuario);
        }
      });
    },
    showSubstituteChoice(usuario) {
      this.$fire({
        title: "Excluir",
        text: "Você deseja transferir os conteúdos não finalizados e funções deste usuário para outro?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Sim, transferir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não"
      }).then(result => {
        if (result.value) {
          this.selectSubstitute(usuario);
        } else {
          this.showConfirm(usuario);
        }
      });
    },
    selectSubstitute(usuario) {
      this.$prompt("Quem deverá substituir esse executor? (Digite o ID)", {
        title: "Excluir",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Selecionar",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar"
      }).then(text => {
        if (text) {
          this.showConfirm(usuario, text);
        } else {
          this.selectSubstitute(usuario);
        }
      });
    },
    dataTable() {
      var _e = this;

      if (_e.table != false) {
        _e.table.destroy();

        setTimeout(function() {
          _e.renderTable();
        }, 300);
      } else {
        setTimeout(function() {
          _e.renderTable();
        }, 300);
      }
    },
    renderTable() {
      this.table = $("#tblUsuarios").DataTable({
        paging: true,
        bInfo: true,
        bLengthChange: false,
        searching: true,
        language: {
          sEmptyTable: "Nenhum registro encontrado",
          sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
          sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
          sInfoFiltered: "(Filtrados de _MAX_ registros)",
          sInfoPostFix: "",
          sInfoThousands: ".",
          sLengthMenu: "_MENU_ resultados por página",
          sLoadingRecords: "Carregando...",
          sProcessing: "Processando...",
          sZeroRecords: "Nenhum registro encontrado",
          sSearch: "Pesquisar",
          oPaginate: {
            sNext: "Próximo",
            sPrevious: "Anterior",
            sFirst: "Primeiro",
            sLast: "Último"
          },
          oAria: {
            sSortAscending: ": Ordenar colunas de forma ascendente",
            sSortDescending: ": Ordenar colunas de forma descendente"
          },
          select: {
            rows: {
              _: "Selecionado %d linhas",
              "0": "Nenhuma linha selecionada",
              "1": "Selecionado 1 linha"
            }
          }
        }
      });
    }
  },
  mounted() {
    if (localStorage.getItem("login")) {
      try {
        const loginJSON = JSON.parse(localStorage.getItem("login"));

        this.isAdmin = loginJSON.isAdmin;

        this.login = {
          headers: {
            Authorization: "Bearer ".concat(loginJSON.api_token),
            Accept: "application/json",
            "Cache-Control": "no-cache"
          }
        };

        this.getUsuarios();
      } catch (e) {
        this.$router.push("/login");
        localStorage.removeItem("login");
      }
    } else {
      this.$router.push("/login");
    }
  },
  destroyed() {}
};
</script>
