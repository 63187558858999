<template>
  <div class="form-group">
    <label>Tags:</label>
    <div class="form-group">
      <tag-list style="display:inline-block;" :tags="tags" 
      @deleted-tag="tag => $emit('deleted-tag', tag)"
      @selected="onTagSelected" 
      @delete-added-tag="confirmDelete"/>
    </div>
    <div class="container-fluid row" style="width: 20em;">
      <div class="tag-color-input" :style="{ backgroundColor: color.hex }" @click="$emit('open-picker')" />
      <div class="col">
        <input
        type="text"
        class="form-control"
        id="tag_name_input"
        placeholder="Nova Tag"
        v-model="new_tag_text"
        />
      </div>
      <button @click.prevent="criarTag" class="btn btn-info">Criar tag</button>
    </div>
  </div>
</template>

<script>
import TagList from '../components/TagList.vue'

export default {
  components: {    
    TagList,
  },
  props: [
    'color',
    'tags',
    'showTagSelect',
  ],
  data() {
    return {
      new_tag_text: null,
    }
  },
  methods: {
    criarTag() {
      this.$emit('create-tag', {
        tag: this.new_tag_text,
        color: this.color,
      });
    },
    onTagSelected(tag) {
      this.$emit('selected', tag);
    },
    confirmDelete(tag) {
      this.$emit('delete-added-tag', tag);
    }
  }
}
</script>

<style>

</style>