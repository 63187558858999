<template>
	<!-- Main Sidebar Container -->
	<aside class="main-sidebar sidebar-dark-primary elevation-4">
		<!-- Brand Logo -->
		<a href="#" class="brand-link bg-transparent">
			<img
				src="@/assets/e.svg"
				alt="esaude"
				class="brand-image img-circle bg-transparent"
				style="opacity: .9"
			/>
			<span class="brand-text font-weight-light">E-Saúde</span>
		</a>

		<!-- Sidebar -->
		<div class="sidebar sidebar-toggle">
			<!-- Sidebar Menu -->
			<nav class="mt-2">
				<ul
					class="nav nav-pills nav-sidebar nav-child-indent flex-column"
					data-widget="treeview"
					role="menu"
					data-accordion="false"
				>
					<!-- Add icons to the links using the .nav-icon class
          with font-awesome or any other icon font library-->

					<li class="nav-item">
						<router-link to="/" class="nav-link">
							<i class="nav-icon fas fa-bookmark mr-1"></i>
							<p>Conteúdos</p>
						</router-link>
					</li>

					<!-- <li class="nav-item">
            <router-link to="/conteudo/editar" class="nav-link">
              <i class="nav-icon fas fa-bookmark mr-1"></i>
              <p>Conteúdo Editar</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/conteudo/solicitar" class="nav-link">
              <i class="nav-icon fas fa-bookmark mr-1"></i>
              <p>Conteúdo Solicitar</p>
            </router-link>
          </li>-->
					<!-- <li class="nav-item">
            <router-link to="/" class="nav-link">
              <i class="nav-icon fas fa-users mr-1"></i>
              <p>Equipe</p>
            </router-link>
          </li>-->
					<!-- <li class="nav-item">
            <router-link to="/" class="nav-link">
              <i class="nav-icon fas fa-cogs mr-1"></i>
              <p>Integrações</p>
            </router-link>
          </li>-->

					<li class="nav-item" v-if="isAdmin">
						<router-link to="/usuarios" class="nav-link">
							<i class="nav-icon fas fa-user mr-1"></i>
							<p>Usuários</p>
						</router-link>
					</li>

					<li class="nav-item" v-if="isAdmin">
						<router-link to="/clientes" class="nav-link">
							<i class="nav-icon fas fa-user-tie mr-1"></i>
							<p>Clientes</p>
						</router-link>
					</li>

					<li class="nav-item" v-if="isAdmin">
						<router-link to="/contratos" class="nav-link">
							<i class="nav-icon fas fa-file-alt mr-1"></i>
							<p>Contratos</p>
						</router-link>
					</li>

					<li v-if="isAdmin" class="nav-item has-treeview">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-chart-line mr-1"></i>
							<p>
								Métricas
								<i class="right fas fa-angle-left"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item">
								<router-link
									to="/relatorio-avaliacao"
									class="nav-link"
								>
									<i
										class="nav-icon fas fa-chart-line mr-1"
									></i>
									<p>Avaliações</p>
								</router-link>
							</li>

							<li class="nav-item">
								<router-link
									to="/relatorio-metricas-avaliacoes"
									class="nav-link"
								>
									<i
										class="nav-icon fas fa-chart-bar mr-1"
									></i>
									<p>Métricas</p>
								</router-link>
							</li>

							<li class="nav-item">
								<router-link
									to="/relatorio-metricas-aprovacoes"
									class="nav-link"
								>
									<i
										class="nav-icon fas fa-chart-bar mr-1"
									></i>
									<p>Métricas Aprov.</p>
								</router-link>
							</li>

							<li class="nav-item">
								<router-link
									to="/relatorio-metricas-producoes"
									class="nav-link"
								>
									<i
										class="nav-icon fas fa-chart-bar mr-1"
									></i>
									<p>Métricas Prod.</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link
									to="/relatorio-producoes-parceiros"
									class="nav-link"
								>
									<i
										class="nav-icon fas fa-chart-bar mr-1"
									></i>
									<p>Métricas Parc.</p>
								</router-link>
							</li>

							<li v-if="false" class="nav-item">
								<router-link
									to="/relatorio-executores"
									class="nav-link"
								>
									<i
										class="nav-icon fas fa-chart-bar mr-1"
									></i>
									<p>Métricas Exec.</p>
								</router-link>
							</li>

							<li v-if="false" class="nav-item">
								<router-link
									to="/relatorio-customer-success"
									class="nav-link"
								>
									<i
										class="nav-icon fas fa-chart-bar mr-1"
									></i>
									<p>Métricas CS</p>
								</router-link>
							</li>
						</ul>
					</li>

					<!--
          <li class="nav-item">
            <router-link to="/login/recuperar-senha" class="nav-link">
              <i class="nav-icon fas fa-unlock mr-1"></i>
              <p>Recuperar Senha</p>
            </router-link>
          </li>
          -->

					<li class="nav-item">
						<a
							href="#"
							class="nav-link"
							v-on:click="confirmLogout()"
						>
							<i class="nav-icon fas fa-sign-out-alt mr-1"></i>
							<p>Sair</p>
						</a>
					</li>
				</ul>
			</nav>
			<!-- /.sidebar-menu -->
		</div>
		<!-- /.sidebar -->
	</aside>
</template>

<script>
import axios from "axios";

export default {
	name: "sidebar",
	data() {
		return {
			isAdmin: 0,
			apiurl: this.$apiUrl + "/logout",
			login: {},
		};
	},
	methods: {
		logout() {
			axios
				.get(this.apiurl, this.login)
				.then((response) => {
					if (response.status == 200) {
						localStorage.removeItem("login");
						this.$router.push("/login");
					}
				})
				.catch((error) => {
					//HAISIAU WHAT? SIM POIS PRECISO DESLOGAR DE QUALQUER JEITO
					if (error) {
						localStorage.removeItem("login");
						this.$router.push("/login");
					} else {
						localStorage.removeItem("login");
						this.$router.push("/login");
					}
				});
		},
		confirmLogout() {
			this.$fire({
				title: "Logout",
				text: "Você tem certeza que deseja sair?",
				type: "question",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Sair",
				cancelButtonColor: "#AAA",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.value) {
					this.logout();
				}
			});
		},
	},
	mounted() {
		if (localStorage.getItem("login")) {
			try {
				const loginJSON = JSON.parse(localStorage.getItem("login"));

				this.isAdmin = loginJSON.isAdmin;

				this.login = {
					headers: {
						Authorization: "Bearer ".concat(loginJSON.api_token),
						Accept: "application/json",
					},
				};
			} catch (e) {
				localStorage.removeItem("login");
			}
		} else {
			localStorage.removeItem("login");
		}
	},
};
</script>
