import './lib/css'
import './lib/scripts'

import Vue from 'vue'
import App from '@/views/App.vue'
import router from '@/router'

/* API */
//DEV AWS
//Vue.prototype.$loginUrl = 'http://aws.vinteum.com/boxti/esaude/e-saude-backend/html/public';

//DEV
// Vue.prototype.$loginUrl = 'http://192.168.0.3:9000';

Vue.prototype.$loginUrl = process.env.VUE_APP_BACKEND_URL;

//PRODUCAO
//Vue.prototype.$loginUrl = 'https://backend.esaudemarketing.com.br';
/* ./API */

Vue.prototype.$apiUrl = Vue.prototype.$loginUrl + '/api';

Vue.prototype.$debugWindow = process.env.NODE_ENV == "development"; //TRUE PARA VER O DEBUG WINDOW

/* NAVBAR */
import Navigator from "./components/Navigator"
Vue.component('navigator', Navigator)

/* SIDEBAR */
import Sidebar from "./components/Sidebar"
Vue.component('sidebar', Sidebar)

/* FOOTER */
import FooterComp from "./components/FooterComp"
Vue.component('footer-comp', FooterComp)

/* DEBUG */
import Debug from "./components/Debug"
Vue.component('debug', Debug)

/* SELECT */
import vSelect from "vue-select"
Vue.component('v-select', vSelect)

/* VUE APEX CHART */
import VueApexCharts from 'vue-apexcharts'
Vue.component('apexchart', VueApexCharts)
Vue.use(VueApexCharts)


/* DIALOG ALERT SWEETALERT */
//https://www.npmjs.com/package/vue-simple-alert
import VueSimpleAlert from "vue-simple-alert"
Vue.use(VueSimpleAlert)

/* MOMENT */
import VueMoment from 'vue-moment';
import axios from 'axios';
import moment from 'moment-timezone'
Vue.use(VueMoment, {
  moment,
})

/* MASCARAS */
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

Vue.config.productionTip = false

/* FILTROS GLOBAIS */
Vue.filter("frontDate", function (value) {
  return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
});

/* MIXIN GLOBAL */
Vue.mixin({
  methods: {
    showalert(title, text, type, timer) {

      if (typeof timer === 'undefined') {

        this.$fire({
          type: type,
          title: title,
          text: text
        });
      } else {
        this.$fire({
          type: type,
          title: title,
          text: text,
          timer: timer
        });
      }

    }
  },
  created() {
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
        //place your reentry code
        localStorage.removeItem("login");
        this.$fire({
          title: "Atenção",
          html: "Sessão expirada<br>Por favor, faça login novamente",
          type: "warning",
          showCancelButton: false,
        }).then(() => {
          this.$router.push('/login');
        });
      }
      return error;
    });
  }
})

new Vue({
  render: h => h(App),
  router,
  // created(){    
  //   //this.$router.push('/')
  // }
}).$mount('#app')
