<template>
  <div class="card">
    <div class="card-header">
      <h3>Indicadores Essenciais</h3>
      <!-- card-tools -->
      <div class="card-tools">
          <button
            type="button"
            class="btn btn-tool"
            data-card-widget="collapse"
          >
            <i class="fas fa-minus"></i>
          </button>
        </div>
      <!-- /.card-tools -->
    </div>

    <div class="card-body">

      <div class="d-flex flex-row justify-content-around">
        <div class="card col-3">
          <div class="card-header d-flex flex-column align-items-center">
            <h3>{{ parseInt((conteudos_entregues_ciclo_atual * 100) / (totalConteudosCiclo)) || 0 }}%</h3>
            <p class="text-center">Conteúdos Entregues Mês Atual</p>
          </div>
          <div class="card-body d-flex flex-row justify-content-center">{{ conteudos_entregues_ciclo_atual }} de {{ totalConteudosCiclo || 0 }}</div>
        </div>
        <div class="card col-3">
          <div class="card-header d-flex flex-column align-items-center">
            <h3>{{ parseInt((conteudos_em_producao_ciclo_atual * 100) / (totalConteudosCiclo)) || 0 }}%</h3>
            <p class="text-center">Conteúdos em Produção Mês Atual</p>
          </div>
          <div class="card-body d-flex flex-row justify-content-center">{{ conteudos_em_producao_ciclo_atual }} de {{ totalConteudosCiclo || 0 }}</div>
        </div>
        <div class="card col-3">
          <div class="card-header d-flex flex-column align-items-center">
            <h3>{{ parseInt((conteudos_entregues_ciclo_anterior * 100) / (conteudos_totais_mes_anterior)) || 0 }}%</h3>
            <p class="text-center">Conteúdos Finalizados Mês Anterior</p>
          </div>
          <div class="card-body d-flex flex-row justify-content-center">{{ conteudos_entregues_ciclo_anterior }} de {{ conteudos_totais_mes_anterior || 0 }}</div>
        </div>
        <div class="card col-3">
          <div class="card-header d-flex flex-column align-items-center">
            <h3>{{ conteudos_em_atraso }}</h3>
            <p class="text-center">Conteúdos Atrasados</p>
          </div>
          <div class="card-body d-flex flex-row justify-content-center">{{ conteudos_em_atraso }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'conteudos_entregues_ciclo_atual',
    'conteudos_em_producao_ciclo_atual',
    'conteudos_entregues_ciclo_anterior',
    'conteudos_em_atraso',
    'conteudos_totais_mes_anterior',
  ],
  computed: {
    totalConteudosCiclo: {
      cache: false,
      get() {
        return this.conteudos_entregues_ciclo_atual + this.conteudos_em_producao_ciclo_atual;
      }
    },
    totalConteudos: {
      cache: false,
      get() {
        return this.conteudos_aberto +
        this.conteudos_producao +
        this.conteudos_revisao  +
        this.conteudos_ajustes +
        this.conteudos_publicar;
      }
    },
  },
}
</script>

<style>

</style>