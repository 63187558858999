<template>
    <div>
        <div class="tag-container">
            <template v-if="tags">
                <tag v-for="tag in tags" :key="tag.id" :tag="tag" @tag-click="deleteDialog"/>
            </template>
            <div>
                <button class="btn btn-secondary" @click.stop.prevent="openSelectTag"><i class="fas fa-plus"></i></button>
                <tag-select ref="tagSelect" class="tag-select" @deleted-tag="tag => $emit('deleted-tag', tag)" @selected="onTagSelected" />
            </div>
        </div>
        <div id="detach-tag-modal" class="modal fade">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">Desassociar essa tag?</h3>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-default" @click="closeDeleteDialog" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger" @click="confirmDelete">Confirmar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import Tag from "./Tag.vue";
import TagSelect from './TagSelect.vue'

axios.defaults.withCredentials = true;

export default {
    props: [
        'tags',
    ],
    components: {
        Tag,
        TagSelect,
    },
    data() {
        return {
            tagToBeDeleted: null,
            showTagSelect: false,
        }
    },
    methods: {
        deleteDialog(tag) {
            $('#detach-tag-modal').modal("show");
            this.tagToBeDeleted = tag;
        },
        closeDeleteDialog() {
            $('#detach-tag-modal').modal("hide");
            this.tagToBeDeleted = null;
        },
        confirmDelete() {
            $('#detach-tag-modal').modal("hide");
            this.$emit('delete-added-tag', this.tagToBeDeleted);
            this.tagToBeDeleted = null;
        },
        openSelectTag() {
            this.$refs.tagSelect.openSelect();
        },
        onTagSelected(tag) {
            this.$emit('selected', tag);
        },
    }
}
</script>
<style scoped>
    .tag-container {
        display: flex;
        gap: .5rem;
        flex-wrap: wrap;
    }
</style>