<template>
  <section class="container">
    <div class="row h-100 justify-content-center">
      <div class="col-10">
        <div class="login-logo mt-5">
          <a href="/">
            <b>E</b>Saúde
          </a>
          <br />
          <a href="/">
            <b>A</b>valiação Mensal
          </a>
          <br />
					
          <div class="progress-group mt-5">
            <span class>
              <b>{{pergunta.ordem}}</b>
              /{{nrPerguntas}}
            </span>
            <br />
            <div class="progress progress-sm">
              <div class="progress-bar bg-primary" v-bind:style="{ width: progress + '%' }"></div>
            </div>
          </div>
					
        </div>				

        <!-- /.login-logo -->
        <form @submit.prevent="verificaLogin(login)" enctype="application/x-www-form-urlencoded">
          <div class="card card-primary mt-5">
            <div class="card-header card bg-primary">
              <h3 class="card-title">{{pergunta.ordem}}) {{pergunta.pergunta}}</h3>
            </div>
            <!-- /.card-header -->
            <!-- form start -->

            <!-- TYPE INTEGER MAX 5 -->
            <div class="card-body" v-if="pergunta.tipo_resposta === 'int_max_5'">
              <div class="form-group">
                <label>Nota de 1 a 5</label>
                <div class="clearfix">
                  <div class="rate">
                    <input
                      type="radio"
                      id="star5"
                      name="rate"
                      value="5"
                      v-model="pergunta.resposta"
                    />
                    <label for="star5" title="5">5</label>
                    <input
                      type="radio"
                      id="star4"
                      name="rate"
                      value="4"
                      v-model="pergunta.resposta"
                    />
                    <label for="star4" title="4">4</label>
                    <input
                      type="radio"
                      id="star3"
                      name="rate"
                      value="3"
                      v-model="pergunta.resposta"
                    />
                    <label for="star3" title="3">3</label>
                    <input
                      type="radio"
                      id="star2"
                      name="rate"
                      value="2"
                      v-model="pergunta.resposta"
                    />
                    <label for="star2" title="2">2</label>
                    <input
                      type="radio"
                      id="star1"
                      name="rate"
                      value="1"
                      v-model="pergunta.resposta"
                    />
                    <label for="star1" title="1">1</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- ./TYPE INTEGER MAX 5 -->

            <!-- TYPE INTEGER MAX 10 -->
            <div class="card-body" v-if="pergunta.tipo_resposta === 'int_max_10'">
              <div class="form-group">
                <label>Selecione de 1 a 10</label>
                <div class="clearfix">

                  <div class="rating-input-row">
                    <div class="round-rating-button" :class="{ selected : pergunta.resposta == 1 }" @click="setResposta(1)"><span>1</span></div>
                    <div class="round-rating-button" :class="{ selected : pergunta.resposta == 2 }" @click="setResposta(2)"><span>2</span></div>
                    <div class="round-rating-button" :class="{ selected : pergunta.resposta == 3 }" @click="setResposta(3)"><span>3</span></div>
                    <div class="round-rating-button" :class="{ selected : pergunta.resposta == 4 }" @click="setResposta(4)"><span>4</span></div>
                    <div class="round-rating-button" :class="{ selected : pergunta.resposta == 5 }" @click="setResposta(5)"><span>5</span></div>
                    <div class="round-rating-button" :class="{ selected : pergunta.resposta == 6 }" @click="setResposta(6)"><span>6</span></div>
                    <div class="round-rating-button" :class="{ selected : pergunta.resposta == 7 }" @click="setResposta(7)"><span>7</span></div>
                    <div class="round-rating-button" :class="{ selected : pergunta.resposta == 8 }" @click="setResposta(8)"><span>8</span></div>
                    <div class="round-rating-button" :class="{ selected : pergunta.resposta == 9 }" @click="setResposta(9)"><span>9</span></div>
                    <div class="round-rating-button" :class="{ selected : pergunta.resposta == 10 }" @click="setResposta(10)"><span>10</span></div>
                  </div>
									<!--
                  <div class="rate">
                    <input
                      type="radio"
                      id="star10"
                      name="rate"
                      value="10"
                      v-model="pergunta.resposta"
                    />
                    <label for="star10" title="10">10</label>
                    <input
                      type="radio"
                      id="star9"
                      name="rate"
                      value="9"
                      v-model="pergunta.resposta"
                    />
                    <label for="star9" title="9">9</label>
                    <input
                      type="radio"
                      id="star8"
                      name="rate"
                      value="8"
                      v-model="pergunta.resposta"
                    />
                    <label for="star8" title="8">8</label>
                    <input
                      type="radio"
                      id="star7"
                      name="rate"
                      value="7"
                      v-model="pergunta.resposta"
                    />
                    <label for="star7" title="7">7</label>
                    <input
                      type="radio"
                      id="star6"
                      name="rate"
                      value="6"
                      v-model="pergunta.resposta"
                    />
                    <label for="star6" title="6">6</label>
                    <input
                      type="radio"
                      id="star5"
                      name="rate"
                      value="5"
                      v-model="pergunta.resposta"
                    />
                    <label for="star5" title="5">5</label>
                    <input
                      type="radio"
                      id="star4"
                      name="rate"
                      value="4"
                      v-model="pergunta.resposta"
                    />
                    <label for="star4" title="4">4</label>
                    <input
                      type="radio"
                      id="star3"
                      name="rate"
                      value="3"
                      v-model="pergunta.resposta"
                    />
                    <label for="star3" title="3">3</label>
                    <input
                      type="radio"
                      id="star2"
                      name="rate"
                      value="2"
                      v-model="pergunta.resposta"
                    />
                    <label for="star2" title="2">2</label>
                    <input
                      type="radio"
                      id="star1"
                      name="rate"
                      value="1"
                      v-model="pergunta.resposta"
                    />
                    <label for="star1" title="1">1</label>
                  </div>
									-->						
                </div>
              </div>
            </div>
            <!-- ./TYPE INTEGER MAX 10 -->

            <!-- TYPE TEXT -->
            <div class="card-body" v-if="pergunta.tipo_resposta === 'text'">
              <div class="form-group">
                <label>Escreva sua resposta</label>
                <div class="clearfix mt-2">
                  <input
                    class="form-control form-control-lg"
                    type="text"
                    placeholder="Resposta"
                    v-model="pergunta.resposta"
                  />
                </div>
              </div>
            </div>
            <!-- ./TYPE TEXT -->

            <!-- TYPE BOOL -->
            <div class="card-body" v-if="pergunta.tipo_resposta === 'bool'">
              <div class="form-group">
                <label>Selecione uma resposta</label>
                <div class="clearfix mt-2">
                  <div class="icheck-success d-inline">
                    <input
                      type="radio"
                      name="r2"
                      id="radioDanger2"
                      v-model="pergunta.resposta"
                      value="1"
                    />
                    <label for="radioDanger2">Sim</label>
                  </div>
                  <div class="icheck-danger d-inline ml-3">
                    <input
                      type="radio"
                      name="r2"
                      id="radioDanger3"
                      v-model="pergunta.resposta"
                      value="0"
                    />
                    <label for="radioDanger3">Não</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- ./TYPE INTEGER -->

            <div class="card-footer">
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="proximaPergunta(pergunta)"
                v-if="btnControle"
              >Próximo</button>
            </div>
          </div>
        </form>

        <debug :log="debug"></debug>
      </div>
    </div>
    <!-- /.login-box -->
  </section>
</template>

<style>
.rate {
  float: left;
  height: 46px;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}
.round-rating-button {
  height: 4em;
  width: 4rem;
  border: 1px solid lightgrey;
  font-style: bold;
  border-radius: 50%;
  box-shadow: 2px 2px 6px #00000020;
  display: flex;
  align-content: center;
  justify-content: center;
  cursor: pointer;
}
.round-rating-button.selected {
  background-color: #0000AA20;
}
.round-rating-button > span {
  font-size: 2rem;
  line-height: 3.5rem;
  user-select: none;
}
.rating-input-row {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: center;
}
</style>

<script>
import axios from "axios";
//import $ from "jquery";

export default {
  name: "avaliacao-mensal",
  data() {
    return {
      avaliacao_pergunta_url:
        this.$apiUrl + "/avaliacao_mensal_pergunta",
      avaliacao_resposta_url:
        this.$apiUrl + "/avaliacao_mensal_resposta",
      login: {},
      loginObj: {},
      avaliacao: {},
      retorno: {},
      btnControle: true,
      perguntas: [],
      nrPerguntas: 0,
      progress: 0,
      pergunta: {},
      debug: {},
      respostas: []
    };
  },
  watch: {
    'pergunta.resposta'() {
      console.log(this.pergunta.resposta);
    }
  },
  methods: {
    resposta(pergunta, ultimo) {
      
      this.debugLog(pergunta, "FRONT");

      // axios
      //   .post(this.avaliacao_resposta_url, pergunta, this.login)
      //   .then(response => {
      //     var _t = this;

      //     this.btnControle = true;

      //     if (ultimo) {
      //       _t.showalert(
      //         "Obrigado",
      //         "Sua opinição é muito importante para nós.",
      //         "success"
      //       );

      //       const loginJSON = JSON.parse(localStorage.getItem("login"));
      //       loginJSON.client.avaliacao_mensal_necessaria = false;
      //       localStorage.login = JSON.stringify(loginJSON);

      //       this.$router.push("/");
      //     } else {
      //       this.perguntas.shift(); //AVANÇA A PERGUNTA
      //       this.pergunta = this.perguntas[0];
      //       this.progress = (100 / this.nrPerguntas) * this.pergunta.id;
      //     }
      //     //var _t = this;

      //     this.retorno = response.data;

      //     this.debugLog(this.retorno, "API");
      //   });

      this.btnControle = true;

      if(this.progress <= 101) {
        this.respostas.push(this.perguntas.shift()); //AVANÇA A PERGUNTA        
      }

      if(ultimo) {
        axios
        .post(this.avaliacao_resposta_url, this.respostas, this.login)
        .then(() => {
          this.showalert(
            "Obrigado",
            "Sua opinião é muito importante para nós.",
            "success"
          );
  
          const loginJSON = JSON.parse(localStorage.getItem("login"));
          loginJSON.client.avaliacao_mensal_necessaria = false;
          localStorage.login = JSON.stringify(loginJSON);
  
          this.$router.push("/");
        }).catch(error => {
          console.error(error);
        });

        return;
      }

      this.pergunta = this.perguntas[0];
      this.progress = (100 / this.nrPerguntas) * this.pergunta.ordem;
      //var _t = this;

      this.debugLog(this.pergunta, "API");
    },
    proximaPergunta(pergunta) {      

      var _t = this;

      pergunta.pergunta_id = pergunta.id;
      pergunta.client_id = this.loginObj.client.id;

      if (typeof pergunta.resposta === "undefined") {
        _t.showalert(
          "Atenção.",
          "Você precisa responder a pergunta",
          "warning"
        );
        return false;
      }

      this.btnControle = false;

      const nrPerguntas = this.perguntas.length;

      if (nrPerguntas > 1) {
        setTimeout(() => {
          this.resposta(this.pergunta, false);
        }, 100);
      } else {
        setTimeout(() => {
          this.resposta(this.pergunta, true); //ULTIMO
        }, 100);
      }
    },
    getAvaliacaoPergunta() {
      axios
        .get(this.avaliacao_pergunta_url, this.login)
        .then(response => {
          this.perguntas = response.data.message;
          this.pergunta = this.perguntas[0];
          this.nrPerguntas = this.perguntas.length;
          this.progress = (100 / this.nrPerguntas) * this.pergunta.ordem;

          this.debugLog(this.pergunta, "API");
          //this.debugLog(this.pergunta, "FRONT");
        })
        .catch(error => {
         console.error(error);
        });
    },
    debugLog(mensagem, sender) {      

      this.debug = {};

      this.debug.mensagem = mensagem;
      this.debug.sender = sender;
      this.debug.time = this.$moment().format();      
    },
    setResposta(resposta) {
      this.$set(this.pergunta, 'resposta', resposta);
    }
  },
  mounted() {
    if (localStorage.getItem("login")) {
      try {
        const loginJSON = JSON.parse(localStorage.getItem("login"));

        this.isAdmin = loginJSON.isAdmin;
        this.loginObj = loginJSON;

        this.login = {
          headers: {
            Authorization: "Bearer ".concat(loginJSON.api_token),
            Accept: "application/json",
            "Cache-Control": "no-cache"
          }
        };

        this.getAvaliacaoPergunta();
      } catch (e) {
        this.$router.push("/login");
        localStorage.removeItem("login");
      }
    } else {
      this.$router.push("/login");
    }
  },
  destroyed() {}
};
</script>
