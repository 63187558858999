<template>
	<Relatorio>
		<!-- right col (We are only adding the ID to make the widgets sortable)-->
		<section class="col-lg-12 connectedSortable">
			<div class="card">
				<div class="card-header">
					<h3>Metricas Executores</h3>
					<div class="card-tools">
						<!-- Maximize Button -->
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
					<!-- /.card-tools -->
				</div>

				<!-- /.card-header -->
				<div class="card-body">
					<div class="row justify-content-md-center">
						<div class="col-sm-12 col-md-6 col-lg-2 sm">
							<label for="ano_vigencia">Ano Vigencia</label>

							<input
								type="text"
								class="form-control"
								placeholder="aaaa"
								v-model="filtro.ano_vigencia"
								v-mask="'####'"
								name="ano_vigencia"
								id="ano_vigencia"
								autocomplete="off"
							/>
						</div>
					</div>

					<div class="row mt-3">
						<div class="col">
							<div class="form-group">
								<button
									v-on:click="getMetricasCS()"
									id="btnn"
									type="button"
									class="btn btn-info form-control"
								>
									PESQUISAR
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="col-lg-12 connectedSortable">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<div class="row">
						<div class="col xs12 md4">
							<h3>Clientes</h3>
						</div>
					</div>
					<div class="card-tools">
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
				</div>

				<div class="card-body">
					<!-- <div class="table-responsive"> This uses responsive search -->
					<div class="table-responsive">
						<table
							id="tblMetricaTimeExecutores"
							class="table table-bordered table-striped dataTable"
							role="grid"
							aria-describedby="conteudos"
						>
							<thead>
								<tr role="row">
									<th rowspan="2" colspan="1">
										Times
									</th>
									<th rowspan="1" colspan="2">
										Janeiro
									</th>
									<th rowspan="1" colspan="2">
										Fevereiro
									</th>
									<th rowspan="1" colspan="2">
										Março
									</th>
									<th rowspan="1" colspan="2">
										Abril
									</th>
									<th rowspan="1" colspan="2">
										Maio
									</th>
									<th rowspan="1" colspan="2">
										Junho
									</th>
									<th rowspan="1" colspan="2">
										Julho
									</th>
									<th rowspan="1" colspan="2">
										Agosto
									</th>
									<th rowspan="1" colspan="2">
										Setembro
									</th>
									<th rowspan="1" colspan="2">
										Outubro
									</th>
									<th rowspan="1" colspan="2">
										Novembro
									</th>
									<th rowspan="1" colspan="2">
										Dezembro
									</th>
								</tr>
								<tr role="row">
									<th rowspan="1" colspan="1">
										CSAT
									</th>
									<th rowspan="1" colspan="1">
										NPS
									</th>
									<th rowspan="1" colspan="1">
										CSAT
									</th>
									<th rowspan="1" colspan="1">
										NPS
									</th>
									<th rowspan="1" colspan="1">
										CSAT
									</th>

									<th rowspan="1" colspan="1">
										NPS
									</th>
									<th rowspan="1" colspan="1">
										CSAT
									</th>

									<th rowspan="1" colspan="1">
										NPS
									</th>
									<th rowspan="1" colspan="1">
										CSAT
									</th>

									<th rowspan="1" colspan="1">
										NPS
									</th>
									<th rowspan="1" colspan="1">
										CSAT
									</th>

									<th rowspan="1" colspan="1">
										NPS
									</th>
									<th rowspan="1" colspan="1">
										CSAT
									</th>

									<th rowspan="1" colspan="1">
										NPS
									</th>
									<th rowspan="1" colspan="1">
										CSAT
									</th>

									<th rowspan="1" colspan="1">
										NPS
									</th>
									<th rowspan="1" colspan="1">
										CSAT
									</th>

									<th rowspan="1" colspan="1">
										NPS
									</th>
									<th rowspan="1" colspan="1">
										CSAT
									</th>

									<th rowspan="1" colspan="1">
										NPS
									</th>
									<th rowspan="1" colspan="1">
										CSAT
									</th>

									<th rowspan="1" colspan="1">
										NPS
									</th>
									<th rowspan="1" colspan="1">
										CSAT
									</th>

									<th rowspan="1" colspan="1">
										NPS
									</th>
								</tr>
							</thead>

							<tbody>
								<tr
									role="row"
									class="odd"
									v-for="(time, index) in metrica_cs.times"
									:key="index"
								>
									<td>{{ time.nome }}</td>
									<td>
										{{
											time.meses[0].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>
									<td>
										{{ time.meses[0].nps.toFixed(2) }}
										%
									</td>
									<td>
										{{
											time.meses[1].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>

									<td>
										{{ time.meses[1].nps.toFixed(2) }}
									</td>
									<td>
										{{
											time.meses[2].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>

									<td>
										{{ time.meses[2].nps.toFixed(2) }}
									</td>
									<td>
										{{
											time.meses[3].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>

									<td>
										{{ time.meses[3].nps.toFixed(2) }}
									</td>
									<td>
										{{
											time.meses[4].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>

									<td>
										{{ time.meses[4].nps.toFixed(2) }}
									</td>
									<td>
										{{
											time.meses[5].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>

									<td>
										{{ time.meses[5].nps.toFixed(2) }}
									</td>
									<td>
										{{
											time.meses[6].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>

									<td>
										{{ time.meses[6].nps.toFixed(2) }}
									</td>
									<td>
										{{
											time.meses[7].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>

									<td>
										{{ time.meses[7].nps.toFixed(2) }}
									</td>
									<td>
										{{
											time.meses[8].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>

									<td>
										{{ time.meses[8].nps.toFixed(2) }}
									</td>
									<td>
										{{
											time.meses[9].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>

									<td>
										{{ time.meses[9].nps.toFixed(2) }}
									</td>
									<td>
										{{
											time.meses[10].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>

									<td>
										{{ time.meses[10].nps.toFixed(2) }}
									</td>
									<td>
										{{
											time.meses[11].media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>

									<td>
										{{ time.meses[11].nps.toFixed(2) }}
									</td>
								</tr>
							</tbody>
						</table>

						<table
							id="tblMetricaExecutores"
							class="table table-bordered table-striped dataTable"
							role="grid"
							aria-describedby="conteudos"
						>
							<thead>
								<tr role="row">
									<th rowspan="2" colspan="1">
										Executores
									</th>
									<th rowspan="1" colspan="1">
										Janeiro
									</th>
									<th rowspan="1" colspan="1">
										Fevereiro
									</th>
									<th rowspan="1" colspan="1">
										Março
									</th>
									<th rowspan="1" colspan="1">
										Abril
									</th>
									<th rowspan="1" colspan="1">
										Maio
									</th>
									<th rowspan="1" colspan="1">
										Junho
									</th>
									<th rowspan="1" colspan="1">
										Julho
									</th>
									<th rowspan="1" colspan="1">
										Agosto
									</th>
									<th rowspan="1" colspan="1">
										Setembro
									</th>
									<th rowspan="1" colspan="1">
										Outubro
									</th>
									<th rowspan="1" colspan="1">
										Novembro
									</th>
									<th rowspan="1" colspan="1">
										Dezembro
									</th>
								</tr>
								<tr role="row">
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
									<th rowspan="1" colspan="1">
										CSAT Atendimento
									</th>
								</tr>
							</thead>

							<tbody>
								<tr
									role="row"
									class="odd"
									v-for="(executor,
									index) in metrica_cs.executores"
									:key="index"
								>
									<td>{{ executor.nome }}</td>
									<td
										v-for="(item,
										key,
										index) in executor.meses"
										:key="`${key}-${index}`"
									>
										{{
											item.media_csat_atendimento.toFixed(
												2,
											)
										}}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>
	</Relatorio>
</template>
<style>
.cursor-pointer {
	cursor: pointer;
}
.note-float-left,
note-float-right {
	padding: 4px;
}
</style>
<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import Relatorio from "../../components/Relatorio.vue";

export default {
	name: "relatorio-metricas-customer-success",
	components: {
		Relatorio,
	},
	data() {
		return {
			titulo: "Carregando lista...",
			usuarios_url: this.$apiUrl + "/users",
			clientes_url: this.$apiUrl + "/clients",
			metricas_cs_url: this.$apiUrl + "/metrica_cs",
			tipos_url: this.$apiUrl + "/conteudo_tipo",
			login: {},
			loginObj: {},
			filtro: {
				ano_vigencia: this.$moment().format("YYYY"),
			},
			relatorio: [],
			clientes: [],
			usuarios: [],
			tipos: [],
			isAdmin: false,
			table: false,
			loading: false,
			metrica_cs: { times: [], executores: [] },
			// Chart options
			chartOptions: {
				chart: {
					type: "bar",
					height: 350,
					stacked: true,
					toolbar: {
						show: true,
					},
					zoom: {
						enabled: true,
					},
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								position: "bottom",
								offsetX: -10,
								offsetY: 0,
							},
						},
					},
				],
				plotOptions: {
					bar: {
						horizontal: false,
						borderRadius: 10,
					},
				},
				xaxis: {
					type: "text",
					categories: [], //x axis names "[mai jun jul ago...]"
				},
				legend: {
					position: "right",
					offsetY: 40,
				},
				fill: {
					opacity: 1,
				},
			},
			// end of chart options
		};
	},
	methods: {
		getMetricasCS() {
			this.loading = true;
			axios
				.get(this.metricas_cs_url, { params: this.filtro })
				.then((response) => {
					this.metrica_cs = this.tratativa_response(response.data);
					this.dataTable();
				})
				.catch((error) => {
					this.showalert("Atenção", error, "error");
				})
				.finally(() => {
					this.loading = false;
				});
		},
		tratativa_response(data) {
			let meses = data.meses;

			let squad1 = { nome: "Squad 1", meses: [] };
			let squad2 = { nome: "Squad 2", meses: [] };

			let nomes_executores = meses[1].executores.map(
				(executor) => executor.nome,
			);

			let executores = nomes_executores.map((nome) => {
				let executor = { nome: nome, meses: [] };

				for (let i = 1; i <= 12; i++) {
					let executorFiltro = meses[i].executores.filter(
						(filtro) => filtro.nome === executor.nome,
					);

					let value =
						executorFiltro.length == 0
							? {
									nome: executor.nome,
									media_csat_atendimento: 0,
							  }
							: executorFiltro[0];

					executor.meses.push(value);
				}

				return executor;
			});

			for (let i = 1; i <= 12; i++) {
				squad1.meses.push({
					media_csat_atendimento:
						meses[i].times[0].media_csat_atendimento == null
							? 0
							: meses[i].times[0].media_csat_atendimento,
					nps:
						meses[i].times[0].nps == null
							? 0
							: meses[i].times[0].nps,
				});
				squad2.meses.push({
					media_csat_atendimento:
						meses[i].times[1].media_csat_atendimento == null
							? 0
							: meses[i].times[1].media_csat_atendimento,
					nps:
						meses[i].times[1].nps == null
							? 0
							: meses[i].times[1].nps,
				});
			}

			return { times: [squad1, squad2], executores };
		},
		getUsuarios() {
			axios
				.get(this.usuarios_url, this.login)
				.then((response) => {
					this.usuarios = response.data;
				})
				.catch((error) => {
					this.titulo = error;
				});
		},
		getClientes() {
			axios
				.get(this.clientes_url + "?include_deleted=1", this.login)
				.then((response) => {
					this.clientes = response.data;
				})
				.catch((error) => {
					this.titulo = error;
				});

			this.editar = false;
		},

		dataTable() {
			var _e = this;

			if (_e.table != false) {
				_e.table.destroy();

				setTimeout(() => {
					_e.renderTable();
				}, 100);
			} else {
				setTimeout(() => {
					_e.renderTable();
				}, 100);
			}
		},
		renderTable() {
			this.table = $("#tblMetricaTimeExecutores").DataTable({
				paging: false,
				bInfo: true,
				bLengthChange: false,
				searching: true,
				order: [[0, "asc"]],
				language: {
					sEmptyTable: "Pesquisando registros...",
					sInfo:
						"Mostrando de _START_ até _END_ de _TOTAL_ registros",
					sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
					sInfoFiltered: "(Filtrados de _MAX_ registros)",
					sInfoPostFix: "",
					sInfoThousands: ".",
					sLengthMenu: "_MENU_ resultados por página",
					sLoadingRecords: "Carregando...",
					sProcessing: "Processando...",
					sZeroRecords: "Nenhum registro encontrado",
					sSearch: "Pesquisar",
					oPaginate: {
						sNext: "Próximo",
						sPrevious: "Anterior",
						sFirst: "Primeiro",
						sLast: "Último",
					},
					oAria: {
						sSortAscending: ": Ordenar colunas de forma ascendente",
						sSortDescending:
							": Ordenar colunas de forma descendente",
					},
					select: {
						rows: {
							_: "Selecionado %d linhas",
							"0": "Nenhuma linha selecionada",
							"1": "Selecionado 1 linha",
						},
					},
				},
			});

			this.table = $("#tblMetricaExecutores").DataTable({
				paging: false,
				bInfo: true,
				bLengthChange: false,
				searching: true,
				order: [[0, "asc"]],
				language: {
					sEmptyTable: "Pesquisando registros...",
					sInfo:
						"Mostrando de _START_ até _END_ de _TOTAL_ registros",
					sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
					sInfoFiltered: "(Filtrados de _MAX_ registros)",
					sInfoPostFix: "",
					sInfoThousands: ".",
					sLengthMenu: "_MENU_ resultados por página",
					sLoadingRecords: "Carregando...",
					sProcessing: "Processando...",
					sZeroRecords: "Nenhum registro encontrado",
					sSearch: "Pesquisar",
					oPaginate: {
						sNext: "Próximo",
						sPrevious: "Anterior",
						sFirst: "Primeiro",
						sLast: "Último",
					},
					oAria: {
						sSortAscending: ": Ordenar colunas de forma ascendente",
						sSortDescending:
							": Ordenar colunas de forma descendente",
					},
					select: {
						rows: {
							_: "Selecionado %d linhas",
							"0": "Nenhuma linha selecionada",
							"1": "Selecionado 1 linha",
						},
					},
				},
			});
		},
	},
	computed: {
		totalConteudos: function() {
			return (
				this.producoes.conteudos_aberto +
				this.producoes.conteudos_producao +
				this.producoes.conteudos_revisao +
				this.producoes.conteudos_ajustes +
				this.producoes.conteudos_publicar
			);
		},
	},
	mounted() {
		this.contador_login = localStorage.getItem("contador");

		if (localStorage.getItem("login")) {
			try {
				const loginJSON = JSON.parse(localStorage.getItem("login"));

				this.loginObj = loginJSON;

				this.isAdmin = loginJSON.isAdmin;

				this.login = {
					headers: {
						Authorization: "Bearer ".concat(loginJSON.api_token),
						Accept: "application/json",
						"Cache-Control": "no-cache",
					},
				};

				setTimeout(() => {
					this.getMetricasCS();
					this.getClientes();
					this.getUsuarios();
				}, 300);
			} catch (e) {
				localStorage.removeItem("login");
				this.$router.push("/login");
			}
		} else {
			localStorage.removeItem("login");
			this.$router.push("/login");
		}
	},
	destroyed() {
		localStorage.contador = 0;
	},
};
</script>
