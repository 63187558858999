<template>
  <div class="wrapper">
    <navigator></navigator>

    <sidebar></sidebar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <!-- HEADER -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Small boxes (Stat box) -->

          <!-- Main row -->
          <!-- regra removida: && contador_login == 1 -->
          <div class="row">
            <section
              class="col-lg-12 connectedSortable"
              v-if="loginObj.client"
            >

              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    <i class="nav-icon fas fa-bookmark mr-2"></i> {{ titulo }}
                  </h3>

                  <div class="card-tools">
                    <button
                      type="button"
                      class="btn btn-tool"
                      data-card-widget="collapse"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </div>
                </div>

                <div class="card-body">
                  <div
                    class="callout callout-danger"
                    v-if="solicitacao_extra_restante == 0"
                  >
                    <p>
                      Você não tem solicitações extras disponíveis no momento.
                    </p>
                  </div>

                  <div class="card-body p-0 no-border border-0">
                    <div
                      class="overlay"
                      v-if="loading"
                    >
                      <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>

                    <div
                      class="callout callout-warning"
                      v-if="contadorAprovacao >= 1"
                    >
                      <p>
                        Você tem {{ contadorAprovacao }} conteúdo(s) para
                        aprovar.
                        <button
                          href
                          v-on:click="getConteudoEstado(5)"
                          class="btn btn-link m-0 p-0"
                        >
                          Ver conteúdo(s)</button>.
                      </p>
                    </div>

                    <div
                      class="callout callout-success"
                      v-if="
                        solicitacao_extra_restante >= 1 &&
                          !loading
                      "
                    >
                      <p>
                        Você ainda tem
                        {{ solicitacao_extra_restante }}
                        horas de solicitações extras disponíveis.
                        <router-link
                          to="/conteudo/solicitar"
                          class="btn btn-link m-0 p-0 text-blue"
                        >Fazer solicitação</router-link>.
                      </p>
                    </div>
                  </div>

                  <!-- FINAL CALLOUTS -->
                </div>
              </div>

              <div class="row">
                <div
                  class="col-md-6"
                  v-if="loginObj.client.links_uteis"
                >
                  <!-- LINKS UTEIS -->
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="nav-icon fas fa-link mr-2"></i>
                        Links Úteis
                      </h3>
                    </div>
                    <div class="card-body bg-gray-light">
                      <p v-html="loginObj.client.links_uteis"></p>
                    </div>
                  </div>
                  <!-- ./LINKS UTEIS -->
                </div>
                <div
                  class="col-md-6"
                  v-if="loginObj.client.informes"
                >
                  <!-- LINKS UTEIS -->
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="nav-icon fas fa-paperclip mr-2"></i>
                        Informes
                      </h3>
                    </div>
                    <div class="card-body bg-gray-light">
                      <p v-html="loginObj.client.informes"></p>
                    </div>
                  </div>
                  <!-- ./LINKS UTEIS -->
                </div>
              </div>
            </section>

          <section v-if="( loginObj.client == null || (loginObj.client != null && isAdmin) ) && producoes" class="col-lg-12 connectedSortable">
            <indicadores v-bind="producoes"/>
          </section>

            <section class="col-lg-12 connectedSortable">
              <div class="card">

                <!-- <div class="card-header">
                  <div class="card-tools">
                    <button
                      type="button"
                      class="btn btn-tool"
                      data-card-widget="collapse"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </div>
                </div> -->

                <div class="card-body">

                  <form v-on:submit.prevent="getConteudoFiltro">

                    <div class="row">
                      <div class="row col-lg-11 min-width-fields">

                        <!-- Filtro -->

                        <!-- SE NAO É CLIENTE -->
                        <div
                          class="col-sm-12 col-md-6 col-lg-2 md"
                          v-if="isAdmin"
                        >
                          <label for="">Pesquisa ampla</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Pesquise por palavra"
                            v-model="filtro.filtro"
                            name="titulo"
                            autocomplete="off"
                          />
                        </div>
                        <!-- SE É CLIENTE -->
                        <div
                          class="col-sm-12 col-md-6 col-lg-4 md"
                          v-else
                        >
                          <label for="">Pesquisa ampla</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Pesquise por palavra"
                            v-model="filtro.filtro"
                            name="titulo"
                            autocomplete="off"
                          />
                        </div>
                        <!-- ./Filtro -->
                        
                        <!-- Cliente -->
                        <div v-if="!(loginObj.client_id !== null && isAdmin !== 1)" class="col-sm-12 col-md-6 col-lg-2 md">
                          <label for="">Cliente</label>
                          <v-select
                            taggable
                            id="cliente"
                            placeholder="Selecione o cliente"
                            label="nome_fantasia"
                            :options="clientes"
                            :reduce="clientes => clientes.id"
                            v-model="filtro.client_id"
                          ></v-select>
                        </div>
                        <!-- ./Cliente -->

                        <!-- Cliclo -->
                        <div class="col-sm-12 col-md-6 col-lg-1 sm">
                          <label for="">Ciclo</label>

                          <input
                            type="text"
                            class="form-control"
                            placeholder="mm/aa"
                            v-model="filtro.ciclo"
                            v-mask="'##/##'"
                            name="ciclo"
                            autocomplete="off"
                          />
                        </div>
                        <!-- ./Ciclo -->

                        <!-- Tipo -->
                        <div class="col-sm-12 col-md-6 col-lg-2 lg">
                          <label for="">Tipo</label>
                          <v-select
                            taggable
                            id="tipo"
                            placeholder="Selecione o tipo"
                            label="descricao"
                            :options="tipos"
                            :reduce="tipos => tipos.id"
                            v-model="filtro.tipo_id"
                          ></v-select>
                        </div>
                        <!-- ./Tipo -->                       

                        <!-- Status -->
                        <div class="col-sm-12 col-md-6 col-lg-2 md">
                          <label for="">Status</label>
                          <v-select
                            taggable
                            id="estado"
                            placeholder="Selecione o Estado"
                            label="descricao"
                            :options="estados"
                            :reduce="estados => estados.id"
                            v-model="filtro.estado_id"
                          ></v-select>
                        </div>
                        <!-- ./Status -->                        

                        <!-- Responsavel -->
                        <div
                          class="col-sm-12 col-md-6 col-lg-2 md"
                          v-if="loginObj.client_id == null"
                        >
                          <label for="">Responsável</label>
                          <v-select
                            taggable
                            id="responsavel"
                            placeholder="Selecione o Responsável"
                            label="name"
                            :options="usuarios"
                            :reduce="usuarios => usuarios.id"
                            v-model="filtro.responsavel"
                          ></v-select>
                        </div>
                        <!-- ./Responsavel -->

                        <!-- Tags -->
                        <div
                          class="col-sm-12 col-md-6 col-lg-2 md"
                          v-if="loginObj.client_id == null"
                        >
                          <div>
                            <label for="">Tags</label>
                          </div>
                          <tag-list style="display:inline-block;" :tags="tags" @selected="addTag" @delete-added-tag="removeTag" @deleted-tag="removeTag"/>
                          
                        </div>
                        <!-- ./Tags -->

                        <!-- PRAZO -->
                        <div class="col-sm-12 col-md-6 col-lg-1 sm" v-if="!(loginObj.client_id !== null && isAdmin === 0)">
                          <label for="data_entrega">Prazo</label>
                            <!-- <input
                              id="datahora_publicacao"
                              type="datetime-local"
                              step="1"
                              v-model="data_hora_publicacao"
                            />-->
                            <!-- //@change="convDataEntrega" -->
                            <!--<input
                              id="data_entrega"
                              autocomplete="off"
                              type="date"
                              class="form-control"
                              v-model="prazo"
                            />-->
                            <date-picker
                              style="width: 100%;"
                              v-model="prazo"
                              format="DD/MM/YY"
                              valueType="YYYY-MM-DD"/>
                        </div>
                        <!-- ./PRAZO -->
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-1">
                        <label
                          for=""
                          class="text-white"
                        >Pesquisar</label>
                        <button
                          type="submit"
                          class="btn btn-default btn-block"
                          style="background: #e9ecef; height: calc(100% - 2.25em);"
                        >
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </section>

            <!-- Left col -->
            <section class="d-none d-sm-none d-md-none d-lg-block col-lg-3 connectedSortable ">

              <!-- FLAGS -->
              <div class="card">
                <div class="card-header">
                  <div class="card-tools">
                    <button
                      type="button"
                      class="btn btn-tool"
                      data-card-widget="collapse"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                  </div>
                </div>

                <div class="card-body p-0 no-border border-0">

                  <!-- <div
                    class="overlay"
                    v-if="loading"
                  >
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div> -->

                  <table class="table table-condensed d-none d-sm-none d-md-none d-lg-table">
                    <tbody>
                      <tr>
                        <td
                          v-on:click="getConteudoFiltro(1, true)"
                          class="cursor-pointer"
                          style="width: 50px"
                        >
                          <span class="text-light badge p-3 btn-block btn-dark">{{ conteudos_total }}</span>
                        </td>
                        <td
                          class="cursor-pointer"
                          v-on:click="getConteudoFiltro(1, true)"
                        >
                          Todos
                          <br />
                          <b>Todos</b>
                        </td>
                      </tr>

                      <tr
                        v-for="estado in estados"
                        :key="estado.id"
                      >
                        <td
                          class="cursor-pointer"
                          style="width: 50px"
                          v-if="estado.conteudos_count"
                          v-on:click="getConteudoEstado(estado.id)"
                        >
                          <span
                            v-bind:style="{ backgroundColor: estado.cor }"
                            class="text-light badge p-3 btn-block"
                          >{{ estado.conteudos_count }}</span>
                        </td>
                        <td
                          class="cursor-pointer"
                          v-if="estado.conteudos_count"
                          v-on:click="getConteudoEstado(estado.id)"
                        >
                          {{ estado.descricao }}
                          <br />
                          <b>{{ estado.descricao }}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- ./estados -->

                  <!-- estados -->
                  <!-- <div class="form-group d-sm-block d-md-block d-lg-none d-xl-none m-4">
                    <label for="estadosSelect">Status</label>

                    <select
                      v-on:change="changeEstados($event)"
                      class="form-control">
                      <option
                        v-for="estado in estados"
                        :key="estado.id"
                        :value="estado.id">
                        {{ estado.descricao }}
                      </option>
                    </select>
          
                  </div> -->

                </div>
                <!-- /.card-body -->
              </div>
              <!-- FINAL FLAGS -->

            </section>
            <!-- /.Left col -->

            <!-- right col (We are only adding the ID to make the widgets sortable)-->
            <section class="col-lg-9">
              <div class="card">
                <div class="card-header">
                  <h3
                    class="card-title p-0 m-0"
                    v-if="isAdmin === 1 || loginObj.client_id !== null"
                  >
                    <!-- <h3 class="card-title p-0 m-0"> -->
                    <router-link
                      to="/conteudo/solicitar"
                      class="nav-link p-0 m-0"
                    >
                      <i class="nav-icon fas fa-plus mr-1"></i>
                      Solicitar Conteúdo
                    </router-link>
                  </h3>
                  <div class="card-tools">
                    <!-- Maximize Button -->
                    <button
                      type="button"
                      class="btn btn-tool"
                      data-card-widget="maximize"
                    >
                      <i class="fas fa-expand"></i>
                    </button>
                  </div>
                  <!-- /.card-tools -->
                </div>

                <!-- /.card-header -->
                <div class="card-body">

                  <div>
                    <!-- {{filtro}} -->
                    <a name="conteudos"></a>

                    <table
                      id="tblConteudos"
                      class="table table-bordered table-striped dataTable mt-4"
                      role="grid"
                      aria-describedby="conteudos"
                    >
                      <thead>
                        <tr>
                          <th rowspan="1" colspan="1" v-if="!!tipoAcao">
                            <input 
                            type="checkbox" id="selecionartudo"
                            v-model="selecionartudo"
                            v-on:change="handleCheckboxSelect()"
                            />
                            <label for="selecionartudo">Selecionar tudo</label> 
                          </th>
                          <th rowspan="1" colspan="4">
                            <v-select
                            placeholder="Selecione a ação"
                            label="text"
                            :options="tipoAcoes"
                            v-model="tipoAcao"
                          />

                          </th>
                          <th rowspan="1" colspan="2">
                            <button
                              :disabled="!(!!tipoAcao && conteudo_id_array.length)"
                              type="button"
                              class="btn btn-block btn-success btn-xs m-2"
                              v-on:click.stop="showConfirmMass()"
                            >
                              Executar
                            </button>
                          </th>
                        </tr>
                        
                        <tr role="row">
                          <th
                            rowspan="1"
                            width="80px"
                            colspan="1"
                            class="d-none"
                          >
                            Ciclo
                          </th>

                          <th 
                          rowspan="1"
                          colspan="1"
                          v-if="!!tipoAcao"
                          >
                          Selecionar
                          </th>

                          <th
                            rowspan="1"
                            colspan="1"
                          >Conteúdo</th>
                          <th
                            rowspan="1"
                            width="80px"
                            colspan="1"
                            class="d-none d-sm-none d-md-none d-lg-table-cell"
                          >
                            Status
                          </th>
                          <th
                            rowspan="1"
                            colspan="1"
                            width="80px"
                            class="d-none d-sm-none d-md-none d-lg-table-cell"
                            v-if="!(loginObj.client_id !== null && isAdmin === 0)"
                          >
                            Responsável
                          </th>
                          <th
                            rowspan="1"
                            colspan="1"
                            width="80px"
                            class="d-none d-sm-none d-md-none d-lg-table-cell"
                            v-if="!(loginObj.client_id !== null && isAdmin === 0)"
                          >
                            Prazo
                          </th>
                          <th
                            rowspan="1"
                            colspan="1"
                            class="d-none d-sm-none d-md-none d-lg-table-cell"
                            v-if="!(loginObj.client_id !== null && isAdmin === 0)"
                          >
                            Cliente
                          </th>
                          <th
                            rowspan="1"
                            width="70px"
                            colspan="1"
                          >#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          role="row"
                          class="odd"
                          v-for="conteudo in conteudos"
                          :key="conteudo.id"
                        >
                          <td class="sorting_1 d-none">
                            <em>
                              <!-- <span class="d-none">{{ conteudo.updated_at }}</span> -->
                              <span class="d-none">{{
                                conteudo.updated_at
                              }}</span>
                              <!-- PARA ORDENAR O DTTABLE -->
                              {{ conteudo.ciclo | moment("MM/YY") }}
                              <br />
                              <small class="text-gray">#{{ conteudo.id }}</small>
                            </em>
                          </td>

                          <td v-if="!!tipoAcao">
                            <input type="checkbox" :id="conteudo.id" :value="conteudo.id" v-model="conteudo_id_array"/>
                          </td>
                          <td>

                            <p class="cursor-pointer font-weight-bold">

                              <!-- v-if="conteudo.estado.name == 'producao' && conteudo.pode_alterar_conteudo" -->
                              <router-link
                                type="button"
                                class="text-dark"
                                :to="'/conteudo/editar/' + conteudo.id"
                              >
                                {{ conteudo.titulo }}
                                <div style="display: inline-block; transform: scale(.6); transform-origin: left;" v-if="conteudo.tags">
                                  <tag v-for="tag in conteudo.tags" :key="tag.id" :tag="tag"/>
                                </div>
                                <span v-if="conteudo.nivel_reprovacao == 2" class="card-label card-label-red mod-card-front" title="Reprovado"><span class="label-text">Reprovado</span></span>
                                <span v-if="conteudo.nivel_reprovacao == 1" class="card-label card-label-yellow mod-card-front" title="Ajuste"><span class="label-text">Ajuste</span></span>
                                <span v-if="conteudo.nivel_reprovacao == 3" class="card-label card-label-yellow mod-card-front" title="ReprovadoMaisAjuste"><span class="label-text">ReprovadoMaisAjuste</span></span>
                                <span v-if="conteudo.nivel_reprovacao == 3" class="card-label card-label-red mod-card-front" title="ReprovadoMaisAjuste"><span class="label-text">ReprovadoMaisAjuste</span></span>
                              </router-link>


                              <!-- <router-link
                                type="button"
                                class="text-dark"
                                :to="'/conteudo/editar/' + conteudo.id"
                                v-else-if="conteudo.pode_visualizar_solicitacao || conteudo.pode_visualizar_conteudo &&
                                  [
                                    'revisao',
                                    'aprovacao',
                                    'producao',
                                    'publicar',
                                    'nao_publicado',
                                    'publicado',
                                  ].includes(conteudo.estado.name)"
                              >
                                {{ conteudo.titulo }}
                              </router-link> -->

                              <!-- <router-link
                                type="button"
                                class="text-dark"
                                :to="'/conteudo/solicitar/editar/' + conteudo.id"
                                v-else-if="conteudo.pode_visualizar_solicitacao && conteudo.estado.name == 'em_aberto'"
                              >{{ conteudo.titulo }}
                              </router-link> -->

                              <!-- <router-link
                                type="button"
                                class="text-dark"
                                to=""
                                v-else-if="conteudo.pode_visualizar_solicitacao == false && conteudo.pode_visualizar_conteudo == false"
                              >{{ conteudo.titulo }}
                              </router-link> -->

                              <!-- <small>#{{ conteudo.id}}</small> -->
                              <!-- <br />
                              <small>{{ conteudo.created_at | moment("DD/MM HH:mm") }}</small>-->

                              <br>
                              <small v-if="conteudo.tipo">{{ conteudo.tipo.descricao }}
                                <template v-if="conteudo.solicitacao_extra"> - Solicitação Extra</template>
                                </small>
                              <br>
                              <small>
                                {{ conteudo.ciclo | moment("MM/YY") }}
                              </small>
                            </p>

                            <p class="d-sm-block d-md-block d-lg-none">
                              <span
                                class="btn btn-sm text-light"
                                v-bind:style="{
                                  backgroundColor: conteudo.estado.cor,
                                }"
                              >{{ conteudo.estado.descricao }}</span>
                            </p>
                            <!-- 
                            <button
                              class="btn badge btn-outline-secondary mr-1 d-none"
                              title="Atendente"
                            >
                              <i class="nav-icon fas fa-user-edit"></i>
                              {{ conteudo.atendente.name }}
                            </button>

                            <button
                              class="btn badge btn-outline-secondary mb-1 mr-1 d-none "
                              title="Moderador"
                            >
                              <i class="nav-icon fas fa-user-shield d-none d-sm-none d-md-none d-lg-inline-block"></i>
                              {{ conteudo.moderador.name }}
                            </button> -->

                            <!-- <button
                              class="btn badge btn-outline-danger mb-1 mr-1 d-none d-sm-none d-md-none d-lg-inline-block"
                              title="Estourou o prazo"
                              v-if="conteudo.prazo_estourou == 1"
                            >
                              <i class="nav-icon fas fa-exclamation"></i>
                              Prazo
                            </button> -->
                          </td>

                          <td class="d-none d-sm-none d-md-none d-lg-table-cell">
                            <span
                              class="btn btn-sm text-light"
                              v-bind:style="{
                                backgroundColor: conteudo.estado.cor,
                              }"
                            >{{ conteudo.estado.descricao }}</span>
                          </td>

                          <td
                            class="d-none d-sm-none d-md-none d-lg-table-cell"
                            v-if="!(loginObj.client_id !== null && isAdmin === 0)"
                          >
                            <span
                              v-for="responsavel in conteudo.usuario_responsavel"
                              :key="responsavel.id"
                            >
                              {{responsavel.name}}
                            </span>
                          </td>
                          
                          <td
                            class="d-none d-sm-none d-md-none d-lg-table-cell"
                            v-if="!(loginObj.client_id !== null && isAdmin === 0)"
                          >
                            <span v-if="conteudo.prazo_etapa"
                              :style=" [($moment().subtract(1, 'days').isAfter(conteudo.prazo_etapa) 
                                        && (conteudo.estado.name != 'publicado') 
                                        && (conteudo.estado.name != 'nao_publicado')) ? { 'color' : 'red'} :{}]">
                              {{ conteudo.prazo_etapa | moment("DD/MM/YY") }}</span>
                          </td>

                          <td
                            class="d-none d-sm-none d-md-none d-lg-table-cell"
                            v-if="!(loginObj.client_id !== null && isAdmin === 0)"
                          >
                            {{ conteudo.cliente.nome_fantasia }}
                          </td>

                          <td class>
                            <router-link
                              type="button"
                              class="btn btn-block btn-success btn-xs m-2"
                              :to="'/conteudo/editar/' + conteudo.id"
                              v-if="conteudo.pode_alterar_conteudo"
                            >
                              detalhes
                            </router-link>

                            <!-- <router-link
                              type="button"
                              class="btn btn-block btn-outline-primary btn-xs m-2"
                              :to="'/conteudo/editar/' + conteudo.id"
                              v-if="conteudo.pode_visualizar_solicitacao && conteudo.pode_visualizar_conteudo &&
                                  [
                                    'revisao',
                                    'aprovacao',
                                    'producao',
                                    'publicar',
                                    'nao_publicado',
                                    'publicado',
                                  ].includes(conteudo.estado.name)"
                            >
                              detalhes
                            </router-link> -->

                            <router-link
                              type="button"
                              class="btn btn-block btn-outline-primary btn-xs m-2"
                              :to="'/conteudo/solicitar/editar/' + conteudo.id"
                              v-else-if="conteudo.pode_visualizar_solicitacao && conteudo.estado.name == 'em_aberto'"
                            >
                              detalhes
                            </router-link>

                            <button
                              type="button"
                              class="btn btn-block btn-outline-secondary color-secondary btn-xs m-2"
                              v-on:click.stop="showConfirm(conteudo)"
                              v-if="conteudo.pode_remover"
                            >
                              <i class="nav-icon fas fa-trash"></i>
                            </button>
                          </td>

                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>

                <!-- PAGINACAO -->
                <div class="card-footer clearfix">
                  <ul class="pagination pagination-sm m-0 float-left">
                    <li class="text-gray">Total <b>{{paginacao.total}}</b> registros em <b>{{paginacao.last_page}}</b> páginas.</li>
                  </ul>
                  <ul class="pagination pagination-sm m-0 float-right">
                    <li
                      class="mr-2 text-gray"
                      style="margin-top: 2px"
                    >Mostrando página <b>{{paginacao.current_page}}</b></li>
                    <li
                      v-for="(pagina, indx) in paginas"
                      class="paginate_button page-item"
                      v-bind:class="{ active: pagina.active }"
                      v-bind:key="indx"
                    >
                      <a
                        :title="pagina.title"
                        v-on:click="getConteudoFiltro(pagina.number)"
                        class="page-link"
                        style="cursor: pointer"
                      >{{ pagina.label }}</a>
                    </li>
                  </ul>
                </div>
                <!-- ./Paginacao -->
              </div>
            </section>

            <!-- right col -->
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>

    <footer-comp></footer-comp>
  </div>
  <!-- ./wrapper -->
</template>

<style>
.cursor-pointer {
  cursor: pointer;
}

.note-float-left,
note-float-right {
  padding: 4px;
}

@media  screen and (max-width: 1800px) {
  .min-width-fields > .sm {
    min-width: 15ch;
  }

  .min-width-fields > .md {
    min-width: 30ch;
  }

  .min-width-fields > .lg {
    min-width: 35ch;
  }
}
.card-label.mod-card-front {
    float: right;
    font-size: 12px;
    font-weight: 700;
    height: 8px;
    line-height: 100px;
    margin: 8px 4px 4px 8px;
    max-width: 20px;
    min-width: 20px;
    padding: 0;
    text-shadow: none;
    width: auto;
}

.card-label-red {
    background-color: #eb5a46;
}

.card-label-yellow {
    background-color: #f2d600;
}

.card-label {
    /* background-color: #b3bac5; */
    border-radius: 4px;
    color: #fff;
    display: block;
    margin-right: 4px;
    max-width: 100%;
    overflow: hidden;
    padding: 4px 6px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
}

.mod-card-front .label-text {
    display: none;
}
</style>

<script>
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Indicadores from '../components/Indicadores.vue';
import Tag from '../components/Tag.vue';
import TagList from '../components/TagList.vue';

axios.defaults.withCredentials = true

export default {
  name: "dashboard",
  components: {
    DatePicker,
    Indicadores,
    TagList,
    Tag,
  },
  data() {
    return {
      titulo: "Carregando lista...",
      conteudos_url: this.$apiUrl + "/conteudos",
      estados_url: this.$apiUrl + "/conteudo_estado",
      tipos_url: this.$apiUrl + "/conteudo_tipo",
      clientes_url: this.$apiUrl + "/clients",
      usuarios_url: this.$apiUrl + "/users",
      filtro: {},
      conteudos: [],
      conteudo_id_array: [],
      estados: [],
      tipos: [],
      clientes: [],
      usuarios: [],
      paginas: [],
      selecionartudo: false,
      tipoAcoes: [
        {
          id:1,
          text:"Excluir",
          value: "delete",
          buttonText: "Sim, excluir!"
        },
        // {
        //   id:2,
        //   text:"Avançar para a próxima etapa",
        //   value: "nextStep",
        // buttonText: "Sim, avançar!"
        // }
      ],
      tipoAcao: null,
      paginacao: {},
      login: {},
      loginObj: {},
      contadorAprovacao: 0,
      isAdmin: false,
      table: false,
      editar: false,
      prazo: null,
      conteudos_total: 0,
      contador_login: 0,
      loading: false,
      solicitacao_extra_restante: null,
      producoes: null,
      tags: [],
    };
  },
  methods: {
    handleCheckboxSelect() {
      if (this.selecionartudo) {
        this.conteudo_id_array = this.conteudos.map(conteudo => {
          return conteudo.id;
        })
      } else {
        this.conteudo_id_array = [];
      }
    },
    changeEstados(event) {
      this.getConteudoEstado(event.target.value);
    },
    getUsuarios() {
      axios
        .get(this.usuarios_url, this.login)
        .then(response => {
          this.usuarios = response.data;
        });
    },
    getTipos() {
      axios
        .get(this.tipos_url, this.login)
        .then(response => {
          this.tipos = response.data;
        });
    },
    getEstados() {
      axios
        .get(this.estados_url, this.login)
        .then((response) => {

          this.estados = response.data.message;
        });
    },
    getConteudoEstado(estado, pagina = 1) {
      this.loading = true;

      this.filtro.estado_id = estado;

      if (this.filtro.tipo_id == null) {
        delete this.filtro.tipo_id;
      }

      if (this.filtro.responsavel == null) {
        delete this.filtro.responsavel;
      }

      this.filtro.page = pagina;

      var par = new URLSearchParams(this.filtro).toString();

      var estado_url = this.estados_url + "/" + estado + "/conteudos" + "?" + par;

      axios
        .get(estado_url, this.login)
        .then((response) => {

          this.conteudos = response.data.message.data;

          this.paginacao = response.data.message;

          delete this.paginacao.data;

          this.loading = false;

          this.insertCampoSelecionar();

          this.pagination();

          window.location.href = "#conteudos";
        });
    },
    getClientes() {
      axios
      .get(this.clientes_url, this.login)
      .then((response) => {
        this.clientes = response.data;
      });
    },
    pagination() {
      this.paginas = [];

      var totalPaginas = this.paginacao.total / this.paginacao.per_page;
      var pagina = {};
      var i = 1;

      totalPaginas = Math.ceil(totalPaginas);

      for (i = 1; i <= totalPaginas; i++) {

        pagina = {};

        if (this.paginacao.current_page == i) {
          pagina.active = true;
        } else {
          pagina.active = false;
        }

        if (i == 1) {
          //primeira pagina
          pagina.label = "«";
          pagina.number = i;
          pagina.title = "Primeira Página";
          this.paginas.push(pagina);

        } else if (i == totalPaginas) {
          //ultima pagina
          pagina.label = "»";
          pagina.number = i;
          pagina.title = "Última Página";
          this.paginas.push(pagina);

        } else {
          // index +- 2          
          if (this.paginacao.current_page >= i - 5 && this.paginacao.current_page < i + 5) {
            pagina.label = i;
            pagina.title = "Página " + i;
            pagina.number = i;
            this.paginas.push(pagina);
          }
        }

      }
    },
    insertCampoSelecionar() {
      this.conteudos = this.conteudos.map(conteudo => {
        conteudo.selecionado = 0;
        return conteudo;
      })
    },
    getConteudoFiltro(pagina = 1, reset = false) {

      if (this.filtro.tipo_id == null) {
        delete this.filtro.tipo_id;
      }

      if (this.filtro.estado_id == null) {
        delete this.filtro.estado_id;
      }

      if (this.filtro.responsavel == null) {
        delete this.filtro.responsavel;
      }

      if (this.filtro.client_id == null) {
        delete this.filtro.client_id;
      }

      if (!this.filtro.prazo) {
        delete this.filtro.prazo;
      }

      if (reset) {
        this.filtro = {};
      }

      this.filtro.page = pagina;

      var par = new URLSearchParams(this.filtro).toString();

      let tags = this.getFiltroTags();
      if (tags) {
        tags.forEach(tag => {
          par += '&tags[]=' + tag;
        });
      }
      axios
        .get(this.conteudos_url + "?" + par, this.login)
        .then((response) => {

          this.conteudos = response.data.message.data;

          this.paginacao = response.data.message;

          delete this.paginacao.data;

          if (this.conteudos_total == 0) {
            this.conteudos_total = this.paginacao.total;
          }

          this.titulo = "Seja bem vindo(a) " + this.loginObj.name;

          this.loading = false;

          this.conteudos.forEach((conteudo) => {
            if (conteudo.cliente.id == this.loginObj.client_id && conteudo.estado.id == 5) {
              this.contadorAprovacao += 1;
            }
          });

          this.insertCampoSelecionar();

          this.pagination();
        })
        .catch(error => {
					
					this.showalert("Atenção", error, "error");
					
          if (error.response.status == 401) {
            localStorage.removeItem("login");
            this.$router.push("/login");
					}
					
        });

      this.loading = false;
    },
    editor(conteudo) {
      this.$router.push("/conteudo/editar/" + conteudo.id);
    },
    updateSolicitacao(conteudo) {
      this.$router.push("/conteudo/solicitar/editar/" + conteudo.id);
    },
    updateConteudo(conteudo) {
      this.$router.push("/conteudo/editar/" + conteudo.id);
    },
    deleteConteudo(conteudo) {
      this.loading = true;

      var t = this;

      var deleteurl = this.conteudos_url + "/" + conteudo.id;

      axios.delete(deleteurl, this.login).then((response) => {

        var _t = this;

        _t.retorno = response.data;

        if (_t.retorno.status == 200) {

          _t.showalert("Cadastro Excluído", _t.retorno.message, "success");

        } else {
					t.showalert("Atenção", response.data.message, "error");					          
        }

        _t.getConteudoFiltro();
      });
    },
    deleteConteudoMass() {
      this.loading = true;
        axios
        .delete(this.conteudos_url, {data: { conteudo_id_array: this.conteudo_id_array}})
        .then(() => {
            this.getConteudoFiltro();
            this.selecionartudo = false;
            this.conteudo_id_array = [];
            this.$fire({
              title: "Concluído", 
              text: "Ação concluida com sucesso", 
              icon: "success",
              confirmButtonText: "OK"
            });
          })
          .catch(error => {
            this.showalert("Atenção", error, "error");
          })
    },
    showConfirm(conteudo) {
      this.$fire({
        title: "Excluir",
        text: "Você tem certeza que deseja excluir " + conteudo.titulo + "?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.value) {
          this.deleteConteudo(conteudo);
        }
      });
    },
    showConfirmMass() {
      this.$fire({
        title: this.tipoAcao.text,
        text: "Você tem certeza que deseja excluir os " + this.conteudo_id_array.length + " item(s)?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: this.tipoAcao.buttonText,
        cancelButtonColor: "#d33",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.value) {
          this.deleteConteudoMass();
        }
      });
    },
    addTag(tag) {
      if( !this.tags.some((current_tag) => {
        if( current_tag.id == tag.id) return true;
      }) ) {
        this.tags.push(tag);
      }      
    },
    removeTag(tag) {
      this.tags.forEach((current_tag, i) => {
        if( current_tag.id == tag.id) this.tags.splice(i, 1);
      });
    },
    getFiltroTags() {
      let tag_ids = [];

      this.tags.forEach(tag => {
        tag_ids.push(tag.id);
      });

      return tag_ids.length > 0 ? tag_ids : null;
    }
  },
  watch: {
    prazo(newPrazo) {
      // var prazo = this.$moment(newPrazo, "DD/MM/YYYY").format("YYYY-MM-DD"); //FORMATA DATA PARA ENVIAR PARA API
      // this.filtro.prazo = prazo;
      this.filtro.prazo = newPrazo;
    }
  },  
  mounted() {
    this.contador_login = localStorage.getItem("contador");

    if (localStorage.getItem("login")) {
      try {
        const loginJSON = JSON.parse(localStorage.getItem("login"));

        this.loginObj = loginJSON;

        this.isAdmin = loginJSON.isAdmin;

        if(this.loginObj.client != null) {
          axios
          .get(this.clientes_url + '/' + this.loginObj.client_id + '/solicitacoes')
          .then(response => {
            this.solicitacao_extra_restante = parseInt(response.data.message);
          })
          .catch(error => {
            // console.error(error); //Removed for production build
           console.error(error); 
          });
        }

        //Carregar indicadores
        if( this.loginObj.client == null || (this.loginObj.client != null && this.isAdmin) ) {
          axios
          .get(this.$apiUrl + "/metrica_producoes")
          .then(response => {
            this.producoes = response.data;
          })
          .catch(error => {
            this.showalert("Atenção", error, "error");
          });
        }

        this.login = {
          headers: {
            Authorization: "Bearer ".concat(loginJSON.api_token),
            Accept: "application/json",
            "Cache-Control": "no-cache",
          },
        };

        if (
          this.loginObj.client_id !== null &&
          this.loginObj.client.avaliacao_mensal_necessaria
        ) {
          this.$router.push("/avaliacao-mensal");
        } else {
          this.getConteudoFiltro();
          this.getTipos();
          this.getEstados();
          this.getUsuarios();
          this.getClientes();
        }
      } catch (e) {
        localStorage.removeItem("login");
        this.$router.push("/login");
      }

      let _t = this;

      //Listener para submit ao apertar enter
      window.addEventListener('keypress', function (e) {
        if (e.key === 'Enter') {
          e.preventDefault();

          _t.getConteudoFiltro();
        }
      });
    } else {
      localStorage.removeItem("login");
      this.$router.push("/login");
    }
  },
  destroyed() {
    localStorage.contador = 0;
  },
};
</script>
