<template>
  <div class="hold-transition login-page">
    <div class="login-box">
      <div class="login-logo">
        <a href="index.html"> Plataforma de Conteúdos <b>E-Saúde</b> </a>
      </div>
      <!-- /.login-logo -->
      <div class="card">
        <div class="card-body login-card-body">
          <!--<p class="login-box-msg">Faça o login para iniciar</p>-->
          <form
            @submit.prevent="verificaLogin(login)"
            enctype="application/x-www-form-urlencoded"
          >
            <div class="input-group mb-3">
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                v-model="login.email"
              />

              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                type="password"
                class="form-control"
                placeholder="Senha"
                autocomplete="off"
                v-model="login.password"
              />

              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-8">
                <!-- <div class="icheck-primary">
                  <input type="checkbox" id="remember" />
                  <label for="remember">Lembrar</label>
                </div>-->
              </div>
              <!-- /.col -->
              <div class="col-4">
                <button class="btn btn-primary btn-block btn-flat">
                  Entrar
                </button>
              </div>
              <!-- /.col -->
            </div>
          </form>
          <!-- /.social-auth-links -->
          <p class="mb-1">
            <router-link to="/login/recuperar-senha"
              >Esqueci a minha senha</router-link
            >
          </p>
        </div>
        <!-- /.login-card-body -->
      </div>

      <!-- card debug -->
      <div class="card" v-if="$debugWindow">
        <div class="card-header">
          <div class="card-tools">
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="collapse"
            >
              <i class="fas fa-minus"></i>
            </button>
            <button
              type="button"
              class="btn btn-tool"
              data-card-widget="remove"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <pre>LOGIN: {{ login }}</pre>
          <pre>RETORNO: {{ retorno }}</pre>
        </div>
      </div>
      <!-- /.card debug -->
    </div>
    <!-- /.login-box -->
  </div>
</template>

<script>
import axios from "axios";

axios.defaults.withCredentials = true;

export default {
  name: "login",
  data() {
    return {
      apiurl: this.$loginUrl,
      login: {},
      retorno: {},
      user: {},
      storagelogin: {},
    };
  },
  methods: {
    verificaLogin() {
			var t = this;
      axios.get(this.apiurl + "/sanctum/csrf-cookie").then(() => {
        axios
          .post(this.apiurl + "/login", this.login, {
            withCredentials: true,
          })
          .then((result) => {            

            this.user = result.data.message.user;
            this.retorno = result.data;

            if (result.data.status == 200) {
              this.storagelogin = JSON.stringify(this.user);

              setTimeout(() => {
                // not passing any argument, resolve is defined in the upper scope.
                this.$router.push("/");
              }, 1000);
            } else {
              // const msg = Object.entries(this.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

              // if (msg.length > 0) {
              //   for (var i = 0; i < msg.length; i++) {
              //     var msgerro = msg[i][1];

              //     this.showalert("Atenção", msgerro, "warning");
              //   }
              // } else {
              //   this.retorno = result.data;

              //   this.showalert("Erro ", this.retorno, "warning");
							// }
							t.showalert("Erro ", "Usuário e/ou senha inválidos", "warning");
            }
          })
          .catch(function () {
						//console.log(error.response.data.errors);
						t.showalert("Atenção", "Usuário e/ou senha inválidos", "warning");
          });
      });
    },
  },
  watch: {
    storagelogin(newLogin) {
			
      localStorage.login = newLogin;
      localStorage.contador = 1;
    },
  },
  mounted() {
    const el = document.body;
    el.classList.add("hold-transition", "login-page");

    if (localStorage.login) {
      this.storagelogin = localStorage.login;
    }
  },
  destroyed() {
    const el = document.body;
    el.classList.remove("hold-transition", "login-page");
  },
};
</script>