<template>
  <div class="wrapper">
    <navigator></navigator>

    <sidebar></sidebar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <!-- HEADER -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Small boxes (Stat box) -->

          <!-- Main row -->
          <div class="row">
            <div class="col-md-8">
              <!-- Início conteúdo -->
              <section class="connectedSortable">
                <div class="card card-primary">
                  <div class="overlay" v-if="loading">
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>

                  <div class="card-header p-0">
                    <ul class="nav nav-this nav-tabs" id="custom-tabs-one-tab" role="tablist">
                      <li class="nav-item" v-if="conteudo.pode_visualizar_solicitacao">
                        <a
                          class="nav-link nav-this-link active"
                          id="custom-tabs-one-home-tab"
                          v-on:click="updateSolicitacao(conteudo)"
                          style="cursor: pointer;"
                          role="tab"
                          data-toggle="pill"
                          aria-controls="custom-tabs-one-home"
                          aria-selected="true"
                        >Solicitação</a>
                      </li>
                      <li class="nav-item">
                        <router-link :to='{name: "conteudo-editar", params: {id: conteudo.id, conteudoProps: conteudo}}' class='nav-link'>
                          Conteúdo
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <div class="tab-content" id="custom-tabs-one-tabContent">
                      <div
                        class="tab-pane fade active show"
                        id="custom-tabs-one-home"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-home-tab"
                      >
                        <form role="form">
                          <!-- CLIENTE -->
                          <div
                            class="form-group"
                            v-if="loginObj.client == null && conteudo.conteudo_estado_id == 2"
                          >
                            <label for="cliente">Cliente</label>
                            <v-select
                              id="cliente"
                              placeholder="Selecione o cliente"
                              label="nome_fantasia"
                              :options="clientes"
                              :reduce="clientes => clientes.id"
                              v-model="cliente"
                            ></v-select>
                          </div>

                          <!-- TITULO -->
                          <div class="form-group">
                            <label for="titulo">Título</label>
                            <input
                              id="titulo"
                              autocomplete="off"
                              type="text"
                              class="form-control"
                              v-model="conteudo.titulo"
                            />
                          </div>

                          <!-- CATEGORIA -->
                          <div
                            class="form-group"
                            v-if="loginObj.isAdmin"
                          >
                            <label for="cliente">Categoria</label>
                            <v-select
                              id="categoria"
                              placeholder="Selecione a categoria"
                              label="descricao"
                              :options="categorias"
                              :reduce="categorias => categorias.id"
                              v-model="categoria_id"
                            ></v-select>
                          </div>

                          <!-- ESTADO -->
                          <div class="form-group" v-if="loginObj.client == null">
                            <label for="projeto">Estado</label>

                            <input
                              disabled
                              id="estadodesc"
                              autocomplete="off"
                              type="text"
                              class="form-control"
                              v-model="estado.descricao"
                            />

                            <input
                              disabled
                              id="estado"
                              autocomplete="off"
                              type="hidden"
                              class="form-control"
                              v-model="conteudo.conteudo_estado_id"
                            />
                          </div>

                          <!-- PAUTA -->
                          <div class="form-group">
                            <label for="pauta">Pauta</label>
                            <textarea id="pauta" class="form-control"></textarea>
                          </div>

                          <!-- Soliticação Extra -->
                          <div v-if="loginObj.client == null" class="form-group">
                            <div class="input-group">
                              <label for="solicitacao_extra" class="mr-2">É Solicitação Extra?</label>
                              <input type="checkbox" style="height: 1.6rem;" name="solicitacao_extra" id="solicitacao_extra" v-model="conteudo.solicitacao_extra">
                            </div>
                            <transition name="slide-down">
                              <div v-if="conteudo.solicitacao_extra">
                                <label for="solicitacao_horas" class="mr-2">Quantidade de horas</label>
                                <div>
                                  <input class="form-control" style="width: 5rem !important; display:inline-block;" min="0" type="number" name="solicitacao_horas" id="solicitacao_horas" v-model="solicitacao_horas">
                                  <span v-if="moment(conteudo.ciclo).isBefore(this.$moment().startOf('month'))" class="ml-2">Este conteúdo é de um mês anterior.</span>
                                  <span v-else-if="saldo_horas  !== null && !isNaN(saldo_calculado)" class="ml-2" :class="{ 'text-danger' : saldo_calculado < 0 }">Saldo de horas: {{ saldo_calculado }}</span>
                                </div>
                              </div>
                            </transition>
                          </div>

                          <!-- CICLO -->
                          <div class="form-group" v-if="loginObj.client == null">
                            <label for="data_entrega">Ciclo do conteúdo</label>

                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="far fa-calendar-alt"></i>
                                </span>
                              </div>

                              <!-- <input
                                id="datahora_publicacao"
                                type="datetime-local"
                                step="1"
                                v-model="data_hora_publicacao"
                              />-->
                              <!-- //@change="convDataEntrega" -->
                              <input
                                id="ciclo"
                                autocomplete="off"
                                type="date"
                                class="form-control"
                                v-model="conteudo.ciclo"
                              />
                            </div>
                          </div>

                          <!-- PRAZO -->
                          <div class="form-group" v-if="loginObj.client == null">
                            <label for="prazo">Data de entrega</label>
                            <!-- v-mask="'##/##/####'" -->
                            <input
                              id="prazo"
                              autocomplete="off"
                              type="date"
                              class="form-control"
                              v-model="conteudo.prazo"
                              @change="convDataEntrega"
                            />
                          </div>

                          <!-- TIPO -->
                          <div class="form-group" v-if="loginObj.client == null">
                            <label for="tipo">Tipo</label>
                            <v-select
                              taggable
                              id="tipo"
                              placeholder="Selecione o tipo"
                              label="descricao"
                              :options="tipos"
                              :reduce="tipos => tipos.id"
                              v-model="conteudo.conteudo_tipo_id"
                            ></v-select>
                          </div>

                          <!-- MODERADOR -->
                          <div class="form-group" v-if="loginObj.client == null">
                            <label for="moderador">Moderador</label>

                            <v-select
                              taggable
                              id="moderador"
                              placeholder="Selecione o Moderador"
                              label="name"
                              :options="usuarios"
                              :reduce="usuarios => usuarios.id"
                              :selectable="user => user.deleted_at == null"
                              v-model="conteudo.moderador_id"
                            ></v-select>
                          </div>

                          <!-- ATENDENTE -->
                          <div class="form-group" v-if="loginObj.client == null">
                            <label for="atendente">Sucesso do Cliente</label>
                            <v-select
                              taggable
                              id="atendente"
                              placeholder="Selecione o Atendente"
                              label="name"
                              :options="usuarios"
                              :reduce="usuarios => usuarios.id"
                              :selectable="user => user.deleted_at == null"
                              v-model="conteudo.atendente_id"
                            ></v-select>
                          </div>

                          <!-- FUNCAO EXECUTOR -->
                          <template v-if="loginObj.client == null && conteudo.executores_funcoes">
                            <label>Executores</label>
                            <div class="row" :key="executor.id" v-for="(executor, idx) in conteudo.executores_funcoes">
                              <div class="col">
                                <!-- PRIMEIRA FUNCAO -->
                                <div class="form-group">
                                  <label for="atendente">Função {{ idx+1 }}</label>
                                  <v-select
                                    taggable
                                    id="funcao1"
                                    placeholder="Selecione a função"
                                    v-model="executor.funcao_id"
                                    label="name"
                                    :options="funcoes"
                                    :reduce="funcoes => funcoes.id"
                                    v-bind:value="funcoes.id"
                                    v-bind:key="funcoes.id"
                                  ></v-select>
                                </div>
                              </div>
                              <div class="col">
                                <!-- PRIMEIRA FUNCAO -->
                                <div class="form-group">
                                  <label for="executor1">Executor</label>
                                  <v-select
                                    taggable
                                    id="executor1"
                                    placeholder="Selecione o executor"
                                    label="name"
                                    :options="usuarios"
                                    :reduce="usuarios => usuarios.id"
                                    v-model="executor.executor_id"
                                  ></v-select>
                                </div>
                              </div>
                              <div class="col">
                                <!-- PRIMEIRA FUNCAO -->
                                <div class="form-group">
                                  <label for="prazo1">Prazo</label>
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="far fa-calendar-alt"></i>
                                      </span>
                                    </div>

                                    <input
                                      id="prazo1"
                                      autocomplete="off"
                                      type="date"
                                      class="form-control"
                                      v-model="executor.prazo"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-1">
                                <div class="form-group">
                                  <label for="executor1"></label>
                                  <button
                                    type="button"
                                    class="btn btn-block btn-outline-secondary color-secondary btn-xs m-2"
                                    v-on:click.stop="removerExecutor(idx)"
                                    v-if="conteudo.pode_remover"
                                  >
                                    <i class="nav-icon fas fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <button type="button" @click="adicionarExecutor" class="btn btn-secondary btn-lg btn-block">Adicionar executor</button>
                            </div>
                          </template>

                        </form>
                      </div>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer" v-if="conteudo.pode_alterar_solicitacao">
                    <button type="submit" v-on:click="patchConteudo()" class="btn btn-primary m-1">
                      <i class="fas fa-save"></i> Salvar
                    </button>



                    <button
                      v-if="conteudo.pode_avancar && conteudo.conteudo_estado_id <= 2"
                      type="button"
                      class="btn btn-success m-1"
                      v-on:click="patchConteudo(true)"
                    >
                      <i class="fas fa-arrow-right"></i> Avançar
                    </button>
                  </div>
                </div>
              </section>

              <!-- Final conteúdo -->
            </div>

            <div class="col-md-4">
              <!-- Inicio Informações -->
              <section class="connectedSortable">
                <div class="card">
                  <div class="card-header border-transparent">
                    <h3 class="card-title">Informações</h3>

                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                      </button>
                      <button type="button" class="btn btn-tool" data-card-widget="remove">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table m-0">
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th v-if="conteudo.usuario_responsavel">Responsável</th>
                            <th>Prazo</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span
                                class="btn text-light"
                                v-bind:style="{ backgroundColor: conteudo_estado.cor }"
                              >{{conteudo_estado.descricao}}</span>
                            </td>
                            <td v-if="conteudo.usuario_responsavel">
                              <div v-if="conteudo.estado.name != 'aprovacao'"
                                class="sparkbar"
                                data-color="#f56954"
                                data-height="20"
                              >{{conteudo.usuario_responsavel[0].name}}</div>
                              <div v-else
                                class="sparkbar"
                                data-color="#f56954"
                                data-height="20"
                              >{{conteudo.cliente.nome_fantasia}}</div>
                            </td>
                            <td>
                              <div
                                v-if="conteudo.updated_at"
                                class="sparkbar"
                                data-color="#f56954"
                                data-height="20"
                              >{{conteudo.prazo_etapa | moment("DD/MM/YY")}}</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <historico v-if="conteudo.id" :conteudo_id="conteudo.id"/>
                    </div>
                    <!-- /.table-responsive -->
                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer clearfix">
                    <!-- FOOTER -->
                  </div>
                  <!-- /.card-footer -->
                </div>
              </section>
              <!-- Final Informações -->

              <!-- Inicio chat FUNCIONARIOS -->
              <section class="connectedSortable" v-if="loginObj.client === null">
                <div class="card card-prirary cardutline direct-chat direct-chat-primary">
                  <div class="card-header">
                    <h3 class="card-title">Comentários</h3>

                    <div class="card-tools">
                      <span
                        data-toggle="tooltip"
                        title="Novas Mensagens"
                        class="badge bg-primary"
                      >{{ mensagens && mensagens.length ? mensagens.length : 0 }}</span>
                      <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-toggle="tooltip"
                        title="Contatos"
                        data-widget="chat-pane-toggle"
                      >
                        <i class="fas fa-comments"></i>
                      </button>
                    </div>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body">
                    <!-- Conversations are loaded here -->

                    <div class="direct-chat-messages" id="chat">
                      <!-- Message. Default to the left -->
                      <div
                        class="direct-chat-msg"
                        v-for="mensagem in mensagens"
                        :key="mensagem.id"
                        :class="{'right': mensagem.user.id === conteudo.atendente_id}"
                      >
                        <div class="direct-chat-infos clearfix">
                          <span class="direct-chat-name float-left">{{mensagem.user.name}}</span>
                          <span
                            class="direct-chat-timestamp float-right"
                          >{{mensagem.updated_at | moment("D MMM HH:mm")}}</span>
                        </div>
                        <!-- /.direct-chat-infos -->
                        <img
                          class="direct-chat-img"
                          src="@/assets/img/user1-128x128.jpg"
                          alt="Message User Image"
                        />
                        <!-- /.direct-chat-img -->
                        <div class="direct-chat-text">{{mensagem.mensagem}}</div>
                        <!-- /.direct-chat-text -->
                      </div>
                      <!-- /.direct-chat-msg -->
                    </div>
                    <!--/.direct-chat-messages-->

                    <!-- Contacts are loaded here -->
                    <div class="direct-chat-contacts">
                      <ul class="contacts-list">
                        <li v-if="conteudo.moderador">
                          <a href="#">
                            <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />

                            <div class="contacts-list-info">
                              <span class="contacts-list-name">{{conteudo.moderador.name}}</span>
                              <span class="contacts-list-msg">{{conteudo.moderador.email}}</span>
                            </div>
                            <!-- /.contacts-list-info -->
                          </a>
                        </li>
                        <li v-if="conteudo.atendente">
                          <a href="#">
                            <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />

                            <div class="contacts-list-info">
                              <span class="contacts-list-name">{{conteudo.atendente.name}}</span>
                              <span class="contacts-list-msg">{{conteudo.atendente.email}}</span>
                            </div>

                            <!-- /.contacts-list-info -->
                          </a>
                        </li>
                        <!-- End Contact Item -->
                      </ul>
                      <!-- /.contatcts-list -->
                    </div>
                    <!-- /.direct-chat-pane -->
                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                    <div class="input-group">
                      <input
                        type="text"
                        name="message"
                        placeholder="Messagem ..."
                        class="form-control"
                        autocomplete="off"
                        v-model="mensagem.mensagem"
                      />
                      <span class="input-group-append">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          v-on:click="postMensagem()"
                        >Enviar</button>
                      </span>
                    </div>
                  </div>
                  <!-- /.card-footer-->
                </div>
              </section>
              <!-- final chat -->

              <!-- Inicio Interações -->
              <section class="connectedSortable">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Interagindo</h3>

                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                      </button>
                      <button type="button" class="btn btn-tool" data-card-widget="remove">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body p-0">
                    <ul class="products-list product-list-in-card pl-2 pr-2">
                      <!-- /item -->
                      <li class="item" v-if="conteudo.criador">
                        <div class="product-img">
                          <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />
                        </div>
                        <div class="product-info">
                          <a
                            href="javascript:void(0)"
                            class="product-title"
                          >{{conteudo.criador.name}}</a>
                          <span class="product-description">Criador</span>
                        </div>
                      </li>
                      <!-- /.item -->

                      <!-- /item -->
                      <li class="item" v-if="conteudo.cliente">
                        <div class="product-img">
                          <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />
                        </div>
                        <div class="product-info">
                          <a
                            href="javascript:void(0)"
                            class="product-title"
                          >{{conteudo.cliente.nome_fantasia}}</a>
                          <span class="product-description">Cliente</span>
                        </div>
                      </li>
                      <!-- /.item -->

                      <!-- /item -->
                      <li class="item" v-if="conteudo.moderador">
                        <div class="product-img">
                          <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />
                        </div>
                        <div class="product-info">
                          <a
                            href="javascript:void(0)"
                            class="product-title"
                          >{{conteudo.moderador.name}}</a>
                          <span class="product-description">Moderador</span>
                        </div>
                      </li>
                      <!-- /.item -->

                      <!-- /item -->
                      <li class="item" v-if="conteudo.atendente">
                        <div class="product-img">
                          <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />
                        </div>
                        <div class="product-info">
                          <a
                            href="javascript:void(0)"
                            class="product-title"
                          >{{conteudo.atendente.name}}</a>
                          <span class="product-description">Sucesso do Cliente</span>
                        </div>
                      </li>
                      <!-- /.item -->

                      <!-- /item -->
                      <template v-if="conteudo.executores_funcoes">
                        <li class="item" :key="executor.id" v-for="executor in executores_atuais">
                          <div class="product-img">
                            <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />
                          </div>
                          <div class="product-info">
                            <a
                              href="javascript:void(0)"
                              class="product-title"
                            >{{executor.executor.name}}</a>
                            <span class="product-description">{{executor.funcao.name}}</span>
                          </div>
                        </li>
                      </template>
                      <!-- /.item -->
                    </ul>
                  </div>
                  <!-- /.card-body -->
                  <!-- /.card-footer -->
                </div>
              </section>
              <!-- Final Interações -->

              <!-- DEBUG -->
              <section class="connectedSortable" v-if="$debugWindow">
                <div class="card">
                  <div class="card-header">
                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                      </button>
                      <button type="button" class="btn btn-tool" data-card-widget="remove">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <pre>RETORNO: {{retorno}}</pre>
                    <pre>SOLICITACAO: {{conteudo}}</pre>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>

    <!-- /.content-wrapper -->
    <footer-comp></footer-comp>
  </div>
  <!-- ./wrapper -->
</template>

<script>
import axios from "axios";

axios.defaults.withCredentials = true;

import $ from "jquery";

import "jquery-ui";

import "jquery-ui-bundle";

import "summernote/dist/summernote-lite.css";

import "summernote/dist/summernote-lite.min.js";

import "summernote/dist/lang/summernote-pt-BR.js";

import Historico from "../components/ConteudoHistorico";

export default {
  name: "conteudo-solicitar-editar",
  components: {
    Historico
  },
  props: {
    conteudoProps: Object
  },
  data() {
    return {
      conteudo: {},
      conteudoObj: {},
      url: {},
      conteudo_url: this.$apiUrl + "/conteudos",
      estados_url: this.$apiUrl + "/conteudo_estado",
      tipos_url: this.$apiUrl + "/conteudo_tipo",
      clientes_url: this.$apiUrl + "/clients",
      usuarios_url: this.$apiUrl + "/users",
      funcoes_url: this.$apiUrl + "/funcoes",
      //categorias_url: this.$apiUrl + "/clients/" + this.loginObj.id + "/categorias",
      conteudo_moderador: {},
      conteudo_atendente: {},
      login: {},
      loginObj: {},
      estado: {},
      retorno: {},
      conteudo_estado: {},
      mensagem: {},
      debug: {},
      cliente: {},
      mensagens: [],
      estados: [],
      tipos: [],
      clientes: [],
      categorias: [],
      categoria_id: {},
      usuarios: [],
      funcoes: [],
      projetos: [],
      executores_atuais: [],
      data_entrega: null,
      dataEntrega: null,
      loading: false,
      solicitacao_horas: null,
      solicitacao_horas_original: null,
      saldo_horas: null
    };
  },
  computed: {
    saldo_calculado: function () {
      if(this.$moment(this.conteudo.ciclo).isBefore(this.$moment().startOf('month'))) {
        return null;
      }

      return this.saldo_horas - this.solicitacao_horas + this.solicitacao_horas_original;
    },
  },
  watch: {
    cliente(cliente) {
      this.getClienteCategorias(cliente);
      this.conteudo.client_id = cliente;

      axios
      .get(this.clientes_url + '/' + cliente + '/solicitacoes')
      .then( response => {
        this.saldo_horas = response.data.message;
      });
    },
    conteudo(conteudo) {
      this.data_entrega = this.$options.filters.frontDate(conteudo.prazo); //FORMATA A DATA PARA EXIBIR NA VIEW
    },
    solicitacao_horas(newHoras) {
      this.conteudo.solicitacao_horas = newHoras;
    },
    categoria_id(categoria_id) {
      this.conteudo.categoria_id = categoria_id;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.conteudo.isDirty && !this.confirmLeave() ) return;

    window.onbeforeunload = null;
    next();
  },
  methods: {
    getConteudo() {
      this.loading = true;
      const url = this.conteudo_url + "/" + this.$route.params.id;

      axios
        .get(url, this.login)
        .then(response => {
          this.conteudo = response.data.message;
          this.solicitacao_horas = response.data.message['solicitacao_horas'];
          this.solicitacao_horas_original = response.data.message['solicitacao_horas'];
          this.carregaConteudoConfiguracao();
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    carregaConteudoConfiguracao() {

      this.getEstado(this.conteudo.conteudo_estado_id);
      this.getConteudoEstado(this.conteudo.conteudo_estado_id);
      this.cliente = this.conteudo.client_id;
      this.getMensagens();
      this.loading = false;
      var _t = this;
      this.executores_atuais = { ...this.conteudo.executores_funcoes };

      this.solicitacao_horas = this.conteudo.solicitacao_horas;

      setTimeout(() => {
        $("#pauta").summernote({
          lang: "pt-BR",
          placeholder: "Pauta ...",
          tabsize: 2,
          height: 500,
          callbacks: {
            onChange: function() {
              if(_t.conteudo.pauta == $("#pauta").summernote('code')) return;
              _t.conteudo.isDirty = true;
              //Avisa sobre modificação no conteudo
              window.onbeforeunload = _t.confirmLeave;
            }
          },
          toolbar: [
            ["style", ["style"]],
            ["font", ["bold", "underline", "clear"]],
            ["fontname", ["fontname"]],
            ["color", ["color"]],
            ["para", ["ul", "ol", "paragraph"]],
            ["table", ["table"]],
            ["insert", ["link", "picture", "video"]],
            ["view", ["fullscreen", "codeview", "help"]]
          ]
        });

        $("#pauta").summernote('code', this.conteudo.pauta);

        this.conteudo.isDirty = false;
        window.onbeforeunload = undefined;

        if (this.conteudo.conteudo_estado_id >= 5) {
          $("#pauta").summernote("disable");
        } else if (this.conteudo.pode_alterar_solicitacao == false){
          $("#pauta").summernote("disable");
        }
      }, 1000);
    },
    getClienteCategorias(cliente) {
      if (
        typeof cliente == "undefined" &&
        typeof this.conteudo.client_id != "undefined"
      ) {
        cliente = this.conteudo.client_id;
      } else if (
        typeof cliente == "undefined" &&
        typeof this.conteudo.client_id == "undefined"
      ) {
        return false;
      }

      var _t = this;

      axios
        .get(this.clientes_url + "/" + cliente + "/categorias", this.login)
        .then(response => {
          //console.log(response.data);
          _t.categorias = response.data.message;
          _t.titulo = "Categorias";

          for(let i in response.data.message) {
            if(response.data.message[i].id == this.conteudo.categoria_id) {
              this.categoria_id = response.data.message[i].id;
              return;
            }
          }

          this.categoria_id = null;

          //_t.dataTable();
        })
        .catch(error => {
          _t.titulo = error;
        });

      //this.editar = false;
    },
    getConteudoEstado(estado) {
      const url = this.estados_url + "/" + estado;

      axios
        .get(url, this.login)
        .then(response => {
          this.conteudo_estado = response.data.message;
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    getFuncoes() {
      axios
        .get(this.funcoes_url, this.login)
        .then(response => {
          this.funcoes = response.data.message;
        })
        .catch(error => {
         console.error("Funcoes: " + error);
        });
    },
    getEstados() {
      axios
        .get(this.estados_url, this.login)
        .then(response => {
          this.estados = response.data;
        })
        .catch(error => {
         console.error("Estados: " + error);
        });
    },
    getEstado(id) {
      const url = this.estados_url + "/" + id;

      axios
        .get(url, this.login)
        .then(response => {
          ////console.log(response);
          this.estado = response.data.message;
        })
        .catch(error => {
         console.error("Estados: " + error);
        });
    },
    getTipos() {
      axios
        .get(this.tipos_url, this.login)
        .then(response => {
          this.tipos = response.data;
        })
        .catch(error => {
         console.error("Tipos: " + error);
        });
    },
    getClientes() {
      axios
        .get(this.clientes_url, this.login)
        .then(response => {
          this.clientes = response.data;
        })
        .catch(error => {
         console.error("Clientes: " + error);
        });
    },
    getUsuarios() {
      axios
        .get(this.usuarios_url + '?with_deleted=1', this.login)
        .then(response => {
          var _t = this;
          this.usuarios = response.data;

          if (typeof _t.conteudoProps === "undefined") {
            this.getConteudo();
          }
        })
        .catch(error => {
         console.error("Usuarios: " + error);
        });
    },
    getMensagens() {
      const url =
        this.conteudo_url + "/" + this.$route.params.id + "/mensagens";

      axios
        .get(url, this.login)
        .then(response => {
          this.mensagens = response.data.message;

          var _t = this;

          setTimeout(() => {
            var container = _t.$el.querySelector("#chat");
            if (typeof container != "undefined" && container != null) {
              container.scrollTop = container.scrollHeight;
            }
          }, 1000);
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    getConteudoAtendente(atendente) {
      const url = this.usuarios_url + "/" + atendente;

      axios
        .get(url, this.login)
        .then(response => {
          this.conteudo_atendente = response.data;
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    getConteudoModerador(moderador) {
      const url = this.usuarios_url + "/" + moderador;

      axios
        .get(url, this.login)
        .then(response => {
          this.conteudo_moderador = response.data;
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    postMensagem() {
      const url =
        this.conteudo_url + "/" + this.$route.params.id + "/mensagens";

      axios
        .post(url, this.mensagem, this.login)
        .then(response => {
          this.mensagem.mensagem = "";
          this.getMensagens(response); //response nao faz nada
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    patchConteudo(avancar) {
      this.conteudo.pauta = $("#pauta").summernote("code");

      this.conteudo.executor1_id = this.conteudo.executores_funcoes.executor1_id;
      this.conteudo.funcao_executor1_id = this.conteudo.executores_funcoes.funcao_executor1_id;
      this.conteudo.prazo_executor1 = this.conteudo.executores_funcoes.prazo_executor1;

      if(this.conteudo.solicitacao_extra) this.conteudo.solicitacao_horas;
      else delete this.conteudo.solicitacao_horas;

      if (this.conteudo.executor1_id === undefined) {
        this.conteudo.executor1_id = null;
      }

      this.conteudo.executor2_id = this.conteudo.executores_funcoes.executor2_id;
      this.conteudo.funcao_executor2_id = this.conteudo.executores_funcoes.funcao_executor2_id;
      this.conteudo.prazo_executor2 = this.conteudo.executores_funcoes.prazo_executor2;
      if (this.conteudo.executor2_id === undefined) {
        this.conteudo.executor2_id = null;
      }

      this.conteudo.executor3_id = this.conteudo.executores_funcoes.executor3_id;
      this.conteudo.funcao_executor3_id = this.conteudo.executores_funcoes.funcao_executor3_id;
      this.conteudo.prazo_executor3 = this.conteudo.executores_funcoes.prazo_executor3;
      if (this.conteudo.executor3_id === undefined) {
        this.conteudo.executor3_id = null;
      }

      delete this.conteudo.id;
      //delete this.conteudo.conteudo_estado_id;
      delete this.conteudo.created_at;
      delete this.conteudo.updated_at;
      if (!this.conteudo.isDirty) delete this.conteudo.conteudo;
      delete this.conteudo.retrabalho_qtd;

      delete this.conteudo.datahora_publicacao_agendada;

      delete this.conteudo.texto_nota;
      delete this.conteudo.texto_nota_observacao;
      delete this.conteudo.design_nota;
      delete this.conteudo.design_nota_observacao;
      delete this.conteudo.prazo_estourou;
      // delete this.conteudo.solicitacao_extra;
      delete this.conteudo.prazo_aprovacao;
      delete this.conteudo.possui_integracao;
      delete this.conteudo.conclusao_executor1;
      delete this.conteudo.conclusao_executor2;
      delete this.conteudo.conclusao_executor3;

      // delete this.conteudo.pode_visualizar_solicitacao;
      // delete this.conteudo.pode_alterar_solicitacao;
      // delete this.conteudo.pode_visualizar_conteudo;
      // delete this.conteudo.pode_alterar_conteudo;
      // delete this.conteudo.pode_avancar;
      // delete this.conteudo.pode_retornar;
      // delete this.conteudo.pode_remover;

      if (avancar) {
        //this.conteudo.conteudo_estado_id = moderacao;
        this.url = this.conteudo_url + "/" + this.$route.params.id + "/avancar";
      } else {
        this.url =
          this.conteudo_url +
          "/" +
          this.$route.params.id +
          "/alterar_solicitacao";
      }

      //console.log(this.url);
      this.loading = true;

      // console.log(this.conteudo); //Removed for production build

      axios.patch(this.url, this.conteudo, this.login).then(response => {
        this.retorno = response;

        //console.log(response);

        var _t = this;

        _t.retorno = response.data;

        this.loading = false;

        //ALERT - INICIO
        if (_t.retorno.status == 200) {
          _t.showalert("Sucesso", _t.retorno.message, "success");
          _t.carregaConteudoConfiguracao();
          _t.getConteudo();
          _t.getEstados();
          _t.getTipos();
          _t.getClientes();
          _t.getFuncoes();
          _t.getUsuarios();
        } else {
          if (typeof _t.retorno.message === "string") {
            _t.showalert("Atenção", _t.retorno.message, "warning");
          } else {
            const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

            for (var i = 0; i < msg.length; i++) {
              var msgerro = msg[i][1];
              _t.showalert("Atenção", msgerro, "warning");
            }
          }
        }
        //ALERT - FINAL
      })
      .catch(err => {
        console.log(err);
        this.showalert("Atenção", "Houve um erro na ação", "warning");
      });
    },
    convDataEntrega() {
      this.dataEntrega = this.data_entrega;
    },
    editor(conteudo) {
      this.$router.push("/conteudo/editar/" + conteudo.id);
    },
    moment(date) {
      if(date) return this.$moment(date);
      else return this.$moment();
    },
    adicionarExecutor() {
      this.conteudo.executores_funcoes.push({
        funcao_id: null,
        executor_id: null,
        prazo: null,
      });
    },
    removerExecutor(idx) {
      this.conteudo.executores_funcoes.splice(idx, 1);
    },
    confirmLeave() {
      return window.confirm('Realmente deseja sair? Alterações realizadas não foram salvas.');
    }
  },
  mounted() {

    if (localStorage.getItem("login")) {
      try {
        const loginJSON = JSON.parse(localStorage.getItem("login"));

        this.loginObj = loginJSON;

        this.login = {
          headers: {
            Authorization: "Bearer ".concat(loginJSON.api_token),
            Accept: "application/json",
            "Cache-Control": "no-cache"
          }
        };

        if ( this.loginObj.isAdmin === 1 ) {
          this.loginObj.client = null
        }

        if (this.conteudoProps != undefined){
          if (this.conteudoProps.id) {
           this.conteudo = this.conteudoProps;
           this.carregaConteudoConfiguracao();
          }
        }

        this.getEstados();
        this.getTipos();
        this.getClientes();
        this.getFuncoes();
        this.getUsuarios();
      } catch (e) {
        this.$router.push("/login");
        localStorage.removeItem("login");
      }
    } else {
      this.$router.push("/login");
    }
  },
  destroyed() {}
};
</script>
