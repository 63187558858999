<template>
  <div class="wrapper">
    <navigator></navigator>

    <sidebar></sidebar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <!-- HEADER -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Small boxes (Stat box) -->

          <!-- Main row -->
          <div class="row">
            <div class="col-md-8">
              <!-- Início conteúdo -->
              <section class="connectedSortable">
                <!-- TABS -->

                <div class="card card-primary">
                  <div class="card-header p-0">
                    <ul class="nav nav-this nav-tabs" id="custom-tabs-one-tab" role="tablist">
                      <li class="nav-item" v-if="conteudo.pode_visualizar_solicitacao">
                        <router-link :to='{name: "conteudo-solicitar-editar", params: {id: conteudo.id, conteudoProps: conteudo}}' class='nav-link'>
                          Solicitação
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link nav-this-link active"
                          id="custom-tabs-one-home-tab"
                          data-toggle="pill"
                          style="cursor: pointer"
                          role="tab"
                          aria-controls="custom-tabs-one-home"
                          aria-selected="true"
                        >Conteúdo</a>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <div class="tab-content" id="custom-tabs-one-tabContent">
                      <div
                        class="tab-pane fade active show"
                        id="custom-tabs-one-home"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-one-home-tab"
                      >
                        <!-- EDITOR -->
                        <div class>
                          <div class="overlay" v-if="loading">
                            <div class="lds-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>

                          <div class="card-header">
                            <input
                              v-model="conteudo.titulo"
                              class="form-control form-control-lg"
                              type="text"
                              placeholder="Título"
                            />
                            <!-- tools box -->
                            <div class="card-tools">
                              <button
                                type="button"
                                class="btn btn-tool btn-sm"
                                data-card-widget="collapse"
                                data-toggle="tooltip"
                                title="Collapse"
                              >
                                <i class="fas fa-minus"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-tool btn-sm"
                                data-card-widget="remove"
                                data-toggle="tooltip"
                                title="Remove"
                              >
                                <i class="fas fa-times"></i>
                              </button>
                            </div>
                            <!-- /. tools -->
                          </div>
                          <!-- /.card-header -->
                          <!-- CONTEUDO EDITOR -->
                          <div class="card-body pad">
                            <div class="mb-3">
                              <div id="summernote"></div>
                            </div>

                            <!-- ANTES - 19.03.20 - v-if="conteudo.pode_alterar_conteudo && data_hora_publicacao_el == false" -->
                            <div class="row">
                              <!-- ANTES: v-if="conteudo.conteudo_estado_id < 5" -->
                              <button
                                v-if="conteudo.pode_alterar_conteudo"
                                type="button"
                                class="btn btn-primary m-1"
                                v-on:click="patchConteudo('salvar')"
                              >
                                <i class="fas fa-save"></i> Salvar
                              </button>

                              <!-- ANTES: v-if="conteudo.conteudo_estado_id <= 6" -->
                              <template v-if="conteudo.pode_retornar">
                                <button
                                  v-if="loginObj.isAdmin && conteudo.estado.name == 'nao_publicado'"
                                  type="button"
                                  class="btn bg-danger m-1"
                                  v-on:click="showConfirm('Você realmente deseja retornar o estado do conteúdo?','retornar')"
                                >
                                  <i class="fas fa-arrow-left"></i> Retornar para aprovação
                                </button>
                                <button
                                  v-else-if="[
                                    'aprovacao'
                                  ].includes(conteudo.estado.name)"
                                  type="button"
                                  class="btn bg-danger m-1"
                                  v-on:click="showConfirm('Você realmente deseja retornar o estado do conteúdo?','retornar-ajustar')"
                                >
                                  <i class="fas fa-arrow-left"></i> Reprovar
                                </button>
                                <button
                                  v-else
                                  type="button"
                                  class="btn bg-danger m-1"
                                  v-on:click="showConfirm('Você realmente deseja retornar o estado do conteúdo?','retornar')"
                                >
                                  <i class="fas fa-arrow-left"></i> Reprovar
                                </button>
                              </template>

                              <!-- Ajustes -->
                              <button
                                v-if="
                                  conteudo.pode_avancar
                                  && [
                                    'aprovacao'
                                  ].includes(conteudo.estado.name)"
                                type="button"
                                class="btn m-1 ajustar-btn"
                                v-on:click="showConfirm('Você realmente deseja ajustar esse conteúdo?','ajustar')"
                              >
                                <i class="fas fa-arrow-right"></i> Aprovar com Ajustes
                              </button>

                              <!-- ANTES: v-if="conteudo.conteudo_estado_id < 5" -->
                              <button
                                v-if="
                                  conteudo.pode_avancar
                                  && [
                                    'em_aberto',
                                    'producao',
                                    'revisao',
                                    'aprovacao',
                                    'ajustar',
                                    'publicar',
                                    'nao_publicado'
                                  ].includes(conteudo.estado.name)"
                                type="button"
                                class="btn bg-success m-1"
                                v-on:click="showConfirm('Você realmente deseja avancar o estado do conteúdo?','avancar')"
                              >
                                <i class="fas fa-arrow-right"></i> {{ labelAvancar }}
                              </button>

                              <button
                                v-if="
                                  loginObj.isAdmin === 1
                                  && conteudo.estado.name != 'aprovacao'"
                                type="button"
                                class="btn btn-warning m-1"
                                v-on:click="showConfirm('Você realmente deseja retornar este conteúdo a sua última versão?','retornarVersao')"
                              >
                                <i class="fas fa-undo"></i> Retornar para última versão
                              </button>

                              <button
                                v-if="
                                  conteudo.pode_avancar
                                  && ( !loginObj.client_id || loginObj.isAdmin )
                                  && ![
                                    'aprovacao',
                                    'finalizado',
                                    'nao_publicado',
                                  ].includes(conteudo.estado.name)"
                                type="button"
                                class="btn bg-warning m-1"
                                v-on:click="conteudo.is_impedido ? desimpedir() : impedir()"
                              >
                                <i class="fas fa-ban" :class="conteudo.is_impedido ? 'fa-check' : 'fa-lock'"></i> {{ conteudo.is_impedido ? 'Retornar para executor' : 'Impedir' }}
                              </button>

                              <!-- QUANDO FOR ESTADO "APROVAR" -->
															<!-- REGRA ANTIGA:  v-if="
                                  conteudo.estado.name == 'aprovacao'
                                  && conteudo.pode_avancar
                                  && conteudo.integravel
                                " -->
                              <button
                                v-if="conteudo.pode_publicar_no_blog"
                                type="button"
                                class="btn bg-black m-1"
                                v-on:click="salvar_e_agendar(true)"
                              >
                                <i class="fas fa-calendar-alt"></i> Agendar
                              </button>

                              <!-- QUANDO FOR ESTADO "APROVAR" -->
                              <!-- v-on:click="salvar_e_agendar(true)" -->
                              <button
                                v-if="conteudo.conteudo_estado_id == 7"
                                type="button"
                                class="btn bg-success m-1"
                              >
                                <i class="fas fa-calendar-alt"></i>
                                Agendado {{data_hora_publicacao | moment("DD/MM HH:mm")}}
                              </button>
                              <!-- Esse Botão aparece ou não no estado Para Aprovar -->
                              <button
                                v-if="conteudo.pode_publicar_no_blog"
                                type="button"
                                class="btn bg-black m-1"
                                v-on:click="showConfirm('Você realmente deseja publicar o conteúdo?','publicar')"
                              >
                                <i class="fas fa-check"></i> Publicar agora no blog
                              </button>
                              <!-- Esse Botão aparece ou não no estado Para Publicar -->
                              <!--
															<button
                                v-if="conteudo.pode_publicar_no_blog && conteudo.estado.name == 'publicar'"
                                type="button"
                                class="btn bg-black m-1"
                                v-on:click="showConfirm('Você realmente deseja marcar o conteúdo como publicado?','avancar')"
                              >
                                <i class="fas fa-check"></i> Publicar agora no blog
                              </button>
															-->
                            </div>

                            <!-- PUBLICACAO -->

                            <div class="card card-primary mt-5" v-if="data_hora_publicacao_el">
                              <div class="card-header">
                                <h3 class="card-title">Agende a publicação</h3>
                              </div>
                              <div class="card-body">
                                <!-- Date and time range -->
                                <div class="form-group">
                                  <label>Data e hora da publicação:</label>

                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="far fa-calendar-alt"></i>
                                      </span>
                                    </div>

                                    <input
                                      id="datahora_publicacao"
                                      type="date"
                                      step="1"
                                      v-model="data_hora_publicacao"
                                    />
                                  </div>
                                  <!-- /.input group -->
                                </div>
                                <!-- /.form group -->
                              </div>
                              <!-- /.card-body -->

                              <div class="card-footer">
                                <button
                                  type="button"
                                  class="btn bg-black m-1"
                                  v-on:click="agendar()"
                                >
                                  <i class="fas fa-calendar-alt"></i> Agendar
                                </button>

                                <button
                                  type="button"
                                  class="btn bg-gray m-1"
                                  v-on:click="salvar_e_agendar(false)"
                                >
                                  <i class="fas fa-times-circle"></i> Cancelar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- FINAL EDITOR -->
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>

                <!-- FINAL TABS -->
              </section>

              <!-- Final conteúdo -->
            </div>

            <div class="col-md-4">
              <!-- Conteúdo Status -->

              <!-- Inicio Informações -->
              <section class="connectedSortable">
                <div class="card">
                  <div class="overlay" v-if="loading">
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>

                  <div class="card-header border-transparent">
                    <h3 class="card-title">Informações</h3>

                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                      </button>
                      <button type="button" class="btn btn-tool" data-card-widget="remove">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body p-0">
                    <div class="table-responsive">
                      <table class="table m-0">
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th v-if="conteudo.usuario_responsavel">Responsável</th>
                            <th>Prazo</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span
                                class="btn text-light"
                                v-bind:style="{ backgroundColor: conteudo_estado.cor }"
                              >{{conteudo_estado.descricao}}</span>
                            </td>
                            <td v-if="conteudo.usuario_responsavel">
                              <div v-if="conteudo.estado.name != 'aprovacao'"
                                class="sparkbar"
                                data-color="#f56954"
                                data-height="20"
                              >{{conteudo.usuario_responsavel[0].name}}</div>
                              <div v-else
                                class="sparkbar"
                                data-color="#f56954"
                                data-height="20"
                              >{{conteudo.cliente.nome_fantasia}}</div>
                            </td>
                            <td>
                              <div
                                v-if="conteudo.updated_at"
                                class="sparkbar"
                                data-color="#f56954"
                                data-height="20"
                              >{{conteudo.prazo_etapa | moment("DD/MM/YY")}}</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <historico v-if="conteudo.id" :key="loading" :conteudo_id="conteudo.id"/>
                    </div>
                    <!-- /.table-responsive -->
                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer clearfix">
                    <!-- FOOTER -->
                  </div>
                  <!-- /.card-footer -->
                </div>
              </section>
              <!-- Final Informações -->

              <!-- Inicio chat FUNCIONARIOS -->
              <section class="connectedSortable" v-if="loginObj.client === null">
                <div class="card card-prirary cardutline direct-chat direct-chat-primary">
                  <div class="card-header">
                    <h3 class="card-title">Comentários</h3>

                    <div class="card-tools">
                      <span
                        data-toggle="tooltip"
                        title="Novas Mensagens"
                        class="badge bg-primary"
                      >{{ mensagens && mensagens.length ? mensagens.length : 0 }}</span>
                      <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-toggle="tooltip"
                        title="Contatos"
                        data-widget="chat-pane-toggle"
                      >
                        <i class="fas fa-comments"></i>
                      </button>
                      <!-- <button
                      type="button"
                      class="btn btn-tool"
                      data-card-widget="remove"
                    >
                      <i class="fas fa-times"></i>
                      </button>-->
                    </div>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body">
                    <!-- Conversations are loaded here -->

                    <div class="direct-chat-messages" id="chat">
                      <!-- Message. Default to the left -->
                      <div
                        class="direct-chat-msg"
                        v-for="(mensagem, indexm) in mensagens"
                        :key="indexm"
                        :class="{'right': mensagem.user.id === conteudo.atendente_id}"
                      >
                        <div class="direct-chat-infos clearfix">
                          <span class="direct-chat-name float-left">{{mensagem.user.name}}</span>
                          <span
                            class="direct-chat-timestamp float-right"
                          >{{mensagem.updated_at | moment("D MMM HH:mm")}}</span>
                        </div>
                        <!-- /.direct-chat-infos -->
                        <img
                          class="direct-chat-img"
                          src="@/assets/img/user1-128x128.jpg"
                          alt="Message User Image"
                        />
                        <!-- /.direct-chat-img -->
                        <div class="direct-chat-text">{{mensagem.mensagem}}</div>
                        <!-- /.direct-chat-text -->
                      </div>
                      <!-- /.direct-chat-msg -->
                    </div>
                    <!--/.direct-chat-messages-->

                    <!-- Contacts are loaded here -->
                    <div class="direct-chat-contacts">
                      <ul class="contacts-list">
                        <li v-if="conteudo.moderador">
                          <a href="#">
                            <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />

                            <div class="contacts-list-info">
                              <span class="contacts-list-name">{{conteudo.moderador.name}}</span>
                              <span class="contacts-list-msg">{{conteudo.moderador.email}}</span>
                            </div>
                            <!-- /.contacts-list-info -->
                          </a>
                        </li>
                        <li v-if="conteudo.atendente">
                          <a href="#">
                            <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />

                            <div class="contacts-list-info">
                              <span class="contacts-list-name">{{conteudo.atendente.name}}</span>
                              <span class="contacts-list-msg">{{conteudo.atendente.email}}</span>
                            </div>

                            <!-- /.contacts-list-info -->
                          </a>
                        </li>
                        <!-- End Contact Item -->
                      </ul>
                      <!-- /.contatcts-list -->
                    </div>
                    <!-- /.direct-chat-pane -->
                  </div>
                  <!-- /.card-body -->
                  <div class="card-footer">
                    <div class="input-group">
                      <input
                        type="text"
                        name="message"
                        placeholder="Messagem ..."
                        class="form-control"
                        autocomplete="off"
                        v-model="mensagem.mensagem"
                      />
                      <span class="input-group-append">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          v-on:click="postMensagem()"
                        >Enviar</button>
                      </span>
                    </div>
                  </div>
                  <!-- /.card-footer-->
                </div>
              </section>
              <!-- final chat -->

              <!-- Inicio Interações -->
              <section class="connectedSortable">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Interagindo</h3>

                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                      </button>
                      <button type="button" class="btn btn-tool" data-card-widget="remove">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <!-- /.card-header -->
                  <div class="card-body p-0">
                    <ul class="products-list product-list-in-card pl-2 pr-2">
                      <!-- /item -->
                      <li class="item" v-if="conteudo.criador">
                        <div class="product-img">
                          <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />
                        </div>
                        <div class="product-info">
                          <a
                            href="javascript:void(0)"
                            class="product-title"
                          >{{conteudo.criador.name}}</a>
                          <span class="product-description">Criador</span>
                        </div>
                      </li>
                      <!-- /.item -->

                      <!-- /item -->
                      <li class="item" v-if="conteudo.cliente">
                        <div class="product-img">
                          <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />
                        </div>
                        <div class="product-info">
                          <a
                            href="javascript:void(0)"
                            class="product-title"
                          >{{conteudo.cliente.nome_fantasia}}</a>
                          <span class="product-description">Cliente</span>
                        </div>
                      </li>
                      <!-- /.item -->

                      <!-- /item -->
                      <li class="item" v-if="conteudo.moderador">
                        <div class="product-img">
                          <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />
                        </div>
                        <div class="product-info">
                          <a
                            href="javascript:void(0)"
                            class="product-title"
                          >{{conteudo.moderador.name}}</a>
                              <span class="product-description">Moderador</span>
                        </div>
                      </li>
                      <!-- /.item -->

                      <!-- /item -->
                      <li class="item" v-if="conteudo.atendente">
                        <div class="product-img">
                          <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />
                        </div>
                        <div class="product-info">
                          <a
                            href="javascript:void(0)"
                            class="product-title"
                          >{{conteudo.atendente.name}}</a>
                          <span class="product-description">Sucesso do Cliente</span>
                        </div>
                      </li>
                      <!-- /.item -->

                      <!-- /item -->
                      <template v-if="conteudo.executores_funcoes">
                        <li class="item" :key="executor.id" v-for="executor in executores_atuais">
                          <div class="product-img">
                            <img class="contacts-list-img" src="@/assets/img/user1-128x128.jpg" />
                          </div>
                          <div class="product-info">
                            <a
                              href="javascript:void(0)"
                              class="product-title"
                            >{{executor.executor.name}}</a>
                            <span class="product-description">{{executor.funcao.name}}</span>
                          </div>
                        </li>
                      </template>
                      <!-- /.item -->
                    </ul>
                  </div>
                  <!-- /.card-body -->
                  <!-- /.card-footer -->
                </div>
              </section>
              <!-- Final Interações -->
            </div>
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>

			<!-- <section>
				<pre>
					{{conteudo}}
				</pre>
			</section> -->

      <!-- MODAL RETORNO FUNCAO -->
      <div class="modal fade" id="retornar-funcao">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title">{{ tituloReprovacao }}</h2>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>{{ mensagemReprovacao }}</p>
              <div
                class="form-group"
                v-for="(nome_funcao, index) in nome_funcoes"
                :key="index"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    :id="index"
                    :value="nome_funcao.id"
                    @change="reprovar(nome_funcao.id, $event)"
                  />
                  <label :for="index" class="custom-control-label">{{nome_funcao.name}}</label>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <!-- <button type="button" class="btn btn-default" data-dismiss="modal">Close</button> -->
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="patchConteudo(controle)"
              >Informar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- ./MODAL RETORNO -->

      <!-- MODAL -->
      <div class="modal fade" id="avaliacao-texto">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Avalie o conteúdo - Texto</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>Qual a sua avaliação sobre o texto?</label>
                <div class="clearfix">
                  <div class="rate">
                    <input type="radio" id="star5" name="rate" value="5" v-model="avaliacao_texto" />
                    <label for="star5" title="text">5</label>
                    <input type="radio" id="star4" name="rate" value="4" v-model="avaliacao_texto" />
                    <label for="star4" title="text">4</label>
                    <input type="radio" id="star3" name="rate" value="3" v-model="avaliacao_texto" />
                    <label for="star3" title="text">3</label>
                    <input type="radio" id="star2" name="rate" value="2" v-model="avaliacao_texto" />
                    <label for="star2" title="text">2</label>
                    <input type="radio" id="star1" name="rate" value="1" v-model="avaliacao_texto" />
                    <label for="star1" title="text">1</label>
                  </div>
                </div>
              </div>
              <!-- Comentário -->
              <div class="form-group">
                <label for="comentarios_texto" v-if="avaliacao_texto > 3">Comentários:</label>
                <label
                  for="comentarios_texto"
                  v-if="avaliacao_texto <= 3"
                >Comentários (informe para avançar):</label>
                <input
                  id="comentarios_texto"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  v-model="avaliacao_texto_comentarios"
                />
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <!-- <button type="button" class="btn btn-default" data-dismiss="modal">Close</button> -->
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="patchConteudo(controle)"
                v-if="avaliacao_texto >= 1"
              >Próximo</button>

              <button
                type="button"
                class="btn btn-secondary"
                v-on:click="patchConteudo(controle)"
								v-if="avaliacao_texto == 0"
              >Próximo</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- ./MODAL -->

      <!-- MODAL -->
      <div class="modal fade" id="avaliacao-design">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Avalie o conteúdo - Design</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>Qual a sua avaliação sobre o design?</label>
                <div class="clearfix">
                  <div class="rate">
                    <input
                      type="radio"
                      id="astar5"
                      name="rate"
                      value="5"
                      v-model="avaliacao_design"
                    />
                    <label for="astar5" title="text">5</label>
                    <input
                      type="radio"
                      id="astar4"
                      name="rate"
                      value="4"
                      v-model="avaliacao_design"
                    />
                    <label for="astar4" title="text">4</label>
                    <input
                      type="radio"
                      id="astar3"
                      name="rate"
                      value="3"
                      v-model="avaliacao_design"
                    />
                    <label for="astar3" title="text">3</label>
                    <input
                      type="radio"
                      id="astar2"
                      name="rate"
                      value="2"
                      v-model="avaliacao_design"
                    />
                    <label for="astar2" title="text">2</label>
                    <input
                      type="radio"
                      id="astar1"
                      name="rate"
                      value="1"
                      v-model="avaliacao_design"
                    />
                    <label for="astar1" title="text">1</label>
                  </div>
                </div>
              </div>
              <!-- Comentário -->
              <div class="form-group">
                <label for="comentarios_design" v-if="avaliacao_design > 3">Comentários:</label>
                <label
                  for="comentarios_design"
                  v-if="avaliacao_design <= 3"
                >Comentários (informe para avançar):</label>
                <input
                  id="comentarios_design"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  v-model="avaliacao_design_comentarios"
                />
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <!-- <button type="button" class="btn btn-default" data-dismiss="modal">Close</button> -->

              <button
                type="button"
                class="btn btn-primary"
                v-on:click="patchConteudo(controle)"
                v-if="avaliacao_design >= 1"
              >Próximo</button>

              <button
                type="button"
                class="btn btn-secondary"
                v-on:click="patchConteudo(controle)"
                v-if="avaliacao_design == 0"
              >Próximo</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- ./MODAL -->

		 <!-- MODAL -->
      <div class="modal fade" id="avaliacao-estrategia">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Avalie o conteúdo - estrategia</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>Qual a sua avaliação sobre a estrategia?</label>
                <div class="clearfix">
                  <div class="rate">
                    <input
                      type="radio"
                      id="estar5"
                      name="rate"
                      value="5"
                      v-model="avaliacao_estrategia"
                    />
                    <label for="estar5" title="text">5</label>
                    <input
                      type="radio"
                      id="estar4"
                      name="rate"
                      value="4"
                      v-model="avaliacao_estrategia"
                    />
                    <label for="estar4" title="text">4</label>
                    <input
                      type="radio"
                      id="estar3"
                      name="rate"
                      value="3"
                      v-model="avaliacao_estrategia"
                    />
                    <label for="estar3" title="text">3</label>
                    <input
                      type="radio"
                      id="estar2"
                      name="rate"
                      value="2"
                      v-model="avaliacao_estrategia"
                    />
                    <label for="estar2" title="text">2</label>
                    <input
                      type="radio"
                      id="estar1"
                      name="rate"
                      value="1"
                      v-model="avaliacao_estrategia"
                    />
                    <label for="estar1" title="text">1</label>
                  </div>
                </div>
              </div>
              <!-- Comentário -->
              <div class="form-group">
                <label for="comentarios_estrategia" v-if="avaliacao_estrategia > 3">Comentários:</label>
                <label
                  for="comentarios_estrategia"
                  v-if="avaliacao_estrategia <= 3"
                >Comentários (informe para avançar):</label>
                <input
                  id="comentarios_estrategia"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  v-model="avaliacao_estrategia_comentarios"
                />
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <!-- <button type="button" class="btn btn-default" data-dismiss="modal">Close</button> -->

              <button
                type="button"
                class="btn btn-primary"
                v-on:click="patchConteudo(controle)"
                v-if="avaliacao_estrategia >= 1"
              >Próximo</button>

              <button
                type="button"
                class="btn btn-secondary"
                v-on:click="patchConteudo(controle)"
                v-if="avaliacao_estrategia == 0"
              >Próximo</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- ./MODAL -->

      <!-- MODAL MOTIVO RETORNO -->
      <div class="modal fade" id="motivo-retorno">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Motivo Retorno</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- Comentário -->
              <div class="form-group">
                <label for="motivo_retorno">Informe o motivo para fazer o retorno:</label>
                <input
                  id="motivo_retorno"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  v-model="motivo_retorno"
                />
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button
                type="button"
                class="btn btn-primary"
                v-on:click="patchConteudo(controle)"
              >Retornar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- MODAL MOTIVO RETORNO -->
      <div class="modal fade" id="motivo-impedimento">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Motivo do Impedimento</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- Comentário -->
              <div class="form-group">
                <label for="motivo_impedimento">Informe o motivo do impedimento</label>
                <input
                  id="motivo_impedimento"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  v-model="motivo_impedimento"
                />
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-default" data-dismiss="modal">Cancelar</button>
              <button
                type="button"
                class="btn btn-primary"
                :class="{ 'disabled': motivo_impedimento.length < 5 }"
                v-on:click="impedir"
              >Retornar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- ./MODAL -->
      <div class="modal fade" id="ultimo-conteudo">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Conteúdo anterior a este:</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <p v-html="conteudo_ultimo_historico.conteudo">
                  {{conteudo_ultimo_historico.conteudo}}
                </p>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-danger"
                v-on:click="fechaModal('ultimo-conteudo')">
              Fechar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>

    <!-- /.content-wrapper -->
    <footer-comp></footer-comp>
  </div>
  <!-- ./wrapper -->
</template>

<style>
.nav-this,
.nav-this-link {
  background-color: #efefef;
}
.rate {
  float: left;
  height: 46px;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.ajustar-btn{
  color: white;
  background-color: rgb(255,111,0);
}

.ajustar-btn:hover, .ajustar-btn:active, .ajustar-btn:visited{
  color: white;
  background-color: rgb(220, 95, 0);
}

</style>

<script>
import $ from "jquery";

import "jquery-ui";

import "jquery-ui-bundle";

import "summernote/dist/summernote-lite.css";

import "summernote/dist/summernote-lite.min.js";

import "summernote/dist/lang/summernote-pt-BR.js";

import Historico from "../components/ConteudoHistorico"

import axios from "axios";

axios.defaults.withCredentials = true;

export default {
  name: "conteudo-editar",
  props: {
    conteudoProps: Object
  },
  components: {
    Historico
  },
  data() {
    return {
      login: {},
      loginObj: {},
      url: {},
      data_hora_publicacao_el: false,
      data_hora_publicacao: "",
      controle: null,
      conteudo: {
        estado: {
          name: '',
        },
        integravel: false,
      },
      conteudoObj: {
        integrar: false,
      },
      conteudo_estado: {},
      conteudo_moderador: {},
      conteudo_atendente: {},
      motivo_retorno: "",
      avaliacao_texto: 0,
			avaliacao_design: 0,
			avaliacao_estrategia: 0,
      avaliacao_texto_comentarios: "",
			avaliacao_design_comentarios: "",
			avaliacao_estrategia_comentarios: "",
      nome_funcoes: [],
      retornar_funcao1: 0,
      retornar_funcao2: 0,
      retornar_funcao3: 0,
      mensagem: {},
      mensagens: [],
      funcoes: [],
      estados: [],
      tipos: [],
      clientes: [],
      usuarios: [],
      projetos: [],
      datahora_publicacao: null,
      //dataPublicacao: null,
      data_entrega: null,
      dataEntrega: null,
      debug: {},
      funcoes_url: this.$apiUrl + "/funcoes",
      usuarios_url: this.$apiUrl + "/users",
      estados_url: this.$apiUrl + "/conteudo_estado",
      conteudo_url: this.$apiUrl + "/conteudos",
      tipos_url: this.$apiUrl + "/conteudo_tipo",
      clientes_url: this.$apiUrl + "/clients",
      loading: false,
      solicitacao_editar_url: '/conteudo/solicitar/editar/',
      labelAvancar: 'Avançar',
      conteudo_ultimo_historico: {
        conteudo: ''
      },
      tituloReprovacao: 'Quais funções estão reprovadas?',
      mensagemReprovacao: 'Informe as funções que deverão ser reprovadas para voltar ao fluxo de produção.',
      conteudoInicial: null,
      executores_atuais: [],
      funcoes_reprovadas: [],
      motivo_impedimento: '',
    };
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeRouteLeave(to, from, next) {
    if(this.conteudo.isDirty && !this.confirmLeave()) return;

    window.onbeforeunload = null;
    next();
  },
  methods: {
    getConteudoTipo() {
      const url = this.tipos_url + "/" + this.conteudo.conteudo_tipo_id;

      axios
        .get(url, this.login)
        .then(response => {
          this.conteudo_tipo = response.data;
        })
        .catch(error => {
         console.error("Tipos: " + error);
        });
    },
    getTipos() {
      axios
        .get(this.tipos_url, this.login)
        .then(response => {
          this.tipos = response.data;
        })
        .catch(error => {
         console.error("Tipos: " + error);
        });
    },
    getClientes() {
      axios
        .get(this.clientes_url, this.login)
        .then(response => {
          this.clientes = response.data;
        })
        .catch(error => {
         console.error("Clientes: " + error);
        });
    },
    getUsuarios() {
      axios
        .get(this.usuarios_url, this.login)
        .then(response => {
          this.usuarios = response.data;
        })
        .catch(error => {
         console.error("Usuarios: " + error);
        });
    },
    getFuncoes() {
      axios
        .get(this.funcoes_url, this.login)
        .then(response => {
          this.funcoes = response.data.message;

          let executores = [...this.conteudo.executores_funcoes];

          let executores_funcoes_id = [];
          executores.map(executor => executores_funcoes_id.push(executor.funcao_id));

          if(this.conteudo.estado.name == 'revisao') {
            this.nome_funcoes = this.funcoes.filter(funcao => {
              return executores_funcoes_id.includes(funcao.id) && ['Texto', 'Design', 'Estratégia', 'Revisor'].includes(funcao.name);
            });

            return;
          }

          this.nome_funcoes = this.funcoes.filter(funcao => {
            return executores_funcoes_id.includes(funcao.id) && ['Texto', 'Design', 'Estratégia'].includes(funcao.name);
          });
        })
        .catch(error => {
         console.error("Funcoes: " + error);
        });
    },
    getConteudo() {
      this.loading = true;

      const url = this.conteudo_url + "/" + this.$route.params.id + "?v=" + Math.random();

      this.debugLog(url, "FRONT");

      axios
        .get(url, this.login)
        .then(response => {
          this.conteudo = response.data.message;
          this.conteudoInicial = response.data.message.conteudo;

          if(typeof this.conteudo != "string" ) this.conteudo.isDirty = false;
          else {
            this.showalert("Atenção", this.conteudo, "warning");
            this.$router.push('/');
            return;
          }

					this.carregaConteudoConfiguracao();

					//console.log(this.conteudo);
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    carregaConteudoConfiguracao() {
      this.data_hora_publicacao = this.conteudo.datahora_publicacao_agendada;
      this.executores_atuais = { ...this.conteudo.executores_funcoes };

      var _t = this;

      $("#summernote").summernote({
        lang: "pt-BR",
        placeholder: "Conteúdo ...",
        tabsize: 2,
				height: 600,
        callbacks: {
          onChange: function() {
            if(_t.conteudo.conteudo == $("#summernote").summernote('code')) return;
            _t.conteudo.isDirty = true;
            //Avisa sobre modificação no conteudo
            window.onbeforeunload = _t.confirmLeave;
          }
        },
        toolbar: [
          ["style", ["style"]],
          ["font", ["italic", "bold", "underline", "clear"]],
          ["fontname", ["fontname"]],
          ["color", ["color"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["table", ["table"]],
          ["insert", ["link", "picture", "video"]],
          ["view", ["fullscreen"]]
        ]
			});


      $("#summernote").summernote('code', this.conteudo.conteudo);

			setTimeout( function(){
				$("img").addClass("img-fluid").removeAttr("width").removeAttr("height"); //SUMMERNOTE
				$("img").css({"max-width":"100%", "height": "auto", });
				$("img").parent("span").parent("font").parent("span").removeAttr("style");

                                //Não sei pq ocorre, mas vamos settar dirty como falso pq o programa que altera
                                _t.conteudo.isDirty = false;
                                window.onbeforeunload = null;
			}, 1000);

      if (this.loginObj.client && !this.conteudo.pode_alterar_conteudo) {
        //SE FOR CLIENTE
        $("#summernote").summernote("disable");
      }

      this.getConteudoEstado(this.conteudo.conteudo_estado_id);

      this.getTipos();
      this.getClientes();
      this.getUsuarios();
      this.getFuncoes();

      delete this.conteudo.datahora_publicacao_agendada;
      delete this.conteudoObj.retornar_funcao2;
      delete this.conteudoObj.retornar_funcao3;
      delete this.conteudoObj.retornar_funcao1;

      this.getConteudoTipo();

      this.debugLog(this.conteudo, "API");

      this.solicitacao_editar_url = this.solicitacao_editar_url + this.conteudo.id;

      if (['publicar','nao_publicado'].includes(this.conteudo.estado.name))
      {
        this.labelAvancar = 'Marcar como Publicado';
      } else if (this.conteudo.estado.name == 'aprovacao')
      {
        this.labelAvancar = 'Aprovar';
      } else {
        this.labelAvancar = 'Avançar';
      }
      this.loading = false;
    },
    convDataEntrega() {
      this.dataEntrega = this.data_entrega;
    },
    patchConteudo(estado) {
      this.controle = estado;
      this.btnAvancar = false;

      if (this.loginObj.client_id == this.conteudo.client_id && ['aprovacao', 'nao_publicado'].includes(this.conteudo.estado.name) && !['salvar', 'retornar', 'retornar-ajustar'].includes(estado)) {

        if (this.conteudo.pode_avaliar_texto && this.avaliacao_texto == 0) {
          $("#avaliacao-texto").modal("show");
          return false;
        } else {
          $("#avaliacao-texto").modal("hide");
        }
        // -1 é caso não exista dentro do vetor de avaliação
        if (this.conteudo.pode_avaliar_design && this.avaliacao_design == 0) {
          $("#avaliacao-design").modal("show");
          return false;
        } else {
          $("#avaliacao-design").modal("hide");
				}

				 // -1 é caso não exista dentro do vetor de avaliação
        if (this.conteudo.pode_avaliar_estrategia && this.avaliacao_estrategia == 0) {
          $("#avaliacao-estrategia").modal("show");
          return false;
        } else {
          $("#avaliacao-estrategia").modal("hide");
        }

        this.conteudoObj.texto_nota = this.avaliacao_texto;
        this.conteudoObj.texto_nota_observacao = this.avaliacao_texto_comentarios;

        this.conteudoObj.design_nota = this.avaliacao_design;
				this.conteudoObj.design_nota_observacao = this.avaliacao_design_comentarios;

				this.conteudoObj.estrategia_nota = this.avaliacao_estrategia;
        this.conteudoObj.estrategia_nota_observacao = this.avaliacao_estrategia_comentarios;
      }
      //FINAL SE FOR CONTEÚDO EM AVALIACAO CHAMA O FORM DE AVAL.

      //Aprovar com ajustes
      if (['aprovacao'].includes(this.conteudo.estado.name) && this.controle == "ajustar") {

        this.tituloReprovacao = 'Quais funções devem ser ajustadas?';
        this.mensagemReprovacao = 'Informe as funções que deverão ser ajustadas para voltar ao fluxo de produção.';

        if ( this.funcoes_reprovadas.length == 0 ) {
          $("#retornar-funcao").modal("show");
          //$('#myModal').modal('hide')
          this.loading = false;
          return false;
        } else {
          $("#retornar-funcao").modal("hide");
        }

        this.conteudoObj.funcoes_reprovadas = this.funcoes_reprovadas;

        //Avaliacao: (refuse if is admin not client)
        if ((!this.loginObj.isAdmin) || (this.loginObj.client_id == this.conteudo.client_id)){

          if (this.conteudo.pode_avaliar_texto && this.avaliacao_texto == 0) {
            $("#avaliacao-texto").modal("show");
            return false;
          } else {
            $("#avaliacao-texto").modal("hide");
          }
          // -1 é caso não exista dentro do vetor de avaliação
          if (this.conteudo.pode_avaliar_design && this.avaliacao_design == 0) {
            $("#avaliacao-design").modal("show");
            return false;
          } else {
            $("#avaliacao-design").modal("hide");
          }

          // -1 é caso não exista dentro do vetor de avaliação
          if (this.conteudo.pode_avaliar_estrategia && this.avaliacao_estrategia == 0) {
            $("#avaliacao-estrategia").modal("show");
            return false;
          } else {
            $("#avaliacao-estrategia").modal("hide");
          }

          this.conteudoObj.texto_nota = this.avaliacao_texto;
          this.conteudoObj.texto_nota_observacao = this.avaliacao_texto_comentarios;

          this.conteudoObj.design_nota = this.avaliacao_design;
          this.conteudoObj.design_nota_observacao = this.avaliacao_design_comentarios;

          this.conteudoObj.estrategia_nota = this.avaliacao_estrategia;
          this.conteudoObj.estrategia_nota_observacao = this.avaliacao_estrategia_comentarios;
        }
      }


      //Reprovar com ajustes
      if (['aprovacao'].includes(this.conteudo.estado.name) && this.controle == "retornar-ajustar") {

        this.tituloReprovacao = 'Quais funções estão reprovadas?';
        this.mensagemReprovacao = 'Informe as funções que deverão ser ajustadas para voltar ao fluxo de produção.';

        if ( this.funcoes_reprovadas.length == 0 ) {
          $("#retornar-funcao").modal("show");
          //$('#myModal').modal('hide')
          this.loading = false;
          return false;
        } else {
          $("#retornar-funcao").modal("hide");
        }

        this.conteudoObj.funcoes_reprovadas = this.funcoes_reprovadas;

      }

      /// SE FOR CONTEÚDO EM AVALIACAO E RETORNAR.
      // This case doesn't exists anymore
      if (['aprovacao'].includes(this.conteudo.estado.name) && ['ajustar', 'retornar', 'retornar-ajustar'].includes(estado)) {

        if (this.motivo_retorno.length < 5) {
          $("#motivo-retorno").modal("show");
          //$('#myModal').modal('hide')
          this.loading = false;
          return false;
        } else {
          $("#motivo-retorno").modal("hide");
        }

        this.conteudoObj.motivo_retorno = this.motivo_retorno;
      }
      // FINAL SE FOR CONTEÚDO EM AVALIACAO E RETORNAR.

      if (estado == "ajustar"){
          this.conteudo.nivel_reprovacao = 1;
      }else if (estado == "retornar-ajustar"){
          this.conteudo.nivel_reprovacao = 2;
          estado = "ajustar";
          this.controle = estado;
      }


      //REPROVACAO ** SE FOR CONTEÚDO EM REVISAO E VOLTAR O ESTADO
      if (['revisao'].includes(this.conteudo.estado.name) && this.controle == "retornar") {

        this.tituloReprovacao = 'Quais funções estão reprovadas?';
        this.mensagemReprovacao = 'Informe as funções que deverão ser reprovadas para voltar ao fluxo de produção.';

        if ( this.funcoes_reprovadas.length == 0 ) {
          $("#retornar-funcao").modal("show");
          //$('#myModal').modal('hide')
          this.loading = false;
          return false;
        } else {
          $("#retornar-funcao").modal("hide");
        }

        this.conteudoObj.funcoes_reprovadas = this.funcoes_reprovadas;
      }

      //./REPROVACAO ** SE FOR CONTEÚDO EM REVISAO E VOLTAR O ESTADO

      /*
      var datapub = this.$moment(this.data_hora_publicacao).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      this.conteudo.datahora_publicacao_agendada = datapub;
      */

      switch (this.controle) {
        case "agendar":
          this.url = this.conteudo_url + "/" + this.$route.params.id + "/avancar";
          this.conteudoObj.integrar = true;
          break;
        case "publicar":
          this.url = this.conteudo_url + "/" + this.$route.params.id + "/avancar";
          delete this.conteudo.datahora_publicacao_agendada;
          this.conteudoObj.integrar = true;
          break;
        case "avancar":
          this.url = this.conteudo_url + "/" + this.$route.params.id + "/avancar";
          break;
        case "retornar":
          this.url =
            this.conteudo_url + "/" + this.$route.params.id + "/retornar";
          break;
        case "ajustar":
          this.url =
            this.conteudo_url + "/" + this.$route.params.id + "/ajustar";
          break;
        case "salvar":
          this.url =
            this.conteudo_url +
            "/" +
            this.$route.params.id +
            "/alterar_conteudo";
          break;
        default:
          this.url =
            this.conteudo_url +
            "/" +
            this.$route.params.id +
            "/alterar_conteudo";
      }

      this.loading = true;

      this.conteudoObj.titulo = this.conteudo.titulo;

      //ADD conteudo info.
      /*
      atendente_id: ["O campo atendente id é obrigatório."]
      client_id: ["O campo client id é obrigatório."]
      conteudo_tipo_id: ["O campo conteudo tipo id é obrigatório."]
      executor1_id: ["É obrigatório o preenchimento de ao menos 1 executor, com sua função."]
      moderador_id: ["O campo moderador id é obrigatório."]
      pauta: ["O campo pauta é obrigatório."]
      */
      this.conteudoObj.atendente_id = this.conteudo.atendente_id;
      this.conteudoObj.client_id = this.conteudo.client_id;
      this.conteudoObj.conteudo_tipo_id = this.conteudo.conteudo_tipo_id;

      //Extra fields to save all required fields in Solicitacao
      this.conteudoObj.prazo = this.conteudo.prazo;
      this.conteudoObj.solicitacao_extra = this.conteudo.solicitacao_extra;
      if (this.conteudoObj.solicitacao_extra) this.conteudoObj.solicitacao_horas = this.conteudo.solicitacao_horas;
      this.conteudoObj.categoria_id = this.conteudo.categoria_id;

      this.conteudoObj.moderador_id = this.conteudo.moderador_id;
      this.conteudoObj.pauta = this.conteudo.pauta;

      this.conteudoObj.nivel_reprovacao = this.conteudo.nivel_reprovacao;


      /* Se não houve mudança no conteúdo
      não incluímos o conteúdo na requisição */
      if(this.conteudo.isDirty) {
        this.conteudoObj.conteudo = $("#summernote").summernote("code");
      }

      this.debugLog(this.url, "FRONT");

      this.debugLog(this.conteudoObj, "FRONT");


      axios.patch(this.url, this.conteudoObj, this.login).then(response => {
        this.retorno = response;

        this.debugLog(this.retorno, "API");

        var _t = this;

        _t.retorno = response.data;

        $("#avaliacao-texto").modal("hide");
        $("#avaliacao-design").modal("hide");

        this.btnAvancar = true;

        this.loading = false;

        //Get response Message (old and new forms):
        let retorno_message;
        if (_t.retorno.message != null)
          retorno_message = _t.retorno.message;
        else
          retorno_message = _t.retorno;

        //ALERT - INICIO
        if ((_t.retorno.status == 200) ||
        ((_t.retorno.status == null && response.status == 200))) {
          window.onbeforeunload = null;
          /// AVISO AVALIAÇÔES
          if (_t.conteudo.conteudo_estado_id === 5 && this.controle == "avancar") {
            var nota_geral =
              parseInt(_t.conteudoObj.texto_nota) +
              parseInt(_t.conteudoObj.design_nota) + parseInt(_t.conteudoObj.estrategia_nota);


            if (nota_geral == 5 || nota_geral == 10) {
              _t.showalert(
                "Woohoo!!!",
                " Que bom que você amou nosso conteúdo. Um grande abraço e obrigado pela confiança ;)",
                "success"
              );
            } else {
              _t.showalert(
                "OK",
                "Feedback registrado com sucesso! Obrigada pelo retorno.",
                "success"
              );
            }
          } else {
              _t.showalert("Sucesso", retorno_message, "success");
          }

          // ./AVISO AVALIACOES


          _t.getConteudo();
        } else {
          if (typeof retorno_message === "string") {
            _t.showalert("Atenção", retorno_message, "warning");

            _t.getConteudo();
          } else {
            const msg = Object.entries(retorno_message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

            for (var i = 0; i < msg.length; i++) {
              var msgerro = msg[i][1];
              _t.showalert("Atenção", msgerro, "warning");
              this.debugLog(msgerro, "API");
            }
          }
        }

        //ALERT - FINAL
      })
      .catch(err => {
        console.error(err);
        this.showalert("Atenção", "Houve um erro na ação", "warning");
      });

    },
    getConteudoAtendente(atendente) {
      const url = this.usuarios_url + "/" + atendente;

      axios
        .get(url, this.login)
        .then(response => {
          this.conteudo_atendente = response.data;
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    getConteudoModerador(moderador) {
      const url = this.usuarios_url + "/" + moderador;

      axios
        .get(url, this.login)
        .then(response => {
          this.conteudo_moderador = response.data;
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    getConteudoEstado(estado) {
      const url = this.estados_url + "/" + estado;

      axios
        .get(url, this.login)
        .then(response => {
          this.conteudo_estado = response.data.message;
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    getMensagens() {
      const url =
        this.conteudo_url + "/" + this.$route.params.id + "/mensagens";

      axios
        .get(url, this.login)
        .then(response => {
          this.mensagens = response.data.message;

          var _t = this;

          setTimeout(() => {
            var container = _t.$el.querySelector("#chat");
            if (typeof container != "undefined" && container != null) {
              container.scrollTop = container.scrollHeight;
            }
          }, 1000);
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    postMensagem() {
      const url =
        this.conteudo_url + "/" + this.$route.params.id + "/mensagens";

      axios
        .post(url, this.mensagem, this.login)
        .then(response => {
          this.mensagem.mensagem = "";
          this.getMensagens(response); //response nao faz nada
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    reprovar(funcao_id, evento) {
      var _e = evento.srcElement.checked;

      if (_e) {
        this.funcoes_reprovadas.push(funcao_id);
      }
    },
    salvar_e_agendar(action) {
      this.data_hora_publicacao_el = action;

      if (action == false) {
        delete this.conteudo.datahora_publicacao_agendada;
      } else {
        setTimeout(() => {
          $("#datahora_publicacao").focus();
        }, 500);
      }
    },
    agendar() {
      var datapub = this.$moment(this.data_hora_publicacao).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      if (datapub == "Invalid date") {
        this.showalert(
          "Atenção",
          "Data de publicação inválida. Informe a data e a hora",
          "warning"
        );
      } else {
        this.conteudo.datahora_publicacao_agendada = datapub;
        this.conteudoObj.datahora_publicacao_agendada = datapub;

        this.patchConteudo("agendar");
      }
    },
    updateSolicitacao(conteudo) {
      this.$router.push("/conteudo/solicitar/editar/" + conteudo.id);
    },
    showConfirm(msg, estado) {

      this.controle = estado;

      // Comentado para revisão posterior, tirar?
      // if(estado === 'retornar') {
      //   this.getConteudo()
      // }

      this.$fire({
        title: "Atenção",
        text: msg,
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Sim!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não"
      }).then(result => {
        if (result.value) {
          if (estado == 'retornarVersao') {
            this.retornaConteudoVersao()
          } else {
            this.patchConteudo(estado);
          }
        }
      });
    },
    debugLog(mensagem, sender) {
      this.debug = {};

      this.debug.mensagem = mensagem;
      this.debug.sender = sender;
      this.debug.time = this.$moment().format();
    },
    retornaConteudoVersao() {
      const url = `${this.$apiUrl}/conteudos/${this.conteudo.id}/historico/ultimo/retornar`;

      axios
        .get(url, this.login)
        .then(response => {
          if (response) {
            window.location.reload()
          }
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    mostraConteudoHistoricoUltimo() {

      if (this.conteudo_ultimo_historico.conteudo) {
        $("#ultimo-conteudo").modal("show");
        return false;
      }

      const url = `${this.$apiUrl}/conteudos/${this.conteudo.id}/historico/ultimo`;
      axios
        .get(url, this.login)
        .then(response => {
          if (response) {
            this.conteudo_ultimo_historico.conteudo = response.data.message.conteudo_backup
            $("#ultimo-conteudo").modal("show");
          }
        })
        .catch(error => {
         console.error("Conteudo: " + error);
        });
    },
    fechaModal(id) {
      $(`#${id}`).modal("hide")
    },
    impedir() {

      if (this.motivo_impedimento.length < 5) {
          $("#motivo-impedimento").modal("show");
          //$('#myModal').modal('hide')
          this.loading = false;
          return false;
        } else {
          $("#motivo-impedimento").modal("hide");
        }

      this.loading = true;

      this.conteudoObj.titulo = this.conteudo.titulo;

      //ADD conteudo info.
      /*
      atendente_id: ["O campo atendente id é obrigatório."]
      client_id: ["O campo client id é obrigatório."]
      conteudo_tipo_id: ["O campo conteudo tipo id é obrigatório."]
      executor1_id: ["É obrigatório o preenchimento de ao menos 1 executor, com sua função."]
      moderador_id: ["O campo moderador id é obrigatório."]
      pauta: ["O campo pauta é obrigatório."]
      */
      this.conteudoObj.atendente_id = this.conteudo.atendente_id;
      this.conteudoObj.client_id = this.conteudo.client_id;
      this.conteudoObj.conteudo_tipo_id = this.conteudo.conteudo_tipo_id;

      //Extra fields to save all required fields in Solicitacao
      this.conteudoObj.prazo = this.conteudo.prazo;
      this.conteudoObj.solicitacao_extra = this.conteudo.solicitacao_extra;
      if (this.conteudoObj.solicitacao_extra) this.conteudoObj.solicitacao_horas = this.conteudo.solicitacao_horas;
      this.conteudoObj.categoria_id = this.conteudo.categoria_id;

      this.conteudoObj.moderador_id = this.conteudo.moderador_id;
      this.conteudoObj.pauta = this.conteudo.pauta;

      this.conteudoObj.nivel_reprovacao = this.conteudo.nivel_reprovacao;


      /* Se não houve mudança no conteúdo
      não incluímos o conteúdo na requisição */
      if(this.conteudo.isDirty) {
        this.conteudoObj.conteudo = $("#summernote").summernote("code");
      }

      axios
      .patch(`${this.$apiUrl}/conteudos/${this.conteudo.id}/alterar_conteudo`, this.conteudoObj, this.login)
      .then( () => {

        axios
        .patch(`${this.$apiUrl}/conteudos/${this.conteudo.id}/impedir`, { motivo_impedimento: this.motivo_impedimento })
        .then( () => {
          this.getConteudo();
          this.getMensagens();
        })
        .catch( error => {
          console.error(error);
        });
      })
      .catch( error => {
        console.error(error);
      });
    },
    desimpedir() {

      this.loading = true;

      this.conteudoObj.titulo = this.conteudo.titulo;

      //ADD conteudo info.
      /*
      atendente_id: ["O campo atendente id é obrigatório."]
      client_id: ["O campo client id é obrigatório."]
      conteudo_tipo_id: ["O campo conteudo tipo id é obrigatório."]
      executor1_id: ["É obrigatório o preenchimento de ao menos 1 executor, com sua função."]
      moderador_id: ["O campo moderador id é obrigatório."]
      pauta: ["O campo pauta é obrigatório."]
      */
      this.conteudoObj.atendente_id = this.conteudo.atendente_id;
      this.conteudoObj.client_id = this.conteudo.client_id;
      this.conteudoObj.conteudo_tipo_id = this.conteudo.conteudo_tipo_id;

      //Extra fields to save all required fields in Solicitacao
      this.conteudoObj.prazo = this.conteudo.prazo;
      this.conteudoObj.solicitacao_extra = this.conteudo.solicitacao_extra;
      if (this.conteudoObj.solicitacao_extra) this.conteudoObj.solicitacao_horas = this.conteudo.solicitacao_horas;
      this.conteudoObj.categoria_id = this.conteudo.categoria_id;

      this.conteudoObj.moderador_id = this.conteudo.moderador_id;
      this.conteudoObj.pauta = this.conteudo.pauta;

      this.conteudoObj.nivel_reprovacao = this.conteudo.nivel_reprovacao;


      /* Se não houve mudança no conteúdo
      não incluímos o conteúdo na requisição */
      if(this.conteudo.isDirty) {
        this.conteudoObj.conteudo = $("#summernote").summernote("code");
      }

      axios
      .patch(`${this.$apiUrl}/conteudos/${this.conteudo.id}/alterar_conteudo`, this.conteudoObj, this.login)
      .then( () => {

        axios
        .patch(`${this.$apiUrl}/conteudos/${this.conteudo.id}/desimpedir`)
        .then( () => {
          this.getConteudo();
          this.getMensagens();
        })
        .catch( error => {
          console.error(error);
        });

      })
      .catch( error => {
        console.error(error);
      });
    },
    confirmLeave() {
      return window.confirm('Realmente deseja sair? Alterações realizadas não foram salvas.');
    }
  },
  watch: {
    conteudo(conteudo) {
      this.data_entrega = this.$options.filters.frontDate(conteudo.prazo); //FORMATA A DATA PARA EXIBIR NA VIEW
    },
    dataEntrega(newDate) {
      var prazo = this.$moment(newDate, "DD/MM/YYYY").format("YYYY-MM-DD"); //FORMATA DATA PARA ENVIAR PARA API
      this.conteudo.prazo = prazo;
    }
  },
  mounted() {

    if (localStorage.getItem("login")) {
      try {
        const loginJSON = JSON.parse(localStorage.getItem("login"));

        this.loginObj = loginJSON;

        this.login = {
          headers: {
            Accept: "application/json",
            "Cache-Control": "no-cache"
          }
        };

        if ( this.loginObj.isAdmin === 1 ) {
          this.loginObj.client = null
        }

        if (typeof this.conteudoProps !== "undefined"){
          if (this.conteudoProps.id) {
            this.conteudo = this.conteudoProps;
            this.conteudoInicial = this.conteudoProps.conteudo;
            this.carregaConteudoConfiguracao();
          }
        }
        else {
          this.getConteudo();
        }

      } catch (e) {
        this.$router.push("/login");
        localStorage.removeItem("login");
      }
    } else {
      this.$router.push("/login");
    }

    /*MONTED*/
    this.getMensagens();
  },
  destroyed() {}
};
</script>
