<template>
	<Relatorio>
		<!-- right col (We are only adding the ID to make the widgets sortable)-->
		<section class="col-lg-12 connectedSortable">
			<div class="card">
				<div class="card-header">
					<h3>Acompanhamento dos Projetos</h3>
					<div class="card-tools">
						<!-- Maximize Button -->
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
					<!-- /.card-tools -->
				</div>

				<!-- /.card-header -->
				<div class="card-body">
					<div class="row">
						<div class="col">
							<!-- CLIENTE -->
							<div cass="form-group">
								<label for="cliente">Cliente</label>
								<v-select
									id="cliente"
									placeholder="Selecione o Cliente"
									label="nome_fantasia"
									:options="clientes"
									:reduce="(clientes) => clientes.id"
									v-model="filtro.client_id"
									v-on:change="selClientes($event)"
									multiple
								></v-select>
							</div>
						</div>

						<div class="col">
							<!-- MODERADOR -->
							<div class="form-group">
								<label for="responsavel">Responsável</label>
								<v-select
									taggable
									id="responsavel"
									placeholder="Selecione o Responsável"
									label="name"
									:options="usuarios"
									:reduce="(usuarios) => usuarios.id"
									v-model="filtro.responsavel_id"
									multiple
								></v-select>
							</div>
						</div>
						<div
							class="col"
							style="align-self: center; transform: translate(0%, 20%);"
						>
							<button
								v-on:click="getMetricasProducoes()"
								id="btnn"
								type="button"
								class="btn btn-info form-control"
							>
								PESQUISAR
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- Overall result section -->
		<section class="col-lg-12 connectedSortable">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<indicadores v-if="producoes" v-bind="producoes" />
		</section>
		<!-- Overall result section -->
		<section class="col-lg-12 connectedSortable">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<h3>Em andamento</h3>
					<div class="card-tools">
						<!-- Maximize Button -->
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
					<!-- /.card-tools -->
				</div>

				<div v-if="producoes" class="card-body">
					<div
						v-if="producoes"
						class="d-flex flex-row justify-content-around"
					>
						<div class="card col">
							<div
								class="card-header d-flex flex-row justify-content-center"
							>
								<h3>ABERTO</h3>
							</div>
							<div
								class="card-body flex-row justify-content-center"
							>
								<h3 class="text-center">
									{{
										parseInt(
											(producoes.conteudos_aberto * 100) /
												totalConteudos,
										) || 0
									}}%
								</h3>
								<p class="text-center">
									{{ producoes.conteudos_aberto }} de
									{{ totalConteudos }}
								</p>
							</div>
						</div>
						<div class="card col">
							<div
								class="card-header d-flex flex-row justify-content-center"
							>
								<h3>PRODUÇÃO</h3>
							</div>
							<div
								class="card-body flex-row justify-content-center"
							>
								<h3 class="text-center">
									{{
										parseInt(
											(producoes.conteudos_producao *
												100) /
												totalConteudos,
										) || 0
									}}%
								</h3>
								<p class="text-center">
									{{ producoes.conteudos_producao }} de
									{{ totalConteudos }}
								</p>
							</div>
						</div>
						<div class="card col">
							<div
								class="card-header d-flex flex-row justify-content-center"
							>
								<h3>REVISÃO</h3>
							</div>
							<div
								class="card-body flex-row justify-content-center"
							>
								<h3 class="text-center">
									{{
										parseInt(
											(producoes.conteudos_revisao *
												100) /
												totalConteudos,
										) || 0
									}}%
								</h3>
								<p class="text-center">
									{{ producoes.conteudos_revisao }} de
									{{ totalConteudos }}
								</p>
							</div>
						</div>
						<div class="card col">
							<div
								class="card-header d-flex flex-row justify-content-center"
							>
								<h3>AJUSTES</h3>
							</div>
							<div
								class="card-body flex-row justify-content-center"
							>
								<h3 class="text-center">
									{{
										parseInt(
											(producoes.conteudos_ajustes *
												100) /
												totalConteudos,
										) || 0
									}}%
								</h3>
								<p class="text-center">
									{{ producoes.conteudos_ajustes }} de
									{{ totalConteudos }}
								</p>
							</div>
						</div>
						<div class="card col">
							<div
								class="card-header d-flex flex-row justify-content-center"
							>
								<h3>PUBLICAR</h3>
							</div>
							<div
								class="card-body flex-row justify-content-center"
							>
								<h3 class="text-center">
									{{
										parseInt(
											(producoes.conteudos_publicar *
												100) /
												totalConteudos,
										) || 0
									}}%
								</h3>
								<p class="text-center">
									{{ producoes.conteudos_publicar }} de
									{{ totalConteudos }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- Overall result section -->
		<section class="col-lg-12 connectedSortable">
			<div class="overlay" v-if="loading">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div class="card">
				<div class="card-header">
					<h3>Entregas</h3>
					<div class="card-tools">
						<!-- Maximize Button -->
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="maximize"
						>
							<i class="fas fa-expand"></i>
						</button>
					</div>
					<!-- /.card-tools -->
				</div>

				<div id="chart" class="card-body">
					<!-- CHART -->
					<apexchart
						type="bar"
						height="350"
						:options="chartOptions"
						:series="series"
					></apexchart>
				</div>
			</div>
		</section>
	</Relatorio>
</template>
<style>
.cursor-pointer {
	cursor: pointer;
}
.note-float-left,
note-float-right {
	padding: 4px;
}
</style>
<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import Indicadores from "../../components/Indicadores.vue";
import VueApexCharts from "vue-apexcharts";
import Relatorio from "../../components/Relatorio.vue";

export default {
	name: "relatorio-metricas-producoes",
	components: {
		Indicadores,
		apexchart: VueApexCharts,
		Relatorio,
	},
	data() {
		return {
			titulo: "Carregando lista...",
			usuarios_url: this.$apiUrl + "/users",
			clientes_url: this.$apiUrl + "/clients",
			avaliacao_mensal_url: this.$apiUrl + "/metrica_producoes",
			tipos_url: this.$apiUrl + "/conteudo_tipo",
			login: {},
			loginObj: {},
			filtro: {
				periodo_ate: this.$moment().format("YYYY-MM-DD"),
				periodo_de: this.$moment().format("YYYY-MM-01"),
			},
			relatorio: [],
			clientes: [],
			usuarios: [],
			tipos: [],
			isAdmin: false,
			table: false,
			loading: false,
			producoes: null,
			// Chart options
			series: [
				{
					name: "Finalizados",
					data: [], // y values for Finalizados
				},
				{
					name: "Em aprovação",
					data: [],
				},
				{
					name: "Em produção",
					data: [],
				},
			],
			chartOptions: {
				chart: {
					type: "bar",
					height: 350,
					stacked: true,
					toolbar: {
						show: true,
					},
					zoom: {
						enabled: true,
					},
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								position: "bottom",
								offsetX: -10,
								offsetY: 0,
							},
						},
					},
				],
				plotOptions: {
					bar: {
						horizontal: false,
						borderRadius: 10,
					},
				},
				xaxis: {
					type: "text",
					categories: [], //x axis names "[mai jun jul ago...]"
				},
				legend: {
					position: "right",
					offsetY: 40,
				},
				fill: {
					opacity: 1,
				},
			},
			// end of chart options
		};
	},
	methods: {
		getMetricasProducoes() {
			this.loading = true;
			axios
				.get(this.avaliacao_mensal_url, { params: this.filtro })
				.then((response) => {
					this.producoes = response.data;
					this.dataTable();
					this.renderChart();
					this.loading = false;
				})
				.catch((error) => {
					this.showalert("Atenção", error, "error");
				});
		},
		getUsuarios() {
			axios
				.get(this.usuarios_url, this.login)
				.then((response) => {
					this.usuarios = response.data;
				})
				.catch((error) => {
					this.titulo = error;
				});
		},
		getClientes() {
			axios
				.get(this.clientes_url + "?include_deleted=1", this.login)
				.then((response) => {
					//console.log(response.data);

					this.clientes = response.data;
				})
				.catch((error) => {
					this.titulo = error;
				});

			this.editar = false;
		},

		dataTable() {
			var _e = this;

			if (_e.table != false) {
				_e.table.destroy();

				setTimeout(() => {
					_e.renderTable();
				}, 100);
			} else {
				setTimeout(() => {
					_e.renderTable();
				}, 100);
			}
		},
		renderChart() {
			let c_series = [];
			this.series[0].data = [];
			this.series[1].data = [];
			this.series[2].data = [];
			for (var mes of this.producoes.entregas_por_mes) {
				this.series[0].data.push(mes.finalizados);
				this.series[1].data.push(mes.aprovacao);
				this.series[2].data.push(mes.producao);
				c_series.push(String(mes.mes));
				this.chartOptions = {
					...this.chartOptions,
					...{
						xaxis: {
							type: "text",
							categories: c_series,
						},
					},
				};

				console.log(mes);
			}
			console.log(this.producoes.entregas_por_mes);
			console.log(this.series);
			console.log(this.chartOptions);
		},
		renderTable() {
			this.table = $("#tblRelatorio").DataTable({
				paging: true,
				bInfo: true,
				bLengthChange: false,
				searching: true,
				order: [[0, "desc"]],
				language: {
					sEmptyTable: "Pesquisando registros...",
					sInfo:
						"Mostrando de _START_ até _END_ de _TOTAL_ registros",
					sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
					sInfoFiltered: "(Filtrados de _MAX_ registros)",
					sInfoPostFix: "",
					sInfoThousands: ".",
					sLengthMenu: "_MENU_ resultados por página",
					sLoadingRecords: "Carregando...",
					sProcessing: "Processando...",
					sZeroRecords: "Nenhum registro encontrado",
					sSearch: "Pesquisar",
					oPaginate: {
						sNext: "Próximo",
						sPrevious: "Anterior",
						sFirst: "Primeiro",
						sLast: "Último",
					},
					oAria: {
						sSortAscending: ": Ordenar colunas de forma ascendente",
						sSortDescending:
							": Ordenar colunas de forma descendente",
					},
					select: {
						rows: {
							_: "Selecionado %d linhas",
							"0": "Nenhuma linha selecionada",
							"1": "Selecionado 1 linha",
						},
					},
				},
			});
		},
	},
	computed: {
		totalConteudos: function() {
			return (
				this.producoes.conteudos_aberto +
				this.producoes.conteudos_producao +
				this.producoes.conteudos_revisao +
				this.producoes.conteudos_ajustes +
				this.producoes.conteudos_publicar
			);
		},
	},
	mounted() {
		this.contador_login = localStorage.getItem("contador");

		if (localStorage.getItem("login")) {
			try {
				const loginJSON = JSON.parse(localStorage.getItem("login"));

				this.loginObj = loginJSON;

				this.isAdmin = loginJSON.isAdmin;

				this.login = {
					headers: {
						Authorization: "Bearer ".concat(loginJSON.api_token),
						Accept: "application/json",
						"Cache-Control": "no-cache",
					},
				};

				setTimeout(() => {
					this.getMetricasProducoes();
					this.getClientes();
					this.getUsuarios();
				}, 300);
			} catch (e) {
				localStorage.removeItem("login");
				this.$router.push("/login");
			}
		} else {
			localStorage.removeItem("login");
			this.$router.push("/login");
		}
	},
	destroyed() {
		localStorage.contador = 0;
	},
};
</script>
