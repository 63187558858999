<template>
  <div class="wrapper">
    <navigator></navigator>

    <sidebar></sidebar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <!-- HEADER -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Small boxes (Stat box) -->

          <!-- Main row -->
          <div class="row">
            <section class="col-lg-5 connectedSortable">
              <!-- card editar -->
              <div class="card card-info" v-if="editar">
                <div class="card-header">
                  <h3 class="card-title">Editar</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->

                <form role="form" @submit.prevent="updateContrato(contrato)">
                  <!-- FORM CONTENT -->
                  <div class="card-body">
                    <!-- Cliente -->
                    <div class="form-group">
                      <label for="cliente">Cliente:</label>
                      <v-select
                        taggable
                        id="cliente"
                        placeholder="Selecione o cliente"
                        label="nome_fantasia"
                        :options="clientes"
                        :reduce="clientes => clientes.id"
                        v-model="contrato.client_id"
                      ></v-select>
                    </div>

                    <!-- TIPO -->
                    <div class="form-group">
                      <label for="tipo">Conteúdo tipo:</label>
                      <v-select
                        taggable
                        id="tipo"
                        placeholder="Selecione o tipo"
                        label="descricao"
                        :options="tipos"
                        :reduce="tipos => tipos.id"
                        v-model="contrato.conteudo_tipo_id"
                      ></v-select>
                    </div>

                    <!-- PERIODICIDADE MES -->
                    <div class="form-group">
                      <label for="periodicidade">Periodicidade (mês):</label>
                      <input
                        type="number"
                        class="form-control"
                        id="periodicidade"
                        placeholder="Periodicidade"
                        v-model="contrato.periodicidade_meses"
                      />
                    </div>

                    <!-- QUANTIDADE -->
                    <div class="form-group">
                      <label for="quantidade">Quantidade:</label>
                      <input
                        type="number"
                        class="form-control"
                        id="quantidade"
                        placeholder="Quantidade"
                        v-model="contrato.quantidade"
                      />
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button class="btn btn-info">Atualizar</button>
                    <button class="btn btn-secondary ml-2" v-on:click="updateCancel()">Cancelar</button>
                  </div>
                </form>
              </div>
              <!-- /.card -->

              <!-- card adicionar -->
              <div class="card card-info" v-else>
                <div class="card-header">
                  <h3 class="card-title">Adicionar Contrato</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form role="form" @submit.prevent="postContrato()">
                  <!-- FORM CONTENT -->
                  <div class="card-body">
                    <!-- Cliente -->
                    <div class="form-group">
                      <label for="cliente">Cliente:</label>
                      <v-select
                        taggable
                        id="cliente"
                        placeholder="Selecione o cliente"
                        label="nome_fantasia"
                        :options="clientes"
                        :reduce="clientes => clientes.id"
                        v-model="contrato.client_id"
                      ></v-select>
                    </div>

                    <!-- TIPO -->
                    <div class="form-group">
                      <label for="tipo">Conteúdo tipo:</label>
                      <v-select
                        taggable
                        id="tipo"
                        placeholder="Selecione o tipo"
                        label="descricao"
                        :options="tipos"
                        :reduce="tipos => tipos.id"
                        v-model="contrato.conteudo_tipo_id"
                      ></v-select>
                    </div>

                    <!-- PERIODICIDADE MES -->
                    <div class="form-group">
                      <label for="periodicidade">Periodicidade (mês):</label>
                      <input
                        type="number"
                        class="form-control"
                        id="periodicidade"
                        placeholder="Periodicidade"
                        v-model="contrato.periodicidade_meses"
                      />
                    </div>

                    <!-- QUANTIDADE -->
                    <div class="form-group">
                      <label for="quantidade">Quantidade:</label>
                      <input
                        type="number"
                        class="form-control"
                        id="quantidade"
                        placeholder="Quantidade"
                        v-model="contrato.quantidade"
                      />
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button class="btn btn-info">Salvar</button>
                  </div>
                </form>
              </div>
              <!-- /.card -->

              <!-- card debug -->
              <div class="card" v-if="$debugWindow">
                <div class="card-header">
                  <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                      <i class="fas fa-minus"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <pre>POST:{{ contrato }}</pre>
                  <pre>RESPONSE:{{ retorno }}</pre>
                </div>
              </div>
              <!-- /.card debug -->
            </section>

            <section class="col-lg-7 connectedSortable">
              <div class="card card-info">
                <div class="overlay" v-if="loading">
                  <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>

                <div class="card-header">
                  <!--       <h3 class="card-title">Contratos</h3>
                  &nbsp;-->
                  <h3 class="card-title">{{ titulo }}</h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive">
                  <table
                    id="tblContratos"
                    class="table table-bordered table-striped dataTable"
                    role="grid"
                    aria-describedby="example1_info"
                  >
                    <thead>
                      <tr role="row">
                        <th rowspan="1" colspan="1">Contrato</th>
                        <th rowspan="1" colspan="1">Cliente</th>
                        <th rowspan="1" colspan="1">Tipo</th>
                        <th rowspan="1" colspan="1">Quantidade</th>
                        <th rowspan="1" colspan="1">Periodicidade</th>
                        <th rowspan="1" colspan="1">Update</th>
                        <th rowspan="1" colspan="1">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        role="row"
                        class="odd"
                        v-for="(contrato, index) in contratos"
                        :key="index"
                      >
                        <td class="sorting_1 text-center">{{ contrato.id }}</td>
                        <td class="sorting_1 text-center">{{ contrato.client.nome_fantasia }}</td>
                        <td class>{{ contrato.conteudo_tipo.descricao }}</td>
                        <td>{{ contrato.quantidade }}</td>
                        <td>{{ contrato.periodicidade_meses }}</td>
                        <td class>
                          <span class="d-none">{{ contrato.updated_at}}</span>
                          {{ contrato.updated_at | moment("D/MM HH:mm") }}
                        </td>
                        <td class>
                          <button
                            type="button"
                            class="btn btn-block btn-outline-danger btn-xs"
                            v-on:click.stop="showConfirm(contrato)"
                          >excluir</button>
                          <button
                            type="button"
                            class="btn btn-block btn-outline-primary btn-xs"
                            v-on:click="updateSelContrato(contrato)"
                          >editar</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- /.card -->
            </section>
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>

    <!-- /.content-wrapper -->
    <footer-comp></footer-comp>
  </div>
  <!-- ./wrapper -->
</template>

<style>
.dataTables_filter input {
  border: 1px solid #ccc;
  margin-bottom: 10px !important;
}
table.dataTable {
  border-collapse: collapse;
  margin-bottom: 10px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #efefef !important;
}
</style>

<script>
import axios from "axios";

import $ from "jquery";

import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";

import "jquery-ui";
import "jquery-ui-bundle";

export default {
  name: "contratos",
  data() {
    return {
      contratos_url: this.$apiUrl + "/cliente_contrato",
      clientes_url: this.$apiUrl + "/clients",
      tipos_url: this.$apiUrl + "/conteudo_tipo",
      titulo: "Carregando lista...",
      retorno: {},
      table: false, //dataTable
      contratos: [],
      contrato: {},
      tipos: [],
      clientes: [],
      login: {},
      editar: false,
      loading: false,
    };
  },
  methods: {
    getClienteContratos() {
      this.loading = true;
      //const url = this.contratos_url + "/" +  this.$route.params.id;
      const url = this.contratos_url;

      axios
        .get(url, this.login)
        .then(response => {
          this.contratos = response.data.message;
          this.titulo = "Contratos";
          this.dataTable();
          this.loading = false;
        })
        .catch(error => {
          this.titulo = error;
        });
    },
    getTipos() {
      axios
        .get(this.tipos_url, this.login)
        .then(response => {
          this.tipos = response.data;
        })
        .catch(error => {
         console.error("Tipos: " + error);
        });
    },
    getClientes() {
      axios
        .get(this.clientes_url, this.login)
        .then(response => {
          //console.log(response.data);
          this.clientes = response.data;
        })
        .catch(error => {
          this.titulo = error;
        });
    },
    postContrato() {
      this.loading = true;
      axios
        .post(this.contratos_url, this.contrato, this.login)
        .then(response => {
          var _t = this;

          _t.retorno = response.data;

          //ALERT
          if (_t.retorno.status == 201) {
            _t.getClienteContratos();

            this.contrato = {};

            this.loading = false;

            _t.showalert("Sucesso", _t.retorno.message, "success");
          } else {
            const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

            for (var i = 0; i < msg.length; i++) {
              var msgerro = msg[i][1];

              _t.showalert("Atenção", msgerro, "warning");
            }
          }
          //FINAL ALERT
        });
    },
    updateContrato(contrato) {
      this.loading = true;
      var updateUrl = this.contratos_url + "/" + contrato.id;

      axios.patch(updateUrl, contrato, this.login).then(response => {
        var _t = this;

        _t.retorno = response.data;
        this.loading = false;

        if (_t.retorno.status == 201) {
          _t.getClienteContratos();

          _t.showalert("Cadastro Atualizado", _t.retorno.message, "success");
        } else {
          const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

          for (var i = 0; i < msg.length; i++) {
            var msgerro = msg[i][1];

            _t.showalert("Atenção", msgerro, "error");
          }
        }
      });
    },
    updateSelContrato(contrato) {
      this.editar = true;
      this.contrato = contrato;
    },
    updateCancel() {
      this.editar = false;
      this.contrato = {};
    },
    deleteContrato(contrato) {
      var deleteurl = this.contratos_url + "/" + contrato.id;

      axios.delete(deleteurl, this.login).then(response => {
        var _t = this;

        _t.retorno = response.data;

        if (_t.retorno.status == 200) {
          _t.getClienteContratos();

          _t.showalert("Cadastro Excluído", _t.retorno.message, "success");
        } else {
          const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

          for (var i = 0; i < msg.length; i++) {
            var msgerro = msg[i][1];

            _t.showalert("Atenção", msgerro, "error");
          }
        }
      });
    },
    showConfirm(contrato) {
      this.$fire({
        title: "Excluir",
        text: "Você tem certeza que deseja excluir " + contrato.id + "?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Sim, excluir!",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não"
      }).then(result => {
        if (result.value) {
          this.deleteContrato(contrato);
        }
      });
    },
    dataTable() {
      var _e = this;

      if (_e.table != false) {
        _e.table.destroy();

        setTimeout(function() {
          _e.renderTable();
        }, 300);
      } else {
        setTimeout(function() {
          _e.renderTable();
        }, 300);
      }
    },
    renderTable() {
      this.table = $("#tblContratos").DataTable({
        paging: true,
        bInfo: true,
        bLengthChange: false,
        searching: true,
        language: {
          sEmptyTable: "Nenhum registro encontrado",
          sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
          sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
          sInfoFiltered: "(Filtrados de _MAX_ registros)",
          sInfoPostFix: "",
          sInfoThousands: ".",
          sLengthMenu: "_MENU_ resultados por página",
          sLoadingRecords: "Carregando...",
          sProcessing: "Processando...",
          sZeroRecords: "Nenhum registro encontrado",
          sSearch: "Pesquisar",
          oPaginate: {
            sNext: "Próximo",
            sPrevious: "Anterior",
            sFirst: "Primeiro",
            sLast: "Último"
          },
          oAria: {
            sSortAscending: ": Ordenar colunas de forma ascendente",
            sSortDescending: ": Ordenar colunas de forma descendente"
          },
          select: {
            rows: {
              _: "Selecionado %d linhas",
              "0": "Nenhuma linha selecionada",
              "1": "Selecionado 1 linha"
            }
          }
        }
      });
    }
  },
  mounted() {
    if (localStorage.getItem("login")) {
      try {
        const loginJSON = JSON.parse(localStorage.getItem("login"));

        this.login = {
          headers: {
            Authorization: "Bearer ".concat(loginJSON.api_token),
            Accept: "application/json",
            "Cache-Control": "no-cache"
          }
        };

        this.getClienteContratos();
        this.getClientes();
        this.getTipos();
      } catch (e) {
        this.$router.push("/login");
        localStorage.removeItem("login");
      }
    } else {
      this.$router.push("/login");
    }
  },
  destroyed() {}
};
</script>
