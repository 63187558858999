import Vue from "vue";

import Router from "vue-router";

import Dashboard from "@/views/Dashboard";

import Usuarios from "@/views/Usuarios";

import Clientes from "@/views/Clientes";

import ClienteCategorias from "@/views/ClienteCategorias";

import Contratos from "@/views/Contratos";

import ConteudoEditar from "@/views/ConteudoEditar";

import ConteudoSolicitarEditar from "@/views/ConteudoSolicitarEditar";

import ConteudoSolicitar from "@/views/ConteudoSolicitar";

import Login from "@/views/Login";

import RecuperarSenha from "@/views/RecuperarSenha";

import AvaliacaoMensal from "@/views/AvaliacaoMensal";

import RelatorioAvaliacao from "@/views/relatorios/RelatorioAvaliacao";

import RelatorioMetricasAvaliacoes from "@/views/relatorios/RelatorioMetricasAvaliacoes";

import RelatorioMetricasAprovacoes from "@/views/relatorios/RelatorioMetricasAprovacoes";

import RelatorioMetricasProducoes from "@/views/relatorios/RelatorioMetricasProducoes";

import RelatorioProducoesParceiros from "@/views/relatorios/RelatorioProducoesParceiros";

import RelatorioMetricasExecutores from "@/views/relatorios/RelatorioMetricasExecutores";

import RelatorioMetricasCS from "@/views/relatorios/RelatorioMetricasCustomerSuccess";

Vue.use(Router);

const routes = [
	{
		name: "login",
		path: "/login",
		component: Login,
	},
	{
		name: "recuperar-senha",
		path: "/login/recuperar-senha",
		component: RecuperarSenha,
	},
	{
		name: "dashboard",
		path: "/",
		component: Dashboard,
	},
	{
		name: "conteudos",
		path: "/conteudos",
		component: Dashboard,
	},
	{
		name: "usuarios",
		path: "/usuarios",
		component: Usuarios,
	},
	{
		name: "clientes",
		path: "/clientes",
		component: Clientes,
	},
	{
		name: "cliente-categorias",
		path: "/cliente-categorias/:id",
		component: ClienteCategorias,
	},
	{
		name: "contratos",
		//path: '/contratos/:id',
		path: "/contratos",
		component: Contratos,
	},
	{
		name: "conteudo-solicitar-editar",
		path: "/conteudo/solicitar/editar/:id",
		component: ConteudoSolicitarEditar,
		props: true,
	},
	{
		name: "conteudo-solicitar",
		path: "/conteudo/solicitar",
		component: ConteudoSolicitar,
	},
	{
		name: "conteudo-editar",
		path: "/conteudo/editar/:id",
		component: ConteudoEditar,
		props: true,
	},
	{
		name: "avaliacao-mensal",
		path: "/avaliacao-mensal",
		component: AvaliacaoMensal,
	},
	{
		name: "relatorio-avaliacao",
		path: "/relatorio-avaliacao",
		component: RelatorioAvaliacao,
	},
	{
		name: "relatorio-metricas-avaliacoes",
		path: "/relatorio-metricas-avaliacoes",
		component: RelatorioMetricasAvaliacoes,
	},
	{
		name: "relatorio-metricas-aprovacoes",
		path: "/relatorio-metricas-aprovacoes",
		component: RelatorioMetricasAprovacoes,
	},
	{
		name: "relatorio-metricas-producoes",
		path: "/relatorio-metricas-producoes",
		component: RelatorioMetricasProducoes,
	},
	{
		name: "relatorio-producoes-parceiros",
		path: "/relatorio-producoes-parceiros",
		component: RelatorioProducoesParceiros,
	},
	{
		name: "relatorio-executores",
		path: "/relatorio-executores",
		component: RelatorioMetricasExecutores,
	},
	{
		name: "relatorio-customer-success",
		path: "/relatorio-customer-success",
		component: RelatorioMetricasCS,
	},
];

const router = new Router({
	routes,
	mode: "history",
	base: process.env.VUE_APP_ROUTER_BASE,
});

export default router;
