<template>
  <div class="hold-transition login-page">
    <div class="login-box">
      <div class="login-logo">
        <a href="index.html">
          <b>E</b>Saúde
        </a>
      </div>

      <div class="card">
        <div class="card-body login-card-body p-3">
          <!-- RECUPERAR SENHA -->
          <form
            @submit.prevent="recuperarSenha()"
            enctype="application/x-www-form-urlencoded"
            v-if="token"
          >
            <p>Escolha sua nova senha:</p>
            <div class="input-group mb-3">
              <input
                type="password"
                class="form-control"
                placeholder="Senha"
                v-model="login.password"
              />

              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                type="password"
                class="form-control"
                placeholder="Confirmar Senha"
                v-model="login.password_confirmation"
              />

              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- /.col -->
              <div class="col-12">
                <button type="submit" class="btn btn-primary btn-block btn-flat">Alterar senha</button>
                <p class="text-center pt-3">ou</p>
                <router-link
                  to="/login"
                  class="btn btn-secondary btn-block btn-flat"
                >Voltar para login</router-link>
              </div>
              <!-- /.col -->
            </div>
          </form>

          <!-- SOLICITAR RECUPERAR SENHA -->
          <form
            @submit.prevent="solicitarRecuperarSenha()"
            v-if="mostrar_recuperar_senha && token == false"
          >
            <div class="input-group mb-3">
              <p>
                Para recuperar a sua senha de acesso por favor, informe abaixo o
                email de cadastro para receber instruções.
              </p>
              <input
                type="email"
                class="form-control"
                name="email"
                autocomplete="off"
                placeholder="Informe seu email cadastrado"
                v-model="usuario.email"
              />

              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- /.col -->
              <div class="col-12">
                <button
                  type="submit"
                  class="btn btn-primary btn-block btn-flat"
                >Enviar instruções para esse email</button>
                <p class="text-center pt-3">ou</p>
                <router-link
                  to="/login"
                  class="btn btn-secondary btn-block btn-flat"
                >Voltar para login</router-link>
              </div>
              <!-- /.col -->
            </div>
          </form>

          <!-- SOLICITAR RECUPERAR SENHA AVISO -->
          <form v-if="mostrar_recuperar_senha == false && token == false">
            <div class="input-group mb-3">
              <p>Enviamos um e-mail para a conta informada. Siga as instruções para recuperar o acesso.</p>
            </div>

            <div class="row">
              <!-- /.col -->
              <div class="col-12">
                <router-link
                  to="/login"
                  class="btn btn-secondary btn-block btn-flat"
                >Voltar para login</router-link>
              </div>
              <!-- /.col -->
            </div>
          </form>
        </div>
        <!-- /.login-card-body -->
      </div>

      <!-- card debug -->
      <div class="card"  v-if="$debugWindow">
        <div class="card-header">
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse">
              <i class="fas fa-minus"></i>
            </button>
            <button type="button" class="btn btn-tool" data-card-widget="remove">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div class="card-body">
          <pre>LOGIN: {{login}}</pre>
          <pre>RETORNO: {{retorno}}</pre>
        </div>
      </div>
      <!-- /.card debug -->
    </div>
    <!-- /.login-box -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "recuperar-senha",
  data() {
    return {
      login: {},
      usuario: {},
      retorno: {},
      token: false,
      mostrar_recuperar_senha: true,
      recuperar_senha_url:
        this.$apiUrl + "/recuperar_senha",
      solicitar_recuperar_senha_url:
        this.$apiUrl + "/solicitar_recuperar_senha"
    };
  },
  methods: {
    recuperarSenha() {
      axios.post(this.recuperar_senha_url, this.login).then(response => {
        var _t = this;

        _t.retorno = response.data;

        //console.log(_t.retorno);

        //ALERT - INICIO
        if (_t.retorno.status == 200) {
          _t.showalert("Sucesso", _t.retorno.message, "success");

          _t.$router.push("/");
        } else {
          if (typeof _t.retorno.message === "string") {
            _t.showalert("Atenção", _t.retorno.message, "warning");
          } else {
            const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

            for (var i = 0; i < msg.length; i++) {
              var msgerro = msg[i][1];
              _t.showalert("Atenção", msgerro, "warning");
            }
          }
        }
        //ALERT - FINAL
      });
    },
    solicitarRecuperarSenha() {
      axios
        .post(this.solicitar_recuperar_senha_url, this.usuario)
        .then(response => {
          var _t = this;

          _t.retorno = response.data;

          //console.log(_t.retorno);

          //ALERT - INICIO
          if (_t.retorno.status == 200) {
            _t.showalert("Sucesso", _t.retorno.message, "success");

            this.mostrar_recuperar_senha = false;
            //_t.$router.push("/");
          } else {
            if (typeof _t.retorno.message === "string") {
              _t.showalert("Atenção", _t.retorno.message, "warning");
            } else {
              const msg = Object.entries(_t.retorno.message); //CONVERTE OBJETO EM ARRAY COM OS VALORES 'entries'

              for (var i = 0; i < msg.length; i++) {
                var msgerro = msg[i][1];
                _t.showalert("Atenção", msgerro, "warning");
              }
            }
          }
          //ALERT - FINAL
        });
    }
  },
  mounted() {
    //console.log(this.$route.query);

    const token = this.$route.query.token;

    if (typeof token !== "undefined") {
      this.token = true;
      this.login.token = token;
    }

    const el = document.body;
    el.classList.add("login-page");
  },
  destroyed() {
    const el = document.body;
    el.classList.remove("login-page");
  }
};
</script>
